import { ExternalModelReference as ExternalModelReferenceType } from 'src/types';
import { Icon } from 'src/components/Icon';
import styles from './ExternalModelReference.module.scss';

const SVG_SIZE = 16;

interface ExternalModelReferenceProps extends ExternalModelReferenceType {
  disabled?: boolean;
  onClick: (key: string) => void;
}

export const ExternalModelReference = ({
  modelKey,
  title,
  icon,
  disabled,
  onClick,
}: ExternalModelReferenceProps) => {
  const handleClick = () => {
    onClick(modelKey);
  };

  return (
    <button disabled={disabled} onClick={handleClick} className={styles.root}>
      <span className={styles.index}>{modelKey}</span>

      {icon && <Icon size={SVG_SIZE} type={icon} />}

      <p className={styles.title}>{title}</p>
    </button>
  );
};
