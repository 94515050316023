import { useRef, useContext } from 'react';
import { SlidersHorizontal } from '@phosphor-icons/react';
import cn from 'classnames';
import { useOutsideClick, useBreakpoint } from 'src/hooks';
import { AvatarSelectorTooltip } from './components/AvatarSelectorTooltip';
import SessionContext from 'src/contexts/SessionContext';

import styles from './AvatarSelectorButton.module.scss';

const ICON_SIZE = 20;

export const AvatarSelectorButton = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileOrTablet } = useBreakpoint();

  const { onToggleAvatarSelect, isShowAvatarSelect } =
    useContext(SessionContext);

  const handleOpenSelector = () => {
    onToggleAvatarSelect();
  };

  const handleCloseSelector = () => {
    onToggleAvatarSelect(false);
  };

  useOutsideClick(containerRef, handleCloseSelector);

  return (
    <div ref={containerRef}>
      <button
        className={cn(styles.toggleButton, {
          [styles.active]: isShowAvatarSelect,
        })}
        onClick={handleOpenSelector}
        id="avatar-select"
      >
        <SlidersHorizontal
          size={ICON_SIZE}
          weight={isShowAvatarSelect ? 'fill' : 'regular'}
        />
      </button>

      {!isMobileOrTablet && <AvatarSelectorTooltip />}
    </div>
  );
};
