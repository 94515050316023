import classNames from 'classnames';
import { useTheme, useBreakpoint } from 'src/hooks';
import { AppNav, Footer } from 'src/components/AppNav';
import { ConversationsList } from '../ConversationsList/ConversationsList';
import { FilteringChip } from '../FilteringChip';
import { MainNavToggleButton } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/MainNavToggleButton';

export const ConversationListMainContent = () => {
  const { isTasksListExpanded } = useTheme();
  const { isMobileOrTablet } = useBreakpoint();

  const firstPanelClasses = classNames('nj-manage-tasks', {
    collapsed: !isTasksListExpanded,
  });

  return (
    <div className={firstPanelClasses} data-testid="first-panel">
      <div className="nj-manage-tasks--content-wrapper nj-beta">
        <AppNav />

        <FilteringChip />

        <ConversationsList />

        <Footer />

        {!isTasksListExpanded && !isMobileOrTablet && (
          <MainNavToggleButton className="nj-manage-tasks--toggle-nav-button" />
        )}
      </div>
    </div>
  );
};
