import { useMemo, Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Conversation } from 'src/types';
import { getTaskDisplayDate } from 'src/utils';
import { useThreads } from 'src/hooks';
import { ConversationThread } from '../ConversationThread';
import { RenameModal } from 'src/components/RenameModal';
import { DEFAULT_CHAT_ID } from 'src/constants';

interface ConversationGroupProps {
  date: string;
  conversations: Conversation[];
  selectedTaskId?: string;
  selectedConversationId?: string;
  shouldAnimateListItem?: [string, string];
  scrollTo: (val: number) => void;
}

/**
 * ConversationsGroup displays a conversation with a list of tasks.
 */
export const ConversationGroup = ({
  date,
  conversations,
  selectedConversationId,
  shouldAnimateListItem,
  scrollTo,
}: ConversationGroupProps) => {
  const { isSubmitHappened } = useThreads();
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);
  const [activeThreadAnchor, setActiveThreadAnchor] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (selectedConversationId === DEFAULT_CHAT_ID) {
      setActiveThreadAnchor(null);
    }
  }, [selectedConversationId]);

  useEffect(() => {
    if (isSubmitHappened) {
      setActiveThreadAnchor(null);
    }
  }, [isSubmitHappened]);

  const title = useMemo(() => getTaskDisplayDate(date), [date]);

  const shouldShowDateTitle = useMemo(() => {
    if (
      title === 'Today' &&
      conversations.length === 1 &&
      conversations[0].conversation_id === DEFAULT_CHAT_ID
    ) {
      return false;
    }
    return true;
  }, [conversations, title]);

  return (
    <Fragment>
      <div
        className={classNames('nj-tasks', {
          'hide-title': !shouldShowDateTitle,
        })}
      >
        <div className="nj-tasks--date">
          <span>{title}</span>
        </div>
        {conversations.map((conversation: Conversation, index: number) => (
          <ConversationThread
            key={`thread-${conversation.conversation_id}-${index}`}
            selected={selectedConversationId === conversation.conversation_id}
            conversation={conversation}
            shouldAnimateListItem={shouldAnimateListItem}
            shouldAnimate={
              shouldAnimateListItem &&
              Array.isArray(shouldAnimateListItem) &&
              shouldAnimateListItem[0] === 'conversation' &&
              shouldAnimateListItem[1] === conversation.conversation_id
            }
            activeThreadAnchor={activeThreadAnchor}
            setActiveThreadAnchor={setActiveThreadAnchor}
            scrollTo={scrollTo}
            shouldOpenRenameModal={setIsRenameModalOpen}
          />
        ))}
      </div>
      {isRenameModalOpen && (
        <RenameModal
          isOpen={isRenameModalOpen}
          onClose={() => setIsRenameModalOpen(false)}
        />
      )}
    </Fragment>
  );
};
