import { useMemo } from 'react';
import { PlanSectionType } from 'src/types';
import { PackageDealDetails } from '../PackageDealDetails';
import './PackageDeal.scss';
import classNames from 'classnames';

interface PackageDealProps {
  type: PlanSectionType;
  selectedAmount?: number;
}

export const PackageDeal = ({ type, selectedAmount = 0 }: PackageDealProps) => {
  const isFree = type === 'free';

  const taskAmount = useMemo(() => {
    switch (selectedAmount) {
      case 1000:
        return '2,000';

      case 2000:
        return '4,400';

      case 3000:
        return '6,900';

      default:
        return '600';
    }
  }, [selectedAmount]);

  const title = useMemo(() => `Up to ${taskAmount} tasks`, [taskAmount]);

  const description = isFree
    ? 'With limited feature access + daily limits'
    : 'Premium feature access without any daily limits';

  return (
    <div className={classNames('nj-package-deal', { isFree: isFree })}>
      <div className="nj-package-deal--title">
        <p>{title}</p>

        <PackageDealDetails type={type} selectedAmount={selectedAmount} />
      </div>

      <p className="nj-package-deal--description">{description}</p>
    </div>
  );
};
