import cn from 'classnames';
import { useTheme } from 'src/hooks';
import { AvatarImage } from 'src/components/AvatarImage';
import styles from './MainNavToggleButton.module.scss';

interface MainNavToggleButtonProps {
  className?: string;
}

/**
 * MainNavToggleButton for opening tasks list.
 */
export const MainNavToggleButton = ({
  className,
}: MainNavToggleButtonProps) => {
  const { toggleTasksListExpanded } = useTheme();

  return (
    <button
      className={cn(styles.root, className)}
      onClick={toggleTasksListExpanded}
    >
      <AvatarImage />
    </button>
  );
};
