export const DEFAULT_NINJA_BETA_LINK = 'https://www.ninjatech.ai/#beta';
export const DEFAULT_PRIVACY_POLICY_LINK = 'https://www.myninja.ai/privacy';

export const DEFAULT_TERMS_OF_SERVICE_LINK =
  'https://www.myninja.ai/terms-of-service';

export const DEFAULT_TERMS_OF_USE_LINK = 'https://www.myninja.ai/terms-of-use';

export const DEFAULT_ACCEPTABLE_USE_POLICY =
  'https://www.myninja.ai/company/acceptable-use-policy';

export const DEFAULT_NINJATECH_LINK = 'https://myninja.ai/company';
export const DEFAULT_NINJATECH_BLOG_LINK = 'https://myninja.ai/company/news';
export const DEFAULT_NINJATECH_TERMS_LINK =
  'https://myninja.ai/terms-of-service';
export const DEFAULT_NINJATECH_PRIVACY_POLICY_LINK =
  'https://myninja.ai/privacy';
