import { useMemo } from 'react';
import { sanitize } from 'dompurify';
import classNames from 'classnames';
import { removeReferencesInBrackets } from 'src/utils';
import { StreamingText } from 'src/components/StreamingText';
import { Citation } from 'src/types';

interface ThreadMessageContentProps {
  content: string;
  className?: string;
  isStreaming?: boolean;
  isExpanded?: boolean;
  references?: Citation[];
}

export const ThreadMessageContent = ({
  content,
  className,
  isStreaming,
  isExpanded,
  references,
}: ThreadMessageContentProps) => {
  const contentHTML = useMemo(
    () => sanitize(removeReferencesInBrackets(content.toString())),
    [content],
  );

  if (!contentHTML) {
    return null;
  }

  return (
    <div className={classNames('nj-thread-message--content', className)}>
      <StreamingText
        isStreaming={isStreaming}
        content={contentHTML}
        isExpanded={isExpanded}
        references={references}
      />
    </div>
  );
};
