import { forwardRef } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  ArrowCircleUp,
  Wallet,
  ClockCounterClockwise,
  ArrowLeft,
} from '@phosphor-icons/react';
import { AppRoutes, SubscriptionNestedRoutes } from 'src/types';
import { useSession } from 'src/hooks';
import styles from './ManageAccountNavigation.module.scss';

const SVG_SIZE_L = 24;
const SVG_SIZE_M = 20;

const SVG_PROPS = {
  size: SVG_SIZE_M,
};

interface ManageAccountNavigationProps {
  onClose?: () => void;
}

export const ManageAccountNavigation = forwardRef<
  HTMLDivElement,
  ManageAccountNavigationProps
>(({ onClose }, ref) => {
  const { pathname } = useLocation();

  const { isProTier } = useSession();

  const navigation = [
    {
      route: SubscriptionNestedRoutes.MANAGE_PLAN,
      icon: <ArrowCircleUp {...SVG_PROPS} />,
      title: 'Plans',
      defaultRoute: true,
      disabled: false,
    },
    {
      route: SubscriptionNestedRoutes.BILLING,
      icon: <Wallet {...SVG_PROPS} />,
      title: 'Billing',
      defaultRoute: false,
      disabled: !isProTier,
    },
    {
      route: SubscriptionNestedRoutes.ACTIVITY,
      icon: <ClockCounterClockwise {...SVG_PROPS} />,
      title: 'Activity',
      defaultRoute: false,
      disabled: !isProTier,
    },
  ];

  return (
    <div className={styles.root} ref={ref}>
      <Link
        to={AppRoutes.HOME}
        className={styles.goBackLink}
      >
        <ArrowLeft size={SVG_SIZE_L} />
        Back to Ninja
      </Link>

      <div className={styles.menu}>
        {navigation.map(({ route, icon, title, defaultRoute, disabled }) => (
          <NavLink
            onClick={(e) => {
              if (disabled) {
                e.preventDefault();
              } else if (onClose) {
                onClose();
              }
            }}
            className={({ isActive }) =>
              classNames(styles.menuLink, {
                active:
                  isActive ||
                  (defaultRoute && pathname === AppRoutes.SUBSCRIPTION),
                disabled,
              })
            }
            key={route}
            to={route}
          >
            {icon}
            {title}
          </NavLink>
        ))}
      </div>
    </div>
  );
});
