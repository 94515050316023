import { Header } from 'src/pages/AuthPages/components/Header';
import atlasSmile from 'src/images/login/atlas_smile.png';
import { FormInput } from 'src/components/FormInput';
import { CheckCircle } from '@phosphor-icons/react';
import { FormProvider } from 'src/components/FormProvider';
import { useEffect, useState } from 'react';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { useForm } from 'react-hook-form';
import { signIn } from 'aws-amplify/auth';
import { LoginHeader } from 'src/pages/AuthPages/components/LoginHeader';
import { Link, useSearchParams } from 'react-router-dom';
import { ErrorMessageBlock } from 'src/pages/AuthPages/components/ErrorMessageBlock';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';

enum SignInField {
  EMAIL = 'email',
  PASSWORD = 'password',
}

interface FormData {
  [SignInField.EMAIL]: string;
  [SignInField.PASSWORD]: string;
}

const SVG_SIZE = 24;

export const LoginWithEmailPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFields, setErrorFields] = useState<SignInField[]>([]);
  const [isShowSuccessMessage, setIsShowSuccessMessage] = useState(false);
  const [searchParams] = useSearchParams();
  const isPasswordChanged = searchParams.get('password-changed');

  useEffect(() => {
    if (isPasswordChanged) {
      setIsShowSuccessMessage(true);
    }
  }, [isPasswordChanged]);

  const methods = useForm<FormData>({
    defaultValues: {
      [SignInField.EMAIL]: '',
      [SignInField.PASSWORD]: '',
    },
  });

  const { handleSubmit } = methods;

  const submitForm = async (data: FormData) => {
    try {
      setErrorMessage('');
      setErrorFields([]);

      await signIn({
        username: data[SignInField.EMAIL].toLowerCase(),
        password: data[SignInField.PASSWORD],
      });

      sendGTMEvent(GTMEvent.EMAIL_LOGIN_SUCCESS);
    } catch (error) {
      sendGTMEvent(GTMEvent.EMAIL_LOGIN_FAILURE);

      setErrorFields([SignInField.EMAIL, SignInField.PASSWORD]);
      setIsShowSuccessMessage(false);
      switch ((error as ServiceError).name) {
        case 'TooManyRequestsException':
          setErrorMessage('Too many attempts. Please try again in x minutes');
          return;
        default:
          setErrorMessage(
            'Oops! The email or password is incorrect. Try again.',
          );
          return;
      }
    }
  };

  const handleClick = () => {
    sendGTMEvent(GTMEvent.FORGOT_PASSWORD_CLICKS);
  };

  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<LoginHeader />} />
      <main className="nj-auth-content">
        <h1 className="nj-auth-header-main">Welcome back!</h1>
        <h3 className="nj-auth-header-secondary">Login to your account</h3>
        <FormProvider<FormData> methods={methods}>
          <form onSubmit={handleSubmit(submitForm)} className="nj-auth-form">
            <div className="nj-auth-form--field">
              <legend className="nj-auth-form--legend">Email</legend>
              <FormInput
                name={SignInField.EMAIL}
                type="email"
                className="nj-auth-form--input"
                placeholder="Enter Email"
                required={true}
                hasErrorBorder={
                  errorMessage.length > 0 &&
                  errorFields.includes(SignInField.EMAIL)
                }
              />
            </div>
            <div className="nj-auth-form--field">
              <legend className="nj-auth-form--legend">Password</legend>
              <FormInput
                name={SignInField.PASSWORD}
                type="password"
                className="nj-auth-form--input"
                placeholder="Enter Password"
                required={true}
                hasErrorBorder={
                  errorMessage.length > 0 &&
                  errorFields.includes(SignInField.PASSWORD)
                }
              />
            </div>
            <button className="nj-auth-form--submit-button" type="submit">
              Log in
            </button>
            {errorMessage.length > 0 && (
              <ErrorMessageBlock errorMessage={errorMessage} />
            )}
            {isShowSuccessMessage ? (
              <div className="nj-auth-form--success-block">
                <CheckCircle size={SVG_SIZE} />
                <span>Your password has been successfully reset.</span>
              </div>
            ) : (
              <Link
                to="/forgot-password"
                className="nj-auth-forgot-password-link"
                onClick={handleClick}
              >
                Forgot Password?
              </Link>
            )}
          </form>
        </FormProvider>
      </main>
      <img className="nj-auth-background-image" src={atlasSmile} alt="Ninja" />
    </div>
  );
};
