import { useMemo } from 'react';
import { sanitize } from 'dompurify';
import { removeReferencesInBrackets } from 'src/utils';
import { useSession } from 'src/hooks';
import { SignupForFreeButton } from 'src/components/SignupForFreeButton';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { ErrorCode } from 'src/types/models/ErrorCode';
import classNames from 'classnames';
import { LearnMoreButton } from 'src/components/LearnMoreButton';
import { TaskSkill } from 'src/types';
import { UserTier } from 'src/types/models/UserTier';
import { RenewEarlyButton } from 'src/components/RenewEarlyButton';

interface ThreadErrorMessageContentProps {
  content: string;
  className?: string;
  errorCode: ErrorCode;
  unsupportedSkills?: Array<TaskSkill>;
}

export const ThreadErrorMessageContent = ({
  content,
  className,
  errorCode,
  unsupportedSkills = [],
}: ThreadErrorMessageContentProps) => {
  const { isOpenTier, appUser, isProTier } = useSession();
  const contentHTML = useMemo(
    () => sanitize(removeReferencesInBrackets(content.toString())),
    [content],
  );

  if (!contentHTML) {
    return null;
  }

  const renderErrorMessage = () => {
    switch (errorCode) {
      case ErrorCode.QUOTA_EXCEPTION:
        if (isProTier) {
          return 'Sorry, insufficient credits to execute this task.';
        }
        return "Sorry, you've reached your daily limit";
      case ErrorCode.SKILL_NOT_SUPPORTED:
        if (isOpenTier) {
          if (unsupportedSkills?.includes(TaskSkill.SCHEDULING)) {
            return 'Scheduler Agent requires a signed-in account.';
          } else if (unsupportedSkills?.includes(TaskSkill.IMAGE)) {
            return 'Image Agent requires a signed-in account.';
          } else {
            return 'Access more features and higher limits by signing up';
          }
        }
        return content;
      default:
        return content;
    }
  };

  const renderTierButton = () => {
    switch (appUser.tier_id) {
      case UserTier.OPEN:
        return <SignupForFreeButton />;
      case UserTier.FREE:
        return <UpgradeButton />;
      case UserTier.PAID:
        return <RenewEarlyButton />;
      default:
        return <></>;
    }
  };

  const renderUpsellButtons = () => {
    switch (errorCode) {
      case ErrorCode.QUOTA_EXCEPTION:
        return renderTierButton();
      case ErrorCode.SKILL_NOT_SUPPORTED:
        if (isOpenTier) {
          return (
            <>
              <SignupForFreeButton />
              {unsupportedSkills?.includes(TaskSkill.SCHEDULING) && (
                <LearnMoreButton />
              )}
            </>
          );
        }
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <div className={classNames('nj-thread-error-message-content', className)}>
      <p>{renderErrorMessage()}</p>
      <div className="nj-thread-error-message-content--button-wrapper">
        {renderUpsellButtons()}
      </div>
    </div>
  );
};
