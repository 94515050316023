import { ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import classNames from 'classnames';
import { FeedbackType, MessageType } from 'src/types';
import { Feedback } from 'src/types/models/Feedback';
import { useFeedback } from 'src/hooks/useFeedback';

const SVG_SIZE = 20;

interface MessageThumbsButtonsProps {
  taskId?: string;
  messageId?: string;
  conversationId?: string;
  feedback?: Feedback;
  withTitle?: boolean;
  disabled?: boolean;
  messageType?: MessageType;
  onToggleNegativeFeedbackForm?: () => void;
  isShowNegativeFeedbackForm?: boolean;
}

export const MessageThumbsButtons = ({
  taskId,
  messageId,
  conversationId,
  feedback,
  withTitle,
  disabled,
  onToggleNegativeFeedbackForm,
  isShowNegativeFeedbackForm = false,
}: MessageThumbsButtonsProps) => {
  const { onThumbsClick } = useFeedback({
    messageId: messageId || '',
    conversationId: conversationId || '',
    onToggleNegativeFeedbackForm,
    isShowNegativeFeedbackForm,
    feedback,
    taskId,
  });

  if (!messageId || !conversationId) {
    return null;
  }

  return (
    <div
      data-testid="thumb-buttons"
      className={classNames('nj-feedback-thumb-buttons nondebug-mode', {
        'in-column': withTitle,
      })}
    >
      <button
        onClick={() => onThumbsClick(FeedbackType.POSITIVE)}
        aria-label="Click on the thumb up"
        disabled={disabled}
        className={classNames('nj-feedback-thumb-buttons--button', {
          'button-with-flex': withTitle,
          selected: feedback?.feedback_type === FeedbackType.POSITIVE,
        })}
      >
        <ThumbsUp
          weight={
            feedback?.feedback_type === FeedbackType.POSITIVE
              ? 'fill'
              : 'regular'
          }
          size={SVG_SIZE}
          color="currentColor"
        />
        {withTitle && <span>Good response</span>}
      </button>
      <button
        onClick={() => onThumbsClick(FeedbackType.NEGATIVE)}
        aria-label="Click on the thumb down"
        disabled={disabled}
        className={classNames('nj-feedback-thumb-buttons--button', {
          'button-with-flex': withTitle,
          selected: feedback?.feedback_type === FeedbackType.NEGATIVE,
        })}
      >
        <ThumbsDown
          weight={
            feedback?.feedback_type === FeedbackType.NEGATIVE
              ? 'fill'
              : 'regular'
          }
          size={SVG_SIZE}
          color="currentColor"
        />
        {withTitle && <span>Bad response</span>}
      </button>
    </div>
  );
};
