import { useRef, useContext, Fragment } from 'react';
import classNames from 'classnames';
// import { DotsSixVertical } from '@phosphor-icons/react';
import AvatarModeContext, {
  AvatarModeContextType,
} from 'src/contexts/AvatarModeContext';
import { AvatarFrame } from 'src/pages/ManageTasksChatPage/components/AvatarFrame';
import { MultiConversationsPanel } from 'src/pages/ManageTasksChatPage/components/MultiConversationsPanel';
import { Panel } from 'src/components/Panel';
import { ChatMode } from 'src/types';
import { useBreakpoint, useSession } from 'src/hooks';

type ResizePanelsProps = {
  inlineAvatar?: boolean;
  startSpeechRecognizing: () => void;
  stopSpeechRecognizing: () => void;
  unMuteMicrophone: () => void;
};

export const ResizePanels = ({
  inlineAvatar = true,
  startSpeechRecognizing,
  stopSpeechRecognizing,
  unMuteMicrophone,
}: ResizePanelsProps) => {
  const { chatMode, avatarQueue } = useSession();
  const { isDesktopLAndUp } = useBreakpoint();
  const { showTranscript } =
    useContext<AvatarModeContextType>(AvatarModeContext);

  const containerRef = useRef<HTMLDivElement>(null);
  const chatPanelRef = useRef<HTMLDivElement>(null);
  const avatarPanelRef = useRef<HTMLDivElement>(null);
  // const resizeBarRef = useRef<HTMLDivElement>(null);

  const conversationsClasses: string = classNames(
    'nj-fluid--panel--chats',
    'nj-converse-ai',
    'nj-resizable',
    {
      collapsed:
        chatMode === ChatMode.AVATAR &&
        showTranscript &&
        isDesktopLAndUp &&
        avatarQueue?.ready,
      hidden: chatMode === ChatMode.AVATAR && !showTranscript && inlineAvatar,
    },
  );

  const avatarClasses: string = classNames(
    'nj-fluid--panel--avatar-frame',
    'nj-resizable',
    {
      collapsed: chatMode !== ChatMode.AVATAR || !isDesktopLAndUp,
    },
  );

  return (
    <Fragment>
      <div className="container nj-resizable-container" ref={containerRef}>
        <Panel className={conversationsClasses} ref={chatPanelRef}>
          <MultiConversationsPanel
            startSpeechRecognizing={startSpeechRecognizing}
            stopSpeechRecognizing={stopSpeechRecognizing}
            unMuteMicrophone={unMuteMicrophone}
          />
        </Panel>
        {inlineAvatar && (
          <Panel className={avatarClasses} ref={avatarPanelRef}>
            <AvatarFrame />
          </Panel>
        )}
      </div>
    </Fragment>
  );
};
