import { PlayCircle } from '@phosphor-icons/react';

type LearnMoreUpsellLabelProps = {
  onClick: () => void;
};

export const LearnMoreUpsellLabel = ({
  onClick,
}: LearnMoreUpsellLabelProps) => {
  return (
    <button className="nj-upsell-learn-more-label-wrapper" onClick={onClick}>
      <PlayCircle size={20} weight="fill" />
      <span className="nj-upsell-learh-more-label-text">Learn more</span>
    </button>
  );
};
