import { LearnMoreUpsellLabel } from 'src/components/LearnMoreUpsellLabel';
import { useVisible } from 'src/hooks';
import { UpsellVideoModal } from 'src/components/UpsellVideoModal';
import { useGetVideoLinksJSONQuery } from 'src/store/services';

export enum UpsellMessageCardType {
  RESEARCHER = 'researcher',
  CODER = 'coder',
}

type UpsellMessageResearchCodeCardProps = {
  cardType: UpsellMessageCardType;
};

export const UpsellMessageResearchCodeCard = ({
  cardType,
}: UpsellMessageResearchCodeCardProps) => {
  const {
    isVisible: isShowUpsellModal,
    handleVisibilitySet: openUpsellModal,
    handleVisibilityRemove: closeUpsellModal,
  } = useVisible();
  const { data: videoLinks } = useGetVideoLinksJSONQuery();

  const isResearcher = cardType === UpsellMessageCardType.RESEARCHER;

  return (
    <>
      <div className="nj-upsell-message-research-code-card">
        <span>
          Sign up to easily get access to 20+ external models from OpenAI,
          Google & Amazon Bedrock
        </span>
        <LearnMoreUpsellLabel onClick={openUpsellModal} />
      </div>

      <hr className="divider" />

      <UpsellVideoModal
        videoUrl={
          cardType === UpsellMessageCardType.RESEARCHER
            ? videoLinks?.research || ''
            : videoLinks?.coder || ''
        }
        title={
          isResearcher
            ? 'Research with third party LLMs'
            : 'Coder using third party LLMs'
        }
        description={
          isResearcher
            ? 'Sign up to get research results from third party LLMs.'
            : 'Sign up to get coder results from third party LLMs.'
        }
        isOpen={isShowUpsellModal}
        onClose={closeUpsellModal}
      />
    </>
  );
};
