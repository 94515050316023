import { forwardRef } from 'react';
import classNames from 'classnames';

type PanelProps = {
  className?: string;
  children?: React.ReactNode;
};

/**
 * Panel component creates a panel to hold other components
 * within the layout of ContentPane.
 */
export const Panel = forwardRef<HTMLDivElement, PanelProps>(
  ({ className, children, ...rest }, ref) => {
    const panelClasses = classNames('nj-fluid--panel', className);
    return (
      <div ref={ref} className={panelClasses} {...rest}>
        {children}
      </div>
    );
  },
);
