import { useThreads } from 'src/hooks';
import { TaskState } from 'src/types';
import { StopTaskButton } from 'src/components/StopTaskButton';
import { CloseTaskButton } from 'src/components/FlatAppearance/components/CloseTaskButton';
import { TaskViewMenu } from './components/TaskViewMenu';
import { ReportIssueToZendeskButton } from './components/ReportIssueToZendeskButton';
import { Prohibit } from '@phosphor-icons/react';

interface TaskViewHeaderProps {
  onCloseTaskView: () => void;
}

export const TaskViewHeader = ({ onCloseTaskView }: TaskViewHeaderProps) => {
  const { currentTask } = useThreads();

  if (!currentTask || !currentTask?.task_id) {
    return null;
  }

  const taskName = currentTask?.task_hash?.replace('#', '') || '';
  const stopTaskButtonLabel = (
    <>
      <Prohibit size={20} />
      <span>Stop task</span>
    </>
  );

  return (
    <div className="nj-task-view-header">
      <div className="nj-task-view-header--actions">
        <h6 className="nj-task-view-header--actions-title ellipsis">
          {taskName}
        </h6>

        <StopTaskButton
          taskId={currentTask.task_id}
          taskState={currentTask.state || TaskState.IN_PROGRESS}
          taskHash={currentTask?.task_hash}
          className="nj-task-view-header--stop-botton"
          buttonName={stopTaskButtonLabel}
        />

        <ReportIssueToZendeskButton
          tags={['task', 'support']}
          taskId={currentTask?.task_id}
        />

        <TaskViewMenu
          taskId={currentTask?.task_id}
          taskFeedback={currentTask?.feedback}
          conversationId={currentTask?.parent_conversation_id || ''}
          messageId={currentTask?.task_creation_card_message_id || ''}
          taskSkill={currentTask?.skill}
        />

        <CloseTaskButton onClick={onCloseTaskView} />
      </div>
    </div>
  );
};
