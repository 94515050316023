import { lazy, Suspense } from 'react';
import { Info } from '@phosphor-icons/react';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const PasswordHint = () => {
  return (
    <>
      <Info
        data-tooltip-id="password-hint-tooltip"
        data-tooltip-place="top-start"
        size={20}
      />
      <Suspense>
        <LazyReactTooltip
          id="password-hint-tooltip"
          noArrow={true}
          className="nj-auth-form-password-tooltip"
        >
          Must use at least 8 characters with upper & lowercase letters,
          numbers, and symbols.
        </LazyReactTooltip>
      </Suspense>
    </>
  );
};
