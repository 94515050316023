import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { v4 as uuid_v4 } from 'uuid';
import {
  tasksBaseUrl,
  feedbackBaseUrl,
  convEngineBaseUrl,
  contactsBaseUrl,
  tokenBaseUrl,
  jsonBaseUrl,
  combusBaseUrl,
  metahumanBaseUrl,
  matchmakerBaseUrl,
  guestUsersBaseUrl,
  walletBaseUrl,
  contentBaseUrl,
} from '../constants/index';
import {
  X_NINJA_EVENT_ID_HEADER,
  X_NINJA_BYPASS_USER_ACCESS_CHECK,
} from 'src/constants/api';
import { get_access_token } from 'src/utils';
import { RootState } from 'src/store';

export const getBaseHeaders = async (headers: Headers) => {
  const token = await get_access_token();
  headers.set('Authorization', `Bearer ${token}`);
  headers.set('Content-Type', 'application/json');
  headers.set(X_NINJA_EVENT_ID_HEADER, uuid_v4());
  if (localStorage.getItem(X_NINJA_BYPASS_USER_ACCESS_CHECK)) {
    headers.set(X_NINJA_BYPASS_USER_ACCESS_CHECK, 'true');
  }

  return headers;
};

export const baseTasksQuery = fetchBaseQuery({
  baseUrl: tasksBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseFeedbackQuery = fetchBaseQuery({
  baseUrl: feedbackBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseNondebugFeedbackQuery = fetchBaseQuery({
  baseUrl: `${tasksBaseUrl}/feedback`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseUsersV2Query = fetchBaseQuery({
  baseUrl: `${tasksBaseUrl}/users`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseConvEngineQuery = fetchBaseQuery({
  baseUrl: convEngineBaseUrl,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseContactsQuery = fetchBaseQuery({
  baseUrl: contactsBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseTokenQuery = fetchBaseQuery({
  baseUrl: `${tokenBaseUrl}/tokens`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseAuthQuery = fetchBaseQuery({
  baseUrl: `${tokenBaseUrl}/auth/`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseMsftQuery = fetchBaseQuery({
  baseUrl: `${tokenBaseUrl}/msft`,
  credentials: 'same-origin',
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseJSONQuery = fetchBaseQuery({
  baseUrl: jsonBaseUrl,
  headers: {
    'Cache-Control': 'no-cache',
  },
});

export const baseCombusQuery = fetchBaseQuery({
  baseUrl: combusBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseMetahumanQuery = fetchBaseQuery({
  baseUrl: metahumanBaseUrl,
  async prepareHeaders(headers) {
    headers.set('Accept', 'application/json');
    return getBaseHeaders(headers);
  },
});

export const baseMatchmakerQuery = fetchBaseQuery({
  baseUrl: matchmakerBaseUrl,
  async prepareHeaders(headers) {
    headers.set('Accept', 'application/json');
    return getBaseHeaders(headers);
  },
});

// endpoints under this path don't require authentication
export const baseGuestUsersQuery = fetchBaseQuery({
  baseUrl: guestUsersBaseUrl,
  async prepareHeaders(headers) {
    headers.set('Content-Type', 'application/json');
    headers.set(X_NINJA_EVENT_ID_HEADER, uuid_v4());
    return headers;
  },
});

export const baseWalletQuery = fetchBaseQuery({
  baseUrl: walletBaseUrl,
  async prepareHeaders(headers) {
    return getBaseHeaders(headers);
  },
});

export const baseContentQuery = fetchBaseQuery({
  baseUrl: contentBaseUrl,
  async prepareHeaders(headers, { getState }) {
    headers.set(
      'X-Ninja-User-Id',
      (getState() as RootState).session?.appUser.user_id,
    );
    return headers;
  },
});
