import { useContext } from 'react';
import classNames from 'classnames';
import { Button } from 'src/components/Button';
import { AudioWave } from '../AudioWave';
import { useSession } from 'src/hooks';
import DebugContext, { DebugContextType } from 'src/contexts/DebugContext';
import { interruptMetahuman } from 'src/utils';
import AudioContext, { AudioContextType } from 'src/contexts/AudioContext';

type MuteButtonProps = {
  className?: string;
  showLabel?: boolean;
};

export const MuteButton = ({
  className = '',
  showLabel = false,
}: MuteButtonProps) => {
  const { debugMode } = useContext<DebugContextType>(DebugContext);
  const { metaHumanTalking, setMetaHumanTalking } =
    useContext<AudioContextType>(AudioContext);

  const {
    appUser: { user_id },
  } = useSession();

  const handleStopAvatarTalking = () => {
    interruptMetahuman(user_id, debugMode);
    setMetaHumanTalking(false);
  };

  if (!metaHumanTalking) {
    return null;
  }

  return (
    <Button
      className={classNames(
        'nj-button',
        'nj-button--mute',
        'selected',
        className,
        {
          collapsed: !showLabel,
        },
      )}
      onClick={handleStopAvatarTalking}
    >
      <AudioWave interactive={metaHumanTalking} />
      {showLabel && <span>Stop</span>}
    </Button>
  );
};
