import { useVisible } from 'src/hooks';
import { LearnMoreUpsellLabel } from 'src/components/LearnMoreUpsellLabel';
import { UpsellVideoModal } from 'src/components/UpsellVideoModal';
import { useGetVideoLinksJSONQuery } from 'src/store/services';

export const UpsellMessageDeepResearch = () => {
  const {
    isVisible: isShowUpsellModal,
    handleVisibilitySet: openUpsellModal,
    handleVisibilityRemove: closeUpsellModal,
  } = useVisible();
  const { data: videoLinks } = useGetVideoLinksJSONQuery();

  return (
    <>
      <div className="nj-upsell-message-research-code-card nj-upsell-message-deep-research">
        <span>Get answers from Ninja’s advanced multi-hop researcher</span>
        <LearnMoreUpsellLabel onClick={openUpsellModal} />
      </div>
      <UpsellVideoModal
        videoUrl={videoLinks?.deepResearch || ''}
        title="Ninja's Deep Researcher Agent"
        description="Upgrade to PRO to get access to Ninja’s advanced multi-hop researcher."
        isOpen={isShowUpsellModal}
        onClose={closeUpsellModal}
      />
    </>
  );
};
