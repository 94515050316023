import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  conversationApi,
  useAppSelector,
  conversationState,
  setDisableConversationSubmit,
  useAppDispatch,
  addConversationMessage,
  addMessageToTaskByTaskId,
} from 'src/store';
import { Message, AppUser } from 'src/types';
import { useSendMessagePDUMutation } from 'src/store/services';
import { toPDUMessage } from 'src/utils';

dayjs.extend(utc);

// TODO(olha): deprecated
export const useConversation = (user: AppUser, taskId: string) => {
  const dispatch = useAppDispatch();
  const { messages = [], messageToStream } = useAppSelector(conversationState);
  const [sendPDUMessage] = useSendMessagePDUMutation();

  const [clearUserConversation] =
    conversationApi.useClearUserConversationMutation();

  // wrapper to add a message
  const addMessageToConversation = async (message: Message) => {
    const envelope = toPDUMessage(JSON.stringify(message), user?.user_id);
    if (message.task_id && message.task_id !== 'default') {
      // todo(ella): test the case when taskId is not known,
      // create maybe a dummy task & update upon combus update
      dispatch(addMessageToTaskByTaskId(message));
    } else {
      dispatch(addConversationMessage(message));
    }
    await sendPDUMessage({
      message: envelope,
      apiContext: {
        conversation_id: message.conversation_id,
      },
    }).unwrap();
  };

  // clears out conversation locally & remotely
  const clearConversationMessages = async () => {
    dispatch(setDisableConversationSubmit(false));
    return await clearUserConversation(user.user_id);
  };

  const changeDisableConversationSubmit = (value: boolean) => {
    dispatch(setDisableConversationSubmit(value));
  };

  return {
    messageToAvatar: messageToStream,
    userConversationMessages: messages,
    addMessageToConversation,
    clearConversationMessages,
    changeDisableConversationSubmit,
  };
};
