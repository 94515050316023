import {
  Microphone,
  CornersOut,
  CornersIn,
  SquareHalf,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { exitFullscreen, goFullScreen, isFullScreenEnabled } from 'src/utils';
import AudioContext from 'src/contexts/AudioContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { Button } from 'src/components/Button';
import {
  isMobile as isMobilePlatform,
  isTablet as isTabletPlatform,
} from 'react-device-detect';

const SVG_SIZE = 24;
const SVG_SIZE_SMALL = 16;

type AvatarMultiButtonControlProps = {
  isVideoFloating?: boolean;
  onFullscreen: (val: boolean) => void;
};

export const AvatarMultiButtonControl = ({
  isVideoFloating = false,
  onFullscreen,
}: AvatarMultiButtonControlProps) => {
  const { showTranscript } = useContext(AvatarModeContext);
  const {
    expandedMeta: isInFullscreen,
    toggleExpandedMeta,
    toggleTranscript,
  } = useContext(AvatarModeContext);

  const { microphoneTriggerRef, recordInProgress, metaHumanTalking } =
    useContext(AudioContext);
  const { rootContainerRef, avatarFrameRef } = useContext(ForwardRefContext);

  const ICON_SIZE = isVideoFloating ? SVG_SIZE_SMALL : SVG_SIZE;

  const enabledFullScreen = useMemo(() => isFullScreenEnabled(), []);

  const handleCCClick = () => {
    toggleTranscript(!showTranscript);
  };

  const handleMicClick = () => {
    microphoneTriggerRef?.current?.onToggleAction();
  };

  const handleAvatarFullscreen = () => {
    if (isInFullscreen) {
      const exitFullscreenAction = enabledFullScreen
        ? exitFullscreen
        : () => onFullscreen(false);
      exitFullscreenAction();
      toggleTranscript(true); // TODO(olha): This appears to be a bug and does not meet the requirements
      toggleExpandedMeta(false);
      microphoneTriggerRef?.current?.stopRecording();
      return;
    }

    const element =
      isVideoFloating && (isMobilePlatform || isTabletPlatform)
        ? avatarFrameRef?.current
        : rootContainerRef?.current;
    if (element instanceof HTMLDivElement) {
      const goFullScreenAction = enabledFullScreen
        ? () => goFullScreen(element)
        : () => onFullscreen?.(true);
      goFullScreenAction();
    }

    toggleTranscript(false);
    toggleExpandedMeta(true);
  };

  const FullScreenIcon = isInFullscreen ? CornersIn : CornersOut;

  return (
    <div
      className={classNames('nj-meta-human--multi-button-control', {
        floating: isVideoFloating,
      })}
    >
      {!isVideoFloating && (
        <>
          <Button
            className={classNames('nj-meta-human--button-control', 'cc', {
              selected: showTranscript,
            })}
            onClick={handleCCClick}
          >
            <SquareHalf
              size={ICON_SIZE}
              weight={showTranscript ? 'fill' : 'duotone'}
            />
          </Button>
        </>
      )}
      <Button
        className={classNames('nj-meta-human--button-control', 'mic', {
          selected: recordInProgress,
          disabled: metaHumanTalking,
        })}
        onClick={handleMicClick}
      >
        <Microphone
          size={ICON_SIZE}
          weight={recordInProgress ? 'fill' : 'regular'}
        />
      </Button>
      <Button
        className={classNames('nj-meta-human--button-control', 'fullscreen', {
          selected: isInFullscreen,
        })}
        onClick={handleAvatarFullscreen}
      >
        <FullScreenIcon size={ICON_SIZE} />
      </Button>
    </div>
  );
};
