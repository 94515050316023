import { useMemo, useRef } from 'react';
import { BannerType } from 'src/types';
import { CSSTransition } from 'react-transition-group';
import { InsufficientCreditsBanner } from 'src/pages/ManageTasksChatPage/components/InsufficientCreditsBanner';
import { useBanner } from 'src/hooks/useBanner';
import { LowCreditsBanner } from 'src/pages/ManageTasksChatPage/components/LowCreditsBanner';
import { AvatarBanner } from 'src/pages/ManageTasksChatPage/components/AvatarBanner';
import { ANIMATION_TIMEOUT } from 'src/constants';

export const Banner = () => {
  const { bannerType } = useBanner();
  const containerRef = useRef<HTMLDivElement>(null);

  const renderContent = useMemo(() => {
    switch (bannerType) {
      case BannerType.LOW_CREDITS:
        return <LowCreditsBanner />;
      case BannerType.INSUFFICIENT_CREDITS:
        return <InsufficientCreditsBanner />;
      default:
        return <></>;
    }
  }, [bannerType]);

  return (
    <>
      <CSSTransition
        in={bannerType !== null}
        timeout={ANIMATION_TIMEOUT}
        classNames="nj-animate-vertical-appearance"
        unmountOnExit
        nodeRef={containerRef}
      >
        <div ref={containerRef} className="nj-banner-wrapper">
          {renderContent}
        </div>
      </CSSTransition>
      <AvatarBanner />
    </>
  );
};
