import { useContext, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/FormProvider';
import AudioContext from 'src/contexts/AudioContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useSession,
  useBreakpoint,
  useTheme,
  useFullScreen,
  useAvatarQueue,
  useThreads,
  useChatPageQueryParams,
  useSpeechRecognizer,
  useSubmitUserInput,
  useAvatarPreferences,
} from 'src/hooks';
import { ChatMode } from 'src/types';
import { MultiConversationsPanel } from './components/MultiConversationsPanel';
import { SidePanel } from './components/SidePanel';
import { MobileSidePanel } from './components/MobileSidePanel';
import { MultiConversationsListPanel } from './components/MultiConversationsListPanel';
import { MovieStudioFormData } from 'src/types';
import {
  interruptMetahuman,
  replaceFirstWordWithCommands,
  logAction,
} from 'src/utils';
import AvatarModeContext, {
  AvatarModeContextType,
} from 'src/contexts/AvatarModeContext';
import { ConversationsHeader } from './components/ConversationsHeader';
import { ResizePanels } from './components/ResizePanels';
import { Panel } from 'src/components/Panel';
import { TaskView } from 'src/components/FlatAppearance/components/TaskView';
import { AvatarFrame } from 'src/pages/ManageTasksChatPage/components/AvatarFrame';
import { AvatarJoiningQueueModal } from 'src/pages/ManageTasksChatPage/components/AvatarJoininigQueueModal';
import { DEFAULT_CHAT_ID } from 'src/constants';
import { ModalWithGallery } from 'src/components/ModalWithGallery';
import AuthContext from 'src/contexts/AuthContext';
import { UpgradeModal } from 'src/components/UpgradeModal';
import { AvatarSelectorModal } from './components/AvatarSelectorButton/components/AvatarSelectorModal';
import { AvatarUpsellVideoModal } from 'src/components/AvatarUpsellVideoModal';
import { SupportModal } from 'src/components/SupportModal';
import { PromoTooltip } from './components/PromoTooltip/PromoTooltip';

/**
 * Manage Tasks Page displays a list of tasks and task creation is enabled
 * by chat powered communication with EA via audio or text.
 */
export const ManageTasksChatPage = () => {
  const {
    chatMode,
    appUser: user,
    avatarQueue,
    updateCurrentConversationId,
    removeCurrentTaskId,
  } = useSession();

  const { isSettingsPanelExpanded } = useTheme();

  const { isGuestAccess } = useContext(AuthContext);

  const { isTasksListExpanded } = useTheme();
  const { isMobileOrTablet } = useBreakpoint();
  const { getConversationsList, isSubmitHappened } = useThreads();
  const { onSubmitUserInput } = useSubmitUserInput();
  const { avatarAudioLocale } = useAvatarPreferences();
  useChatPageQueryParams();

  const { showTranscript, expandedMeta, shouldShowAvatarAsFloating } =
    useContext<AvatarModeContextType>(AvatarModeContext);

  const { threadInputBoxValue, setThreadInputBoxValue } = useContext(
    ThreadInputBoxContext,
  );

  const { setMetaHumanTalking } = useContext(AudioContext);

  useFullScreen();
  useAvatarQueue();

  const isAvatarMode = useMemo(() => chatMode === ChatMode.AVATAR, [chatMode]);

  // interrupt metahuman during unmount
  useEffect(() => {
    return () => {
      interruptMetahuman(user.user_id);
      setMetaHumanTalking(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set to the default state task list then user refresh the page
  useEffect(() => {
    if (user?.user_id) {
      updateCurrentConversationId(DEFAULT_CHAT_ID);
      removeCurrentTaskId();
      getConversationsList({ reload: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.user_id]);

  // TODO(olha): deprecated form
  const movieStudioFormMethods = useForm<Partial<MovieStudioFormData>>({
    defaultValues: {
      title: '',
      emotion: 'neutral',
      _4k: true,
      portrait: true,
      square: true,
      script: [
        {
          text: '',
          imageURL: '',
        },
      ],
    },
  });

  const handleSpeechRecognizing = useCallback(
    (newValue: string) => {
      setThreadInputBoxValue(`${threadInputBoxValue} ${newValue}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadInputBoxValue],
  );

  const handleSpeechRecognized = useCallback(
    async (newValue: string) => {
      // We don't want to send the message if "stop record" was clicked.
      if (!recordInProgressRef?.current) {
        return;
      }

      const updatedNewValue = threadInputBoxValue
        ? `${threadInputBoxValue} ${
            newValue[0].toLowerCase() + newValue.slice(1)
          }`
        : newValue;

      setThreadInputBoxValue(updatedNewValue);

      const resultValue = replaceFirstWordWithCommands(updatedNewValue);

      logAction('send_message', { type: 'voice' });

      muteMicrophone();
      await onSubmitUserInput(resultValue);
      if (isAvatarMode) {
        // Event that is coming from the iFrame is taking some time and UI behavior in not smooth.
        // So setting metaHumanTalking to true as soon as we send the request.
        setMetaHumanTalking(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadInputBoxValue, onSubmitUserInput],
  );

  const {
    startSpeechRecognizing,
    stopSpeechRecognizing,
    recordInProgressRef,
    microphoneIsMuted,
    muteMicrophone,
    unMuteMicrophone,
  } = useSpeechRecognizer({
    onRecognizing: handleSpeechRecognizing,
    onRecognized: handleSpeechRecognized,
    sourceLanguage: avatarAudioLocale,
  });

  useEffect(() => {
    // TODO(olha): double-check isSubmitHappened here
    if (!isAvatarMode && !isSubmitHappened && microphoneIsMuted) {
      unMuteMicrophone();
    }
  }, [isSubmitHappened, microphoneIsMuted, isAvatarMode, unMuteMicrophone]);

  const pageClasses: string = classNames('nj-fluid--page', 'manage-tasks', {
    'nav-on': isTasksListExpanded,
    'nav-off': !isTasksListExpanded,
    'cc-on': showTranscript,
    'cc-off': !showTranscript,
    'avatar-on': chatMode === ChatMode.AVATAR && avatarQueue?.ready,
    'avatar-off': chatMode !== ChatMode.AVATAR && !avatarQueue?.ready,
    fullscreen: expandedMeta,
    'settings-on': isSettingsPanelExpanded,
    'settings-off': !isSettingsPanelExpanded,
  });

  //  don't wait for user_id in the guest mode
  return user.user_id || isGuestAccess ? (
    <FormProvider<Partial<MovieStudioFormData>>
      methods={movieStudioFormMethods}
    >
      {shouldShowAvatarAsFloating && <AvatarFrame isFloating />}

      <div className={pageClasses}>
        <MultiConversationsListPanel />

        {isMobileOrTablet && (
          <>
            <ConversationsHeader />
            <Panel className="nj-converse-ai flexible">
              <MultiConversationsPanel
                startSpeechRecognizing={startSpeechRecognizing}
                stopSpeechRecognizing={stopSpeechRecognizing}
                unMuteMicrophone={unMuteMicrophone}
              />
            </Panel>
          </>
        )}

        {!isMobileOrTablet && (
          <Panel className="nj-fluid--panel--main">
            <ConversationsHeader />
            <ResizePanels
              inlineAvatar={!shouldShowAvatarAsFloating}
              startSpeechRecognizing={startSpeechRecognizing}
              stopSpeechRecognizing={stopSpeechRecognizing}
              unMuteMicrophone={unMuteMicrophone}
            />
          </Panel>
        )}

        {!expandedMeta &&
          (!isMobileOrTablet ? <SidePanel /> : <MobileSidePanel />)}

        <AvatarJoiningQueueModal />
      </div>

      <TaskView />

      <ModalWithGallery />

      <PromoTooltip />

      {isMobileOrTablet && <AvatarSelectorModal />}

      <AvatarUpsellVideoModal />

      <UpgradeModal />

      <SupportModal />
    </FormProvider>
  ) : (
    <></>
  );
};
