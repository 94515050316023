import { CommonNavigationButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons';
import { Archive } from '@phosphor-icons/react';
import { useSession, useTasks } from 'src/hooks';

export const ArchiveTaskButton = () => {
  const {
    selectedTaskDetail: { selectedTaskId },
  } = useSession();
  const { archiveTask } = useTasks();

  const handleArchiveTask = () => {
    if (!selectedTaskId) return;

    archiveTask(selectedTaskId);
  };

  return (
    <CommonNavigationButton
      title="Archive Task"
      IconComponent={Archive}
      onClick={handleArchiveTask}
    />
  );
};
