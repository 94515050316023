import { Link } from 'react-router-dom';
import { ArrowCircleUp } from '@phosphor-icons/react';
import { AppRoutes, SubscriptionNestedRoutes } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';
import styles from './AccountActivityLink.module.scss';

export const AccountActivityLink = () => {
  return (
    <Link
      to={`${AppRoutes.SUBSCRIPTION}/${SubscriptionNestedRoutes.ACTIVITY}`}
      className={styles.root}
    >
      <ArrowCircleUp size={SVG_SIZE_M} />
      Account activity
    </Link>
  );
};
