import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useVisible, useBreakpoint } from 'src/hooks';
import { OverlayBackground } from 'src/components/OverlayBackground';
import { ManageAccountNavigation } from './components/ManageAccountNavigation';
import { ToggleManageAccountNavigation } from './components/ToggleManageAccountNavigation/';
import styles from './ManageAccountPage.module.scss';
import { ANIMATION_TIMEOUT } from 'src/constants';

export const ManageAccountPage = () => {
  const { isMobileOrTablet } = useBreakpoint();

  const overlayRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);

  const { isVisible, handleVisibilityRemove, handleVisibilitySet } =
    useVisible();

  return (
    <div className={styles.root}>
      {!isMobileOrTablet && <ManageAccountNavigation />}

      {isMobileOrTablet && (
        <>
          <OverlayBackground
            showOverlay={isVisible}
            onClick={handleVisibilityRemove}
            zIndex={10}
            className="transparent-tablet"
            ref={overlayRef}
          />

          <CSSTransition
            in={isVisible}
            timeout={ANIMATION_TIMEOUT}
            classNames="nj-animate-horizontal-appearance-ltr"
            unmountOnExit
            nodeRef={navRef}
          >
            <ManageAccountNavigation
              ref={navRef}
              onClose={handleVisibilityRemove}
            />
          </CSSTransition>
        </>
      )}

      <div className={styles.container}>
        {isMobileOrTablet && (
          <ToggleManageAccountNavigation onClick={handleVisibilitySet} />
        )}

        <Outlet />
      </div>
    </div>
  );
};
