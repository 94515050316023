import { CompanyLinksSet } from 'src/pages/AuthPages/components/CompanyLinksSet';
import { GuestAuthLinks } from '../GuestAuthLinks';
import { useSession } from 'src/hooks';
import { UserTier } from 'src/types/models/UserTier';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { LabelPro } from 'src/components/LabelPro';
import { LabelLimitedTime } from 'src/components/LabelLimitedTime';

export const TiersAuthFooter = () => {
  const { appUser } = useSession();

  const renderContent = () => {
    switch (appUser.tier_id) {
      case UserTier.OPEN:
        return (
          <>
            <h4 className="nj-tier-auth-footer-title">
              <span>Try Ninja Pro for 90% off</span>
              <LabelLimitedTime />
            </h4>

            <p className="nj-tier-auth-footer-description">
              Try advanced meeting negotiations, deep research, access to third
              party LLMs and video chat with Ninja.
            </p>

            <GuestAuthLinks />
          </>
        );
      case UserTier.FREE:
        return (
          <>
            <h4 className="nj-tier-auth-footer-title">
              Try Ninja Pro for 90% Off
            </h4>

            <p className="nj-tier-auth-footer-description">
              Try advanced meeting negotiations, deep research, access to third
              party LLMs and video chat with Ninja.
            </p>

            <UpgradeButton />
          </>
        );
      case UserTier.PAID:
      case UserTier.TESTING:
        return (
          <div className="nj-tier-auth-footer-current-plan-row">
            <span>Current plan:</span>
            <LabelPro />
          </div>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className="nj-tier-auth-footer">
      {renderContent()}

      <div className="nj-tier-auth-footer-links-wrapper">
        <CompanyLinksSet />
      </div>
    </div>
  );
};
