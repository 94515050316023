import className from 'classnames';
import { PaymentPlanOption, PaymentPlanOptionWithSale } from 'src/types';
import { roundPriceAmount } from '../../utils/prepareData';
import './PlanOptionWithSale.scss';

interface PlanOptionProps {
  isActive?: boolean;
  option: PaymentPlanOptionWithSale;
  onClick: (option: PaymentPlanOption) => void;
}

export const PlanOptionWithSale = ({
  isActive,
  option,
  onClick,
}: PlanOptionProps) => {
  const handleClick = () => {
    onClick(option);
  };

  return (
    <button
      onClick={handleClick}
      className={className('nj-plan-option-with-sale', { active: isActive })}
    >
      <>
        <span className="nj-plan-option-with-sale--base-amount">
          ${roundPriceAmount(option.amount)} /{option.isAnnual ? 'yr' : 'mo'}
        </span>
        <div>
          <span className="nj-plan-option-with-sale--title">
            ${option.newAmount}
          </span>
          <span className="nj-plan-option-with-sale--subtitle">
            /{option.isAnnual ? 'yr' : 'mo'}
          </span>
        </div>
      </>
    </button>
  );
};
