import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DotsThreeOutlineVertical,
  IconWeight,
  ArrowUp,
  ChatsCircle,
  Broom,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { DEFAULT_DATE_FORMAT } from 'src/constants';
import SessionContext from 'src/contexts/SessionContext';
import AuthContext from 'src/contexts/AuthContext';
import { useBreakpoint, useThreads, useSession, useTheme } from 'src/hooks';
import { Conversation, ChatsFilter } from 'src/types';
import { Button } from 'src/components/Button';
import { Spinner } from 'src/components/Loading';
import { ConversationControl } from '../ConversationControl';
import { ConversationGroup } from '../ConversationGroup';
import { DEFAULT_PAGE_SIZE } from 'src/constants';
import { UpsellChatsMessage } from 'src/components/UpsellChatsMessage';

const SVG_SIZE_LG = 48;

const ICON_PROPS = {
  size: 20,
  color: 'currentColor',
  weight: 'regular' as IconWeight,
};

enum ConversationsListEmptyStates {
  NO_RESULTS = `There are no matches for your criteria.`,
  DEFAULT = `Starting a chat with Ninja creates new conversation threads. Dive in and chat whenever you are ready.`,
}

export const ConversationsList = () => {
  const {
    currentConversationId,
    chatsFilter = ChatsFilter.ALL,
    updateChatsPageSize,
    removeCurrentTaskId,
    isOpenTier,
  } = useSession();
  const { isTasksListExpanded } = useTheme();
  const { isMobile } = useBreakpoint();

  const { onOpenTaskListControlsPanel } = useContext(SessionContext);
  const { isGuestAccess } = useContext(AuthContext);

  const {
    conversations,
    pageToken = '',
    shouldAnimate: shouldAnimateListItem,
    getConversationsList,
  } = useThreads();

  // attn: temporarily disabled
  // const [isShowSearch, setIsShowSearch] = useState<boolean>(false);
  // const [searchValue, setSearchValue] = useState<string>('');
  // TODO(olha): implement actual loading with redux pattern
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(false);

  const threadsListScrollRef = useRef<HTMLDivElement>(null);

  const [isButtonScrollUpVisible, setIsButtonScrollUpVisible] = useState(false);

  const scrollTo = useCallback((x = 0) => {
    if (threadsListScrollRef.current) {
      threadsListScrollRef.current.scrollTo({
        top: x,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  // To be able to see infinite scroll,
  // we need to always provide slightly larger
  // amount of tasks per page
  const pageSize = useCallback(() => {
    if (threadsListScrollRef.current) {
      const { clientHeight = 0 } = threadsListScrollRef.current || {};
      if (clientHeight > 0) {
        const loadingPageSize = Math.ceil(clientHeight / 48);
        return loadingPageSize;
      }
    }

    return DEFAULT_PAGE_SIZE;
  }, []);

  useEffect(() => {
    const size = pageSize();
    updateChatsPageSize(size);

    const scrollRef = threadsListScrollRef?.current;

    const onConversationsScroll = () => {
      const {
        scrollTop = 0,
        clientHeight = 0,
        scrollHeight = 0,
      } = scrollRef || {};

      setIsButtonScrollUpVisible(scrollTop > 30);

      if (!scrolledToBottom) {
        if (scrollTop + clientHeight >= scrollHeight) {
          setScrolledToBottom(true);
        }
      }
    };

    setIsLoading(false);

    scrollRef?.addEventListener('scroll', onConversationsScroll);

    return () => {
      scrollRef?.removeEventListener('scroll', onConversationsScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // no token arrives means no conversations to fetch
    if (scrolledToBottom && pageToken !== '') {
      // load with page token in mind as next page
      getConversationsList({ filter: chatsFilter, reload: false });
      setScrolledToBottom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledToBottom, pageToken]);

  const groupedConversations: Record<string, Conversation[]> = useMemo(() => {
    const acc: Record<string, Conversation[]> = {};

    for (const conversation of conversations) {
      const groupedDate = dayjs(conversation.timestamp).format(
        DEFAULT_DATE_FORMAT,
      );

      if (!acc[groupedDate]) {
        acc[groupedDate] = [conversation];
      } else {
        acc[groupedDate] = [...acc[groupedDate], conversation];
      }
    }

    return acc;
  }, [conversations]);

  // empty flag for empty states
  const isEmpty = useMemo(() => {
    return Object.keys(groupedConversations).length === 0;
  }, [groupedConversations]);

  // pick the right empty state message
  const emptyStateMessage = useMemo(() => {
    switch (chatsFilter) {
      case ChatsFilter.ALL:
        return (
          <Fragment>
            <span className="nj-task-list--icon">
              <ChatsCircle size={SVG_SIZE_LG} weight="light" />
            </span>
            <span className="nj-task-list--message">
              {ConversationsListEmptyStates.DEFAULT}
            </span>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <span className="nj-task-list--icon">
              <Broom size={SVG_SIZE_LG} weight="light" />
            </span>
            <span className="nj-task-list--message">
              {ConversationsListEmptyStates.NO_RESULTS}
            </span>
          </Fragment>
        );
    }
  }, [chatsFilter]);

  // attn: temporarily disabled
  //const handleClearSearch = () => {
  //  setSearchValue('');
  //};

  // attn: temporarily disabled
  //const handleCloseSearch = () => {
  //  setIsShowSearch(false);
  //  handleClearSearch();
  //};

  return (
    <div
      className={classNames('nj-task-list', {
        'reduced-height': isGuestAccess,
      })}
      ref={threadsListScrollRef}
    >
      <div
        className="nj-task-list--header"
        data-testid="task-header"
        onClick={removeCurrentTaskId}
      >
        <div className="nj-task-list--header-wrapper">
          <h5
            className="nj-task-list--header-title"
            id="onboarding-element-mobile-2"
          >
            Chats
          </h5>
          <div className="nj-task-list--header-buttons-wrapper">
            {isMobile ? (
              <button onClick={onOpenTaskListControlsPanel}>
                <DotsThreeOutlineVertical {...ICON_PROPS} alt="Tasks menu" />
              </button>
            ) : (
              <ConversationControl type="menu" />
            )}
          </div>
        </div>
      </div>
      <div className="nj-task-list--container">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {isOpenTier && <UpsellChatsMessage />}
            {Object.entries(groupedConversations).map(([date, group]) => (
              <ConversationGroup
                key={date}
                date={date}
                conversations={group}
                selectedConversationId={currentConversationId}
                shouldAnimateListItem={shouldAnimateListItem}
                scrollTo={scrollTo}
              />
            ))}
            {!isEmpty && (
              <Spinner
                inline={true}
                visibility={pageToken !== '' && scrolledToBottom}
              />
            )}

            {!isLoading && isEmpty && (
              <div className="nj-task-list--empty-list-warning">
                {emptyStateMessage}
              </div>
            )}
          </>
        )}
      </div>
      <Button
        className={classNames('nj-tasks-list--back-to-top--button', {
          visible: isButtonScrollUpVisible && isTasksListExpanded,
        })}
        onClick={() => scrollTo()}
      >
        <span>
          <ArrowUp {...ICON_PROPS} />
        </span>
      </Button>
    </div>
  );
};
