/* eslint-disable */
// import { performanceMarkerStart, performanceMarkerEnd } from './performanceMarkers';

/*!
 * Reconnecting WebSocket
 * by Pedro Ladaria <pedro.ladaria@gmail.com>
 * https://github.com/pladaria/reconnecting-websocket
 * License MIT
 */

import { datadogLogs } from '@datadog/browser-logs';

const logger = datadogLogs.logger;

export class Event {
  public target: any;
  public type: string;
  constructor(type: string, target: any) {
    this.target = target;
    this.type = type;
  }
}

export class ErrorEvent extends Event {
  public message: string;
  public error: Error;
  constructor(error: Error, target: any) {
    super('error', target);
    this.message = error.message;
    this.error = error;
  }
}

export class CloseEvent extends Event {
  public code: number;
  public reason: string;
  public wasClean = true;
  constructor(code = 1000, reason = '', target: any) {
    super('close', target);
    this.code = code;
    this.reason = reason;
  }
}
export interface WebSocketEventMap {
  close: CloseEvent;
  error: ErrorEvent;
  message: MessageEvent;
  open: Event;
}

export interface WebSocketEventListenerMap {
  close: (
    event: CloseEvent,
  ) => void | { handleEvent: (event: CloseEvent) => void };
  error: (
    event: ErrorEvent,
  ) => void | { handleEvent: (event: ErrorEvent) => void };
  message: (
    event: MessageEvent,
  ) => void | { handleEvent: (event: MessageEvent) => void };
  open: (event: Event) => void | { handleEvent: (event: Event) => void };
}

export type ProtocolsProvider =
  | null
  | string
  | string[]
  | (() => string | string[] | null)
  | (() => Promise<string | string[] | null>);

const getGlobalWebSocket = (): WebSocket | undefined => {
  if (typeof WebSocket !== 'undefined') {
    // @ts-ignore
    return WebSocket;
  }
};

/**
 * Returns true if given argument looks like a WebSocket class
 */
const isWebSocket = (w: any) =>
  typeof w !== 'undefined' && !!w && w.CLOSING === 2;

// Creating Ping-pong functionality to track
// the life of the socket connection
const PING_MESSAGE = 'ping';

// Reconnecting WebSocket Defaults
const DEFAULT_PING_INTERVAL = 15000;
const DEFAULT_CONNECTION_TIMEOUT = 4000;
const DEFAULT_RECONNECTION_GROWTH_FACTOR = 1.3;
const DEFAULT_MIN_UPTIME = 5000;
const DEFAULT_MAX_RECONNECTION_DELAY = 10000;
const DEFAULT_MIN_RECONNECTION_DELAY = 1000 + Math.random() * 4000;

// Props to create a reconnecting web socket class.
export type Options = {
  WebSocket?: any;
  maxReconnectionDelay?: number;
  minReconnectionDelay?: number;
  reconnectionDelayGrowFactor?: number;
  minUptime?: number;
  connectionTimeout?: number;
  maxRetries?: number;
  maxEnqueuedMessages?: number;
  startClosed?: boolean;
  debug?: boolean;
  shouldPing?: boolean;
};

// Default options object to create a web socket connection.
const DEFAULT = {
  maxReconnectionDelay: DEFAULT_MAX_RECONNECTION_DELAY,
  minReconnectionDelay: DEFAULT_MIN_RECONNECTION_DELAY,
  minUptime: DEFAULT_MIN_UPTIME,
  reconnectionDelayGrowFactor: DEFAULT_RECONNECTION_GROWTH_FACTOR,
  connectionTimeout: DEFAULT_CONNECTION_TIMEOUT,
  maxRetries: Infinity,
  maxEnqueuedMessages: Infinity,
  startClosed: false,
  debug: false,
  shouldPing: false,
  pingInterval: DEFAULT_PING_INTERVAL,
};

export type UrlProvider = string | (() => string) | (() => Promise<string>);

export type SocketMessage = string | ArrayBuffer | Blob | ArrayBufferView;

export type ListenersMap = {
  error: Array<WebSocketEventListenerMap['error']>;
  message: Array<WebSocketEventListenerMap['message']>;
  open: Array<WebSocketEventListenerMap['open']>;
  close: Array<WebSocketEventListenerMap['close']>;
};

export default class ReconnectingWebSocket {
  // connecting heartbeat with redux datastore
  private _ws?: WebSocket;
  private _listeners: ListenersMap = {
    error: [],
    message: [],
    open: [],
    close: [],
  };
  private _retryCount = -1;
  private _uptimeTimeout: any;
  private _connectTimeout: any;
  private _shouldReconnect = true;
  private _connectLock = false;
  private _binaryType: BinaryType = 'blob';
  private _closeCalled = false;
  private _messageQueue: SocketMessage[] = [];
  private _pingInterval?: NodeJS.Timeout;

  private readonly _url: UrlProvider;
  private readonly _protocols?: ProtocolsProvider;
  private readonly _options: Options;
  private _pongInterval?: NodeJS.Timeout;
  private _lastPongTime: number = 0;

  constructor(
    url: UrlProvider | string,
    protocols?: ProtocolsProvider,
    options: Options = {},
  ) {
    this._url = url;
    this._protocols = protocols;
    this._options = options;
    if (this._options.startClosed) {
      this._shouldReconnect = false;
    }

    // establishing socket connection
    this._connect();
  }

  // setup pinging routine
  private _setupPing() {
    return setInterval(() => {
      if (this._ws?.readyState === this.OPEN) {
        this._debug('nj socket: ping signal');
        this.send(PING_MESSAGE);
      }
    }, DEFAULT_PING_INTERVAL);
  }

  private _setupPong() {
    this._lastPongTime = new Date().getTime();
    return setInterval(() => {
      if (
        new Date().getTime() - this._lastPongTime >
        DEFAULT_PING_INTERVAL * 2 + 5000
      ) {
        this._handleError(
          new ErrorEvent(
            Error('Two pong messages was missing in a row.'),
            this,
          ),
        );
      }
    }, DEFAULT_PING_INTERVAL);
  }

  static get CONNECTING() {
    return 0;
  }
  static get OPEN() {
    return 1;
  }
  static get CLOSING() {
    return 2;
  }
  static get CLOSED() {
    return 3;
  }

  get CONNECTING() {
    return ReconnectingWebSocket.CONNECTING;
  }
  get OPEN() {
    return ReconnectingWebSocket.OPEN;
  }
  get CLOSING() {
    return ReconnectingWebSocket.CLOSING;
  }
  get CLOSED() {
    return ReconnectingWebSocket.CLOSED;
  }

  get binaryType() {
    return this._ws ? this._ws.binaryType : this._binaryType;
  }

  set binaryType(value: BinaryType) {
    this._binaryType = value;
    if (this._ws) {
      this._ws.binaryType = value;
    }
  }

  /**
   * Returns the number or connection retries
   */
  get retryCount(): number {
    return Math.max(this._retryCount, 0);
  }

  /**
   * The number of bytes of data that have been queued using calls to send() but not yet
   * transmitted to the network. This value resets to zero once all queued data has been sent.
   * This value does not reset to zero when the connection is closed; if you keep calling send(),
   * this will continue to climb. Read only
   */
  get bufferedAmount(): number {
    const bytes = this._messageQueue.reduce((acc, message) => {
      if (typeof message === 'string') {
        acc += message.length; // not byte size
      } else if (message instanceof Blob) {
        acc += message.size;
      } else {
        acc += message.byteLength;
      }
      return acc;
    }, 0);
    return bytes + (this._ws ? this._ws.bufferedAmount : 0);
  }

  /**
   * The extensions selected by the server. This is currently only the empty string or a list of
   * extensions as negotiated by the connection
   */
  get extensions(): string {
    return this._ws ? this._ws.extensions : '';
  }

  /**
   * A string indicating the name of the sub-protocol the server selected;
   * this will be one of the strings specified in the protocols parameter when creating the
   * WebSocket object
   */
  get protocol(): string {
    return this._ws ? this._ws.protocol : '';
  }

  /**
   * The current state of the connection; this is one of the Ready state constants
   */
  get readyState(): number {
    if (this._ws) {
      return this._ws.readyState;
    }
    return this._options.startClosed
      ? ReconnectingWebSocket.CLOSED
      : ReconnectingWebSocket.CONNECTING;
  }

  /**
   * The URL as resolved by the constructor
   */
  get url(): string {
    return this._ws ? this._ws.url : '';
  }

  /**
   * Whether the websocket object is now in reconnectable state
   */
  get shouldReconnect(): boolean {
    return this._shouldReconnect;
  }

  /**
   * An event listener to be called when the WebSocket connection's readyState changes to CLOSED
   */
  public onclose: ((event: CloseEvent) => void) | null = null;

  /**
   * An event listener to be called when an error occurs
   */
  public onerror: ((event: ErrorEvent) => void) | null = null;

  /**
   * An event listener to be called when a message is received from the server
   */
  public onmessage: ((event: MessageEvent) => void) | null = null;

  /**
   * An event listener to be called when the WebSocket connection's readyState changes to OPEN;
   * this indicates that the connection is ready to send and receive data
   */
  public onopen: ((event: Event) => void) | null = null;

  /**
   * Closes the WebSocket connection or connection attempt, if any. If the connection is already
   * CLOSED, this method does nothing
   */
  public close(code = 1000, reason?: string) {
    this._closeCalled = true;
    this._shouldReconnect = false;
    // if we force close the connection, set heartbeat to off
    this._clearTimeouts();
    this._clearIntervals();
    if (!this._ws) {
      this._debug('close enqueued: no ws instance');
      return;
    }
    if (this._ws.readyState === this.CLOSED) {
      this._debug('close: already closed');
      return;
    }
    this._ws.close(code, reason);
  }

  /**
   * Closes the WebSocket connection or connection attempt and connects again.
   * Resets retry counter;
   */
  public reconnect(code?: number, reason?: string) {
    // connection was closed, let's show it
    this._shouldReconnect = true;
    this._closeCalled = false;
    this._retryCount = -1;
    if (!this._ws || this._ws.readyState === this.CLOSED) {
      this._connect();
    } else {
      this._disconnect(code, reason);
      this._connect();
    }
  }

  /**
   * Enqueue specified data to be transmitted to the server over the WebSocket connection
   */
  public send(data: SocketMessage) {
    if (this._ws && this._ws.readyState === this.OPEN) {
      this._debug('send', data);
      this._ws.send(data);
    } else {
      const { maxEnqueuedMessages = DEFAULT.maxEnqueuedMessages } =
        this._options;
      if (this._messageQueue.length < maxEnqueuedMessages) {
        this._debug('enqueue', data);
        this._messageQueue.push(data);
      }
    }
  }

  /**
   * Register an event handler of a specific event type
   */
  public addEventListener<T extends keyof WebSocketEventListenerMap>(
    type: T,
    listener: WebSocketEventListenerMap[T],
  ): void {
    if (this._listeners[type]) {
      // @ts-ignore
      this._listeners[type].push(listener);
    }
  }

  public dispatchEvent(event: Event) {
    const listeners =
      this._listeners[event.type as keyof WebSocketEventListenerMap];
    if (listeners) {
      for (const listener of listeners) {
        this._callEventListener(event, listener);
      }
    }
    return true;
  }

  /**
   * Removes an event listener
   */
  public removeEventListener<T extends keyof WebSocketEventListenerMap>(
    type: T,
    listener: WebSocketEventListenerMap[T],
  ): void {
    if (this._listeners[type]) {
      // @ts-ignore
      this._listeners[type] = this._listeners[type].filter(
        // @ts-ignore
        (l) => l !== listener,
      );
    }
  }

  private _debug(...args: any[]) {
    if (this._options.debug) {
      // not using spread because compiled version uses Symbols
      // tslint:disable-next-line
      // console.log.apply(console, ['RWS>', ...args]);
      logger.info(['RWS>', ...args].join(' '));
    }
  }

  private _getNextDelay() {
    const {
      reconnectionDelayGrowFactor = DEFAULT.reconnectionDelayGrowFactor,
      minReconnectionDelay = DEFAULT.minReconnectionDelay,
      maxReconnectionDelay = DEFAULT.maxReconnectionDelay,
    } = this._options;
    let delay = 0;
    if (this._retryCount > 0) {
      delay =
        minReconnectionDelay *
        Math.pow(reconnectionDelayGrowFactor, this._retryCount - 1);
      if (delay > maxReconnectionDelay) {
        delay = maxReconnectionDelay;
      }
    }
    this._debug('next delay', delay);
    return delay;
  }

  private _wait(): Promise<void> {
    // waiting means, we are trying to reconnect
    // and the socket is not connected
    return new Promise((resolve) => {
      setTimeout(resolve, this._getNextDelay());
    });
  }

  private _getNextProtocols(
    protocolsProvider: ProtocolsProvider | null,
  ): Promise<string | string[] | null> {
    if (!protocolsProvider) return Promise.resolve(null);

    if (
      typeof protocolsProvider === 'string' ||
      Array.isArray(protocolsProvider)
    ) {
      return Promise.resolve(protocolsProvider);
    }

    if (typeof protocolsProvider === 'function') {
      const protocols = protocolsProvider();
      if (!protocols) return Promise.resolve(null);

      if (typeof protocols === 'string' || Array.isArray(protocols)) {
        return Promise.resolve(protocols);
      }

      // @ts-ignore redundant check
      if (protocols.then) {
        return protocols;
      }
    }

    throw Error('Invalid protocols');
  }

  private _getNextUrl(urlProvider: UrlProvider): Promise<string> {
    if (typeof urlProvider === 'string') {
      return Promise.resolve(urlProvider);
    }
    if (typeof urlProvider === 'function') {
      const url = urlProvider();
      if (typeof url === 'string') {
        return Promise.resolve(url);
      }
      // @ts-ignore redundant check
      if (url.then) {
        return url;
      }
    }
    throw Error('Invalid URL');
  }

  private _connect() {
    if (this._connectLock || !this._shouldReconnect) {
      return;
    }
    this._connectLock = true;

    const {
      maxRetries = DEFAULT.maxRetries,
      connectionTimeout = DEFAULT.connectionTimeout,
      WebSocket = getGlobalWebSocket(),
    } = this._options;

    if (this._retryCount >= maxRetries) {
      this._debug('max retries reached', this._retryCount, '>=', maxRetries);
      return;
    }

    this._retryCount++;

    this._debug('connect', this._retryCount);
    this._removeListeners();
    if (!isWebSocket(WebSocket)) {
      throw Error('No valid WebSocket class provided');
    }
    this._wait()
      .then(() =>
        Promise.all([
          this._getNextUrl(this._url),
          this._getNextProtocols(this._protocols || null),
        ]),
      )
      .then(([url, protocols]) => {
        // close could be called before creating the ws
        if (this._closeCalled) {
          this._connectLock = false;
          return;
        }
        this._debug('connect', url);
        this._ws = protocols
          ? new WebSocket(url, protocols)
          : new WebSocket(url);
        this._ws!.binaryType = this._binaryType;
        this._connectLock = false;
        this._addListeners();
        // finally reconnected, show the green heartbeat
        // force the connection to timeout, but it is not a true timeout!
        this._connectTimeout = setTimeout(
          () => this._handleTimeout(),
          connectionTimeout,
        );
      })
      .catch((err) => {
        this._connectLock = false;
        // true error occured, show red heartbeat
        this._handleError(new ErrorEvent(Error(err.message), this));
      });
  }

  private _handleTimeout() {
    this._debug('timeout event');
    // it is not an actual error, but it is a common routine
    // same as in error to ensure that we reconnect upon timeout!
    this._handleError(new ErrorEvent(Error('TIMEOUT'), this));
  }

  private _disconnect(code = 1000, reason?: string) {
    this._clearTimeouts();
    this._clearIntervals();
    // set heartbeat to off since we are forcing a disconnect
    if (!this._ws) {
      return;
    }
    this._removeListeners();
    try {
      this._ws.close(code, reason);
      this._handleClose(new CloseEvent(code, reason, this));
    } catch (error) {
      // ignore
    }
  }

  private _acceptOpen() {
    this._debug('accept open');
    this._retryCount = 0;
  }

  private _callEventListener<T extends keyof WebSocketEventListenerMap>(
    event: WebSocketEventMap[T],
    listener: WebSocketEventListenerMap[T],
  ) {
    if ('handleEvent' in listener) {
      // @ts-ignore
      listener.handleEvent(event);
    } else {
      // @ts-ignore
      listener(event);
    }
  }

  private _handleOpen = (event: Event) => {
    this._debug('open event');
    const { minUptime = DEFAULT.minUptime } = this._options;

    clearTimeout(this._connectTimeout);
    this._uptimeTimeout = setTimeout(() => this._acceptOpen(), minUptime);

    this._ws!.binaryType = this._binaryType;

    // send enqueued messages (messages sent before websocket open event)
    this._messageQueue.forEach((message) => this._ws?.send(message));
    this._messageQueue = [];

    if (this.onopen) {
      this.onopen(event);
    }
    this._listeners.open.forEach((listener) =>
      this._callEventListener(event, listener),
    );

    // adding ping-pong communication
    if (this._options.shouldPing) {
      this._pingInterval = this._setupPing();
      this._pongInterval = this._setupPong();
    }
  };

  private _handleMessage = (event: MessageEvent) => {
    //this._debug('message event');

    if (event.data === 'pong') {
      this._lastPongTime = new Date().getTime();
      return;
    }

    if (this.onmessage) {
      this.onmessage(event);
    }
    this._listeners.message.forEach((listener) =>
      this._callEventListener(event, listener),
    );
  };

  private _handleError = (event: ErrorEvent) => {
    this._debug('error event', event.message);
    this._disconnect(
      undefined,
      event.message === 'TIMEOUT' ? 'timeout' : undefined,
    );

    if (this.onerror) {
      this.onerror(event);
    }
    this._debug('exec error listeners');
    this._listeners.error.forEach((listener) =>
      this._callEventListener(event, listener),
    );

    this._connect();
  };

  private _handleClose = (event: CloseEvent) => {
    this._debug('close event');
    this._clearTimeouts();
    this._clearIntervals();

    if (this._shouldReconnect) {
      this._connect();
    }

    if (this.onclose) {
      this.onclose(event);
    }
    this._listeners.close.forEach((listener) =>
      this._callEventListener(event, listener),
    );
  };

  private _removeListeners() {
    if (!this._ws) {
      return;
    }
    this._debug('removeListeners');
    this._ws.removeEventListener('open', this._handleOpen);
    this._ws.removeEventListener('close', this._handleClose);
    this._ws.removeEventListener('message', this._handleMessage);
    // @ts-ignore
    this._ws.removeEventListener('error', this._handleError);
  }

  private _addListeners() {
    if (!this._ws) {
      return;
    }
    this._debug('addListeners');
    this._ws.addEventListener('open', this._handleOpen);
    this._ws.addEventListener('close', this._handleClose);
    this._ws.addEventListener('message', this._handleMessage);
    // @ts-ignore
    this._ws.addEventListener('error', this._handleError);
  }

  private _clearTimeouts() {
    clearTimeout(this._connectTimeout);
    clearTimeout(this._uptimeTimeout);
  }

  private _clearIntervals() {
    // clear out ping-pong intervals
    clearInterval(this._pingInterval);
    clearInterval(this._pongInterval);
    this._debug('nj socket: clear ping-pong');
  }
}
