import {
  FileCsv,
  FileDoc,
  FilePdf,
  FileText,
  FileTxt,
  FileXls,
  XCircle,
} from '@phosphor-icons/react';
import { useContext } from 'react';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { Spinner } from 'src/components/Loading';

const SVG_SIZE = 20;

export const AttachedFileItem = () => {
  const { threadInputBoxFile, deleteAttachment, attachmentLoading } =
    useContext<ThreadInputBoxContextType>(ThreadInputBoxContext);

  const renderExceptionIcon = (type: string) => {
    switch (type) {
      case 'application/pdf':
        return <FilePdf size={SVG_SIZE} />;
      case 'text/plain':
        return <FileTxt size={SVG_SIZE} />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <FileDoc size={SVG_SIZE} />;
      case 'text/csv':
        return <FileCsv size={SVG_SIZE} />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <FileXls size={SVG_SIZE} />;
      case 'text/tab-separated-values':
      case 'application/rtf':
      default:
        return <FileText size={SVG_SIZE} />;
    }
  };

  if (!threadInputBoxFile) {
    return null;
  }

  return (
    <div className="nj-thread-input-box--file-label">
      <div className="nj-thread-input-box--file-label-text">
        {attachmentLoading ? (
          <Spinner size={SVG_SIZE} inline />
        ) : (
          renderExceptionIcon(threadInputBoxFile.type)
        )}
        <span className="nj-thread-input-box--file-name ellipsis">
          {threadInputBoxFile?.name}
        </span>
      </div>
      <button
        type="button"
        onClick={deleteAttachment}
        className="nj-thread-input-box--file-label-close"
      >
        <XCircle size={20} weight="fill" />
      </button>
    </div>
  );
};
