import { useMemo, useRef } from 'react';
import {
  Message,
  MessageType,
  isResearchTaskCreationCard,
  isChitChatCard,
} from 'src/types';
import { formatHours } from 'src/utils';
import { useTimeZone, useBreakpoint } from 'src/hooks';
import { MessageAuthorPicture } from '../MessageAuthorPicture';
import { MessageAuthorName } from '../MessageAuthorName';
import { SkillStyleToneTags } from '../SkillStyleToneTags';
import { MessageFooter } from '../MessageFooter';
import { ThreadMessageBody } from '../ThreadMessageBody';
import { ThreadMessageActions } from '../ThreadMessageActions';
import classNames from 'classnames';
import { RelatedQuestions } from 'src/components/RelatedQuestions';

interface ThreadMessageProps {
  message: Message;
  canShowProgress?: boolean;
}

export const ThreadMessage = ({
  message,
  canShowProgress,
}: ThreadMessageProps) => {
  const { userTimeZone } = useTimeZone();
  const { isMobile } = useBreakpoint();
  const { timestamp, message_type, payload } = message;
  const messageRef = useRef<HTMLDivElement>(null);

  const date = useMemo(
    () => formatHours(timestamp, userTimeZone),
    [timestamp, userTimeZone],
  );

  const relatedQuestions = useMemo(() => {
    if (isResearchTaskCreationCard(payload)) {
      return payload?.research_card?.data?.related_questions;
    }

    if (isChitChatCard(payload)) {
      return payload?.data?.related_questions;
    }
  }, [payload]);

  return (
    <div className="nj-thread-message" ref={messageRef}>
      <div className="nj-thread-message--author-wrapper">
        <div className="nj-thread-message--flex-wrapper">
          <MessageAuthorPicture
            message={message}
            canShowProgress={canShowProgress}
          />

          {isMobile && (
            <>
              <MessageAuthorName message={message} />
              <span className="nj-thread-message--date">{date}</span>
            </>
          )}
        </div>

        {isMobile && <ThreadMessageActions message={message} />}
      </div>

      <div className="nj-thread-message--container">
        {isMobile ? (
          <div className="nj-thread-message--header">
            <SkillStyleToneTags message={message} />
          </div>
        ) : (
          <div
            className={classNames('nj-thread-message--header', {
              researcher:
                message_type === MessageType.RESEARCH_TASK_CREATION_CARD,
            })}
          >
            <div className="nj-thread-message--flex-wrapper">
              <MessageAuthorName message={message} />

              <SkillStyleToneTags message={message} />

              <span className="nj-thread-message--date">{date}</span>
            </div>

            <ThreadMessageActions message={message} />
          </div>
        )}

        <ThreadMessageBody message={message} />

        <MessageFooter message={message} />

        <RelatedQuestions
          data={relatedQuestions}
          defaultExpanded={canShowProgress}
        />
      </div>
    </div>
  );
};
