import { FormProvider } from 'src/components/FormProvider';
import { useForm } from 'react-hook-form';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { Icon } from 'src/components/Icon';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { Checkbox } from 'src/components/Checkbox';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import { useSession, useUserData } from 'src/hooks';
import type { ExternModelOption } from 'src/types/models/ExternModelOption';
import { LabelInsufficientCredits } from 'src/components/LabelInsufficientCredits';
import { LabelPro } from 'src/components/LabelPro';
import './ExternalModelsSection.scss';
import { convertToValueWithoutDots, getExternalModelData } from 'src/utils';
import log from 'src/utils/logger';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { ExternalModelsSettings } from 'src/types';
import {
  externalModelProvidersSet,
  newExternalModelsList,
} from 'src/constants';
import { FormSwitch } from 'src/components/FormSwitch';
import { LabelNew } from 'src/components/LabelNew';
import classNames from 'classnames';

const SVG_SIZE = 20;

export const ExternalModelsSection = () => {
  const { appUser, isFreeTier, isProTier, isOpenTier } = useSession();
  const [trigger] = useLazyGetUserByIdQuery();
  const { updateUserSettings } = useUserData();

  const methods = useForm({
    defaultValues: async () => {
      const result = await trigger(appUser.user_id, true);
      const { data } = result;

      const isAllSelected = (dataToCheck: ExternModelOption[]) => {
        return dataToCheck
          .filter((item) => item.is_capable)
          .every((item) => item.enabled);
      };

      const allExternalModelsBE = [
        ...(data?.settings?.external_models?.amazon_bedrock || []),
        ...(data?.settings?.external_models?.openai || []),
        ...(data?.settings?.external_models?.google || []),
      ];

      return Object.fromEntries([
        ...allExternalModelsBE.map((item) => [
          convertToValueWithoutDots(item.model_name),
          item.enabled || false,
        ]),
        [
          'amazon_bedrock',
          isAllSelected(data?.settings?.external_models?.amazon_bedrock || []),
        ],
        [
          'openai',
          isAllSelected(data?.settings?.external_models?.openai || []),
        ],
        [
          'google',
          isAllSelected(data?.settings?.external_models?.google || []),
        ],
      ]);
    },
  });

  const { reset, getValues, setValue } = methods;

  const isClearDisabled = Object.values(getValues()).every((item) => !item);

  const handleExternalModelValueChange = async (
    checked: boolean,
    groupName: keyof ExternalModelsSettings,
    modelName: string,
  ) => {
    const updatedModelList = (
      appUser?.settings?.external_models
        ? (appUser?.settings?.external_models[groupName] as ExternModelOption[])
        : []
    ).map((item) => {
      if (item?.model_name === modelName) {
        return {
          ...item,
          enabled: checked,
        };
      }
      return item;
    });
    const external_models = {
      external_models: {
        ...(appUser?.settings?.external_models || {}),
        [groupName]: updatedModelList,
      },
    };

    const availableFields = updatedModelList.filter((item) => item.is_capable);

    const isAllModelsSelected = availableFields.every((item) => item.enabled);

    const isAllModelsUnselected = availableFields.every(
      (item) => !item.enabled,
    );

    if (isAllModelsSelected || isAllModelsUnselected) {
      setValue(groupName, checked);
    }

    if (!isAllModelsSelected && !checked) {
      setValue(groupName, false);
    }

    try {
      await updateUserSettings(external_models, 'Preferred model saved');
    } catch (e) {
      log.error(e);
    }
  };

  const handleClearAllModels = async () => {
    const makeAllValuesDisabled = (data?: ExternModelOption[]) => {
      return (data || []).map((item) => ({ ...item, enabled: false }));
    };

    const external_models = {
      external_models: {
        ...(appUser?.settings?.external_models || {}),
        amazon_bedrock: makeAllValuesDisabled(
          appUser?.settings?.external_models?.amazon_bedrock,
        ),
        openai: makeAllValuesDisabled(
          appUser?.settings?.external_models?.openai,
        ),
        google: makeAllValuesDisabled(
          appUser?.settings?.external_models?.google,
        ),
      },
    };

    try {
      await updateUserSettings(external_models);
      const allValues = getValues();
      const resetValues = Object.fromEntries(
        Object.keys(allValues).map((key) => [key, false]),
      );
      reset(resetValues);
    } catch (e) {
      log.error(e);
    }
  };

  const handleSelectAllClick = async (
    checked: boolean,
    groupName: keyof ExternalModelsSettings,
  ) => {
    let fieldsToChange = [] as string[];
    const makeAllValuesSelected = (data?: ExternModelOption[]) => {
      return (data || []).map((item) => {
        if (item?.is_capable) {
          fieldsToChange = [...fieldsToChange, item.model_name];
          return { ...item, enabled: checked };
        }
        return item;
      });
    };

    const external_models = {
      external_models: {
        ...(appUser?.settings?.external_models || {}),
        [groupName]: makeAllValuesSelected(
          appUser?.settings?.external_models
            ? (appUser?.settings?.external_models[
                groupName
              ] as ExternModelOption[])
            : [],
        ),
      },
    };

    try {
      await updateUserSettings(external_models);
      fieldsToChange.forEach((item) => {
        setValue(convertToValueWithoutDots(item), checked);
      });
    } catch (e) {
      log.error(e);
    }
  };

  return (
    <div className="nj-section--main-container with-padding">
      {!isProTier && (
        <UpsellMessage description="Get access to 20+ premium Amazon Bedrock, OpenAI & Google Gemini models" />
      )}
      <FormProvider methods={methods}>
        <div className="nj-external-models-form-wrapper">
          <h5 className="nj-section--main-container-title">
            Enable external model(s) for Researcher, Advisor and Coder results
          </h5>
          <h6 className="nj-section--main-container-subtitle">
            Your default external model preference
          </h6>
          <div className="nj-accordion nj-accordion-external-models">
            {Object.entries(externalModelProvidersSet).map(
              ([key, value], index) => {
                const modelsList =
                  (appUser?.settings?.external_models &&
                    (appUser?.settings?.external_models[
                      key as keyof ExternalModelsSettings
                    ] as ExternModelOption[])) ||
                  [];

                const modelLength = modelsList.length;

                const header = (
                  <div className="nj-accordion--label-content-wrapper">
                    <Icon size={SVG_SIZE} type={value.icon} />
                    <span className="nj-accordion--label-content-text">
                      {value.label} ({modelLength})
                    </span>
                  </div>
                );

                const selectedNumber = modelsList.filter(
                  (item) => !!item.enabled,
                ).length;

                const rightSideComponent = (
                  <span className="nj-accordion--label-selected-items">
                    {selectedNumber} selected
                  </span>
                );

                const content = (
                  <div className="nj-accordion-external-models-content-wrapper">
                    <div
                      className={classNames(
                        'nj-accordion-external-models-checkboxes-wrapper',
                        {
                          isAllDisabled:
                            !appUser?.settings?.external_models?.is_capable,
                        },
                      )}
                    >
                      <div className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper">
                        <div className="nj-section--field-title">
                          <span>Select all</span>
                        </div>

                        <FormSwitch
                          name={key}
                          onChangeHandler={(checked: boolean) =>
                            handleSelectAllClick(
                              checked,
                              key as keyof ExternalModelsSettings,
                            )
                          }
                          isDisabled={
                            (modelsList || []).every(
                              (item) => !item.is_capable,
                            ) || !appUser?.settings?.external_models?.is_capable
                          }
                        />
                      </div>
                      {modelsList.map(
                        (
                          { model_name, is_capable }: ExternModelOption,
                          index: number,
                        ) => {
                          const model = getExternalModelData(model_name);
                          if (!model) {
                            return <></>;
                          }

                          const iconType = !model.hide_icon_in_settings
                            ? model.icon_name
                            : null;

                          return (
                            <div
                              key={`${model_name}_${index}`}
                              className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper"
                            >
                              <Checkbox
                                label={
                                  <div className="nj-section--field-title-wrapper">
                                    <p className="nj-section--field-title">
                                      <span>{model.display_name}</span>
                                      {iconType && (
                                        <Icon type={iconType} size={SVG_SIZE} />
                                      )}
                                    </p>
                                    {isProTier && !is_capable && (
                                      <LabelInsufficientCredits />
                                    )}
                                    {isFreeTier && !is_capable && <LabelPro />}
                                    {!isOpenTier &&
                                      is_capable &&
                                      newExternalModelsList.includes(
                                        model_name,
                                      ) && <LabelNew />}
                                  </div>
                                }
                                name={convertToValueWithoutDots(model_name)}
                                onChangeHandler={(checked: boolean) => {
                                  handleExternalModelValueChange(
                                    checked,
                                    key as keyof ExternalModelsSettings,
                                    model_name,
                                  );
                                }}
                                disabled={
                                  !is_capable ||
                                  !appUser?.settings?.external_models
                                    ?.is_capable
                                }
                              />
                            </div>
                          );
                        },
                      )}
                    </div>
                    <a
                      href={value.url}
                      target="_blank"
                      rel="noreferrer"
                      className="nj-accordion-external-models-learn-more"
                    >
                      <>
                        <span>Learn more</span>
                        <ArrowSquareOut size={SVG_SIZE} />
                      </>
                    </a>
                  </div>
                );

                return (
                  <CollapsedItem
                    key={key}
                    header={header}
                    title={value.label}
                    content={content}
                    isExpanded={false}
                    rightSideComponent={rightSideComponent}
                  />
                );
              },
            )}
          </div>
          <button
            className="nj-external-models-clear-button"
            type="button"
            disabled={isClearDisabled}
            onClick={handleClearAllModels}
          >
            Clear all
          </button>
        </div>
      </FormProvider>
    </div>
  );
};
