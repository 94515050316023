import { Balloon } from '@phosphor-icons/react';

const SVG_SIZE = 48;

export const EmptyBlock = () => {
  return (
    <div className="nj-section--notification-empty-block">
      <Balloon size={SVG_SIZE} color="currentColor" />
      <p>Nothing requires your attention</p>
    </div>
  );
};
