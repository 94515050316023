import { useSession } from 'src/hooks';
import {
  useGetUserBudgetQuotaInfoQuery,
  useGetUserSubscriptionInfoQuery,
  // useGetUserTaskQuotaInfoQuery,
} from 'src/store/services';
import dayjs from 'dayjs';
import { UserTier } from 'src/types/models/UserTier';
// import { UserTierStatus } from 'src/types/models/UserTierStatus';
import styles from './CreditsInfoSection.module.scss';

export const CreditsInfoSection = () => {
  const { appUser } = useSession();
  const { data, isLoading } = useGetUserSubscriptionInfoQuery(
    {
      user_id: appUser.user_id,
    },
    { skip: appUser.tier_id !== UserTier.PAID },
  );
  const { data: quotaData, isLoading: isQuotaLoading } =
    useGetUserBudgetQuotaInfoQuery({
      user_id: appUser.user_id,
    });
  // const { data: tasksQuotaData, isLoading: isTaskQuotaLoading } =
  //   useGetUserTaskQuotaInfoQuery({
  //     user_id: appUser.user_id,
  //   });

  if (isLoading || isQuotaLoading) {
    return null;
  }

  const creditInfo = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(
    quotaData?.amount ||
      // || appUser?.settings?.money_quota?.value // todo needs to check with BE
      0,
  );

  return (
    <>
      <div className={styles.root}>
        <div>
          <h4 className={styles.title}>Available credits</h4>
          <span className={styles.description}>
            Resets on{' '}
            {dayjs(data?.curr_period_end_timestamp).format('MMM D, YYYY')}
          </span>
        </div>
        <span className={styles.value}>{creditInfo}</span>
      </div>
      {/*{!isTaskQuotaLoading &&*/}
      {/*  appUser.tier_status === UserTierStatus.QUOTA_EXCEEDED && (*/}
      {/*    <div className="nj-wallet-section-credits-info-section--credits">*/}
      {/*      <div className="nj-wallet-section-credits-info-section--credits-label">*/}
      {/*        <h4 className="nj-wallet-section-credits-info-section--credits-title">*/}
      {/*          Available tasks*/}
      {/*        </h4>*/}
      {/*        <span className="nj-wallet-section-credits-info-section--credits-description">*/}
      {/*          Amount varies based on complexity*/}
      {/*        </span>*/}
      {/*      </div>*/}
      {/*      <span className="nj-wallet-section-credits-info-section--credits-value">*/}
      {/*        {tasksQuotaData && tasksQuotaData?.count !== null*/}
      {/*          ? tasksQuotaData?.count*/}
      {/*          : appUser?.settings?.task_quota?.value}*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*  )}*/}
    </>
  );
};
