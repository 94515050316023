import classNames from 'classnames';
import { sanitize } from 'dompurify';
import { Choice } from 'src/types';
import { CheckboxChoiceButton } from '../CheckboxChoiceButton';
import { RadioChoiceButton } from '../RadioChoiceButton';
import { Markdown } from 'src/components/Markdown';

type ChoiceOptionProps = {
  option: Choice;
  multiSelect?: boolean;
  selectKey?: string;

  onClick?: ({
    select_key,
    select_value,
    selected,
    multi_select,
  }: {
    select_key: string;
    select_value: string;
    selected: boolean;
    multi_select: boolean;
  }) => void;
};

export const ChoiceOption = ({
  option,
  multiSelect = false,
  selectKey = '',
  onClick,
}: ChoiceOptionProps) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (onClick) {
      const data = {
        select_key: selectKey,
        select_value: option.select_value || '',
        selected: !option.selected,
        multi_select: multiSelect,
      };
      onClick(data);
    }
  };

  return (
    <label
      data-e2e="choice-card--suboption"
      className={classNames('nj-options-card--row', 'sub-option', {
        selected: option.selected,
      })}
      onClick={handleClick}
    >
      <span className="nj-options-card--date">
        <Markdown>{sanitize(option.title || '')}</Markdown>
      </span>
      <span className="nj-options-card--meeting">
        <Markdown>{sanitize(option.description || '')}</Markdown>
      </span>
      <span className="nj-options-card--icon">
        {multiSelect ? (
          <CheckboxChoiceButton selected={option.selected} />
        ) : (
          <RadioChoiceButton selected={option.selected} />
        )}
      </span>
    </label>
  );
};
