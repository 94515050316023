import { useContext, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { X } from '@phosphor-icons/react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { Modal } from 'react-responsive-modal';
import { RenameForm } from './components/RenameForm';
import { useThreads, useSession } from 'src/hooks';
import {
  ApiTask,
  RenameType,
  RenameTypeProps,
  UpdateConversationHashStateRequest,
} from 'src/types';
import {
  fetchUpdateConversationById,
  fetchUpdateTaskById,
  useAppDispatch,
} from 'src/store';

const SVG_SIZE = 20;

type RenameModalProps = {
  isOpen: boolean;
  onClose: (val: boolean) => void;
};

export const RenameModal = ({ isOpen, onClose }: RenameModalProps) => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { currentTask, currentThread } = useThreads();
  const { appUser } = useSession();

  const dispatch = useAppDispatch();

  const renameProps = useMemo(() => {
    if (currentTask) {
      return {
        itemId: currentTask.task_id,
        itemName: currentTask.task_hash?.replace('#', ''),
        itemType: RenameType.TASK,
      } as RenameTypeProps;
    } else if (currentThread) {
      return {
        itemId: currentThread.conversation_id,
        itemName: currentThread.conversation_hash?.replace('#', ''),
        itemType: RenameType.CONVERSATION,
      } as RenameTypeProps;
    } else {
      return {
        itemId: 'default',
        itemName: 'new-chat',
        itemType: RenameType.CONVERSATION,
      } as RenameTypeProps;
    }
  }, [currentTask, currentThread]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose(false);
    }
  }, [onClose]);

  // To rename a task, a user clicks to choose a list item,
  // for that reason, we rely on current task or current thread.
  const handleRenameSubmit = ({
    itemId,
    itemName,
    itemType,
  }: RenameTypeProps) => {
    if (currentTask) {
      const { selected, ...taskDataToUpdate } = currentTask;
      dispatch(
        fetchUpdateTaskById({
          userId: appUser.user_id,
          task: {
            ...taskDataToUpdate,
            task_id: itemId,
            task_hash: `#${itemName}`,
          } as ApiTask,
        }),
      );
      toast('Task renamed');
    } else if (currentThread) {
      dispatch(
        fetchUpdateConversationById({
          user_id: appUser.user_id,
          conversation_id: itemId,
          conversation_hash: `#${itemName}`,
        } as UpdateConversationHashStateRequest),
      );
      toast('Chat renamed');
    }

    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--rename-form',
        closeIcon: 'nj-video-avatar-modal-closeButton',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <RenameForm {...renameProps} onRenameSubmit={handleRenameSubmit} />
    </Modal>
  );
};
