import { useSession } from 'src/hooks/useSession';
import { nondebugFeedbackApi, useAppDispatch } from 'src/store';
import { FeedbackType } from 'src/types';
import { updateMessageFeedback } from 'src/store/slices/conversationSlice';
import { updateConversationChatMessageFeedback } from 'src/store/slices/userChatsSlice';
import { toast } from 'react-toastify';
import log from 'src/utils/logger';
import { Feedback } from 'src/types/models/Feedback';

type FeedbackProps = {
  conversationId: string;
  messageId: string;
  taskId?: string;
  isShowNegativeFeedbackForm: boolean;
  onToggleNegativeFeedbackForm?: () => void;
  feedback?: Feedback;
};

export const useFeedback = ({
  conversationId,
  messageId,
  taskId,
  isShowNegativeFeedbackForm,
  onToggleNegativeFeedbackForm,
  feedback,
}: FeedbackProps) => {
  const { appUser } = useSession();
  const dispatch = useAppDispatch();

  const [createFeedback] = nondebugFeedbackApi.useCreateFeedbackMutation();
  const [updateFeedback] = nondebugFeedbackApi.useUpdateFeedbackMutation();
  const [deleteFeedback] = nondebugFeedbackApi.useDeleteFeedbackMutation();

  const handleThumbsClick = async (feedbackType: FeedbackType) => {
    if (
      feedbackType === FeedbackType.POSITIVE &&
      isShowNegativeFeedbackForm &&
      !!onToggleNegativeFeedbackForm
    ) {
      onToggleNegativeFeedbackForm();
    }

    try {
      let feedbackId = feedback?.feedback_id || '';
      const previousFeedbackType = feedback?.feedback_type;
      const isTheSameFeedback = previousFeedbackType === feedbackType;
      const requestTaskId = !!taskId && taskId.length > 0 ? taskId : undefined;

      if (!isTheSameFeedback) {
        if (feedbackId.length > 0) {
          await updateFeedback({
            feedback_id: feedbackId,
            user_id: appUser.user_id,
            conversation_id: conversationId,
            message_id: messageId,
            feedback_type: feedbackType,
            task_id: requestTaskId,
          }).unwrap();
        } else {
          const feedbackResponse = await createFeedback({
            user_id: appUser.user_id,
            conversation_id: conversationId,
            message_id: messageId,
            feedback_type: feedbackType,
            task_id: requestTaskId,
          }).unwrap();

          feedbackId = feedbackResponse?.feedback_id || '';
        }

        if (taskId === 'default') {
          dispatch(
            updateMessageFeedback({
              taskId: taskId,
              message_id: messageId,
              feedback: {
                feedback_id: feedbackId,
                feedback_type: feedbackType,
              },
            }),
          );
        } else {
          dispatch(
            updateConversationChatMessageFeedback({
              taskId: taskId,
              conversation_id: conversationId,
              message_id: messageId,
              feedback: {
                feedback_id: feedbackId,
                feedback_type: feedbackType,
              },
            }),
          );
        }

        toast('Feedback submitted');
      } else {
        await deleteFeedback({
          feedback_id: feedbackId,
          user_id: appUser.user_id,
          conversation_id: conversationId,
          message_id: messageId,
          task_id: requestTaskId,
        }).unwrap();

        if (taskId === 'default') {
          dispatch(
            updateMessageFeedback({
              taskId,
              message_id: messageId,
              feedback: undefined,
            }),
          );
        } else {
          dispatch(
            updateConversationChatMessageFeedback({
              taskId: taskId,
              conversation_id: conversationId,
              message_id: messageId,
              feedback: undefined,
            }),
          );
        }

        toast('Feedback canceled');
      }

      if (
        feedbackType === FeedbackType.NEGATIVE &&
        !!onToggleNegativeFeedbackForm &&
        ((isTheSameFeedback && isShowNegativeFeedbackForm) ||
          (!isTheSameFeedback && !isShowNegativeFeedbackForm))
      ) {
        onToggleNegativeFeedbackForm();
      }
    } catch (error) {
      log.error(error);
    }
  };

  return {
    onThumbsClick: handleThumbsClick,
  };
};
