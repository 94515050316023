import classNames from 'classnames';
import { ChatMode } from 'src/types';
import { useSession } from 'src/hooks';
import { UnreadCountButton } from 'src/components/UnreadCountButton';
import { ThreadView } from 'src/components/FlatAppearance/components/ThreadView';

interface MultiConversationsPanelProps {
  startSpeechRecognizing: () => void;
  stopSpeechRecognizing: () => void;
  unMuteMicrophone: () => void;
}

/**
 * Composes conversation panel with header, footer and AI scenario.
 */
export const MultiConversationsPanel = ({
  startSpeechRecognizing,
  stopSpeechRecognizing,
  unMuteMicrophone,
}: MultiConversationsPanelProps) => {
  const { chatMode } = useSession();

  const classes = classNames('nj-section', 'nj-section--content', {
    'has-messages': chatMode === ChatMode.CHAT || chatMode === ChatMode.TASK,
  });

  return (
    <div className={classes}>
      <div className="container">
        {chatMode === ChatMode.CHAT && <UnreadCountButton />}

        <ThreadView
          startSpeechRecognizing={startSpeechRecognizing}
          stopSpeechRecognizing={stopSpeechRecognizing}
          unMuteMicrophone={unMuteMicrophone}
        />
      </div>
    </div>
  );
};
