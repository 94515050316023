import { ACTIVITY_COLUMNS } from 'src/constants';
import './ActivityTable.scss';
import { useSession } from 'src/hooks';
import { useGetStripeCostsQuery } from 'src/store/services';

export const ActivityTable = () => {
  const { appUser } = useSession();
  const { data, isLoading } = useGetStripeCostsQuery({
    user_id: appUser.user_id,
  });

  const getColumnClass = (colIndex: number) => {
    return colIndex === 1 || colIndex === 2 ? 'light-color' : '';
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="nj-activity-table">
      <table>
        <thead>
          <tr>
            {ACTIVITY_COLUMNS.map((col) => (
              <th key={col.accessor}>{col.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(data?.charged_tasks || []).map((activity, rowIndex) => (
            <tr key={rowIndex}>
              {ACTIVITY_COLUMNS.map((col, colIndex) => (
                <td
                  key={`${col.accessor}-${rowIndex}`}
                  className={getColumnClass(colIndex)}
                >
                  {col.format
                    ? col.format(activity[col.accessor])
                    : activity[col.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
