import { useBreakpoint } from 'src/hooks';
import styles from './ManageAccountContentPage.module.scss';

interface ManageAccountContentPageProps {
  title: string;
  children: JSX.Element;
}

export const ManageAccountContentPage = ({
  title,
  children,
}: ManageAccountContentPageProps) => {
  const { isDesktopAndUp } = useBreakpoint();

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{title}</h3>

      {isDesktopAndUp && <hr className="divider" />}

      <div className={styles.container}>{children}</div>
    </div>
  );
};
