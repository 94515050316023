import {
  DEFAULT_NINJATECH_BLOG_LINK,
  DEFAULT_NINJATECH_LINK,
  DEFAULT_NINJATECH_PRIVACY_POLICY_LINK,
  DEFAULT_NINJATECH_TERMS_LINK,
} from 'src/constants/externalLinks';
import { DotOutline } from '@phosphor-icons/react';

export const linksList = [
  {
    label: 'Company',
    href: DEFAULT_NINJATECH_LINK,
  },
  {
    label: 'Blog',
    href: DEFAULT_NINJATECH_BLOG_LINK,
  },
  {
    label: 'Terms',
    href: DEFAULT_NINJATECH_TERMS_LINK,
  },
  {
    label: 'Privacy policy',
    href: DEFAULT_NINJATECH_PRIVACY_POLICY_LINK,
  },
];

export const CompanyLinksSet = () => {
  return (
    <div className="nj-auth-footer-links-list">
      {linksList.map((item, index) => (
        <div key={item.label} className="nj-auth-footer-link">
          <a href={item.href} target="_blank" rel="noreferrer">
            {item.label}
          </a>
          {index !== linksList.length - 1 && (
            <DotOutline size={16} weight="fill" />
          )}
        </div>
      ))}
    </div>
  );
};
