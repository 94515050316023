import { useMemo } from 'react';
import cn from 'classnames';
import { useAvatarPreferences } from 'src/hooks';
import styles from './AvatarImage.module.scss';
import { Persona } from 'src/types/models/Persona';

interface AvatarImageProps {
  size?: number;
  className?: string;
  persona?: Persona;
}

export const AvatarImage = ({
  size = 40,
  className,
  persona,
}: AvatarImageProps) => {
  const { avatarsListData, avatarImageURL: globalAvatarImageURL } =
    useAvatarPreferences();

  const avatarImageURL = useMemo(
    () =>
      persona
        ? avatarsListData?.find((item) => item.id === persona.avatar_id)?.url ||
          ''
        : '',
    [persona, avatarsListData],
  );

  return (
    <img
      className={cn(styles.root, className)}
      src={avatarImageURL || globalAvatarImageURL}
      width={size}
      height={size}
      alt="Ninja"
    />
  );
};
