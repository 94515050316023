import { useContext } from 'react';
import { TaskSkill } from 'src/types';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { SkillLabel } from 'src/components/SkillLabel';
import { useVisible, useBreakpoint, useSubmitUserInput } from 'src/hooks';
import { UpsellVideoModal } from 'src/components/UpsellVideoModal';
import { LearnMoreUpsellLabel } from 'src/components/LearnMoreUpsellLabel';
import { useGetVideoLinksJSONQuery } from 'src/store/services';
import { SkillLabelIcon } from 'src/components/SkillLabelIcon';

export type Example = {
  displayMessage: string;
  inputText: string;
  agent?: string;
};

type PillarsBlendTileProps = {
  blocked?: boolean;
  skill: TaskSkill | string;
  template?: Example;
  onClick: () => void;
};

export const PillarsBlendTile = ({
  blocked = false,
  skill,
  template,
  onClick,
}: PillarsBlendTileProps) => {
  const { setThreadInputBoxValue, onCheckInputTagReplacement } = useContext(
    ThreadInputBoxContext,
  );
  const {
    isVisible: isShowUpsellModal,
    handleVisibilitySet: openUpsellModal,
    handleVisibilityRemove: closeUpsellModal,
  } = useVisible();
  const { data: videoLinks } = useGetVideoLinksJSONQuery();

  const { isMobileOrTablet } = useBreakpoint();
  const { onSubmitUserInput } = useSubmitUserInput();

  const handleTileClick = () => {
    if (!template) {
      return;
    }

    setThreadInputBoxValue(template?.inputText);

    const shouldShowReplaceTooltip = onCheckInputTagReplacement(
      template?.inputText,
    );

    if (!shouldShowReplaceTooltip) {
      onSubmitUserInput(template?.inputText);
    }

    onClick();
  };

  return (
    <>
      <div className="nj-random-pillars--blend-tile" onClick={handleTileClick}>
        {isMobileOrTablet ? (
          <SkillLabelIcon skill={skill} />
        ) : (
          <SkillLabel skill={skill} />
        )}
        <span className="nj-random-pillars--blend-tile--content">
          {template?.displayMessage}
        </span>
        {blocked && <LearnMoreUpsellLabel onClick={openUpsellModal} />}
      </div>
      {blocked && (
        <UpsellVideoModal
          videoUrl={videoLinks?.scheduler || ''} // todo - for now only scheduler could be disabled
          title="Scheduler AI Agent"
          description="Sign up to have Ninja coordinate your meeting times seamlessly."
          isOpen={isShowUpsellModal}
          onClose={closeUpsellModal}
        />
      )}
    </>
  );
};
