import { useFormContext, UseFieldArrayRemove } from 'react-hook-form';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import {
  TrashSimple,
  ImageSquare,
  ChatCircleDots,
  DotsSixVertical,
} from '@phosphor-icons/react';
import { Button } from 'src/components/Button';
import type { MovieStudioFormData } from 'src/types';
import { IItemProps } from 'react-movable';

interface VideoScriptDragItemProps {
  id: string;
  index: number;
  remove: UseFieldArrayRemove;
  isDisabledRemove?: boolean;
  itemProps: IItemProps;
}

const SVG_SIZE = 20;

export const VideoScriptDragItem = ({
  id,
  index,
  remove,
  isDisabledRemove,
  itemProps,
}: VideoScriptDragItemProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<MovieStudioFormData>();

  return (
    <li
      key={id}
      {...itemProps}
      className="nj-avatar-recording--video-script-item"
    >
      <div className="nj-avatar-recording--video-script-drag-button">
        <DotsSixVertical size={SVG_SIZE} />
      </div>

      <div className="nj-avatar-recording--video-script-inputs">
        <div className="nj-avatar-recording--video-script-input-wrapper">
          <TextareaAutosize
            minRows={3}
            cacheMeasurements
            placeholder="Add dialogue"
            className={classNames(
              'nj-avatar-recording--video-script-textarea',
              {
                isRequired: errors.script && errors.script[index]?.text,
              },
            )}
            {...register(`script.${index}.text` as const, {
              required: true,
            })}
          />

          <ChatCircleDots
            size={SVG_SIZE}
            className="nj-avatar-recording--video-script-input-icon"
          />

          {errors.script && errors.script[index]?.text && (
            <span className="nj-avatar-recording--video-script-error-label">
              Required
            </span>
          )}
        </div>

        <div className="nj-avatar-recording--input-wrapper">
          <input
            className="nj-avatar-recording--video-script-upload"
            placeholder="Image URL (Optional)"
            {...register(`script.${index}.imageURL` as const)}
          />

          <ImageSquare
            size={SVG_SIZE}
            className="nj-avatar-recording--video-script-input-icon"
          />
        </div>
      </div>

      <Button
        onClick={() => remove(index)}
        type="button"
        className="nj-avatar-recording--video-script-action-button"
        disabled={isDisabledRemove}
      >
        <TrashSimple size={SVG_SIZE} />
      </Button>
    </li>
  );
};
