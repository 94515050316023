import { useGoogleResourcesAccess, useSession } from 'src/hooks';
import { GoogleAccess } from './components/GoogleAccess';
import { ArchiveAll } from './components/ArchiveAll';
// import { ClearPersonalization } from './components/ClearPersonalization';
// import { ExportData } from './components/ExportData';
import { DisconnectAccess } from './components/DisconnectAccess';
import { Icon } from 'src/components/Icon';
import { useCanConnectToGoogle } from 'src/hooks/useCanConnectToGoogle';

const SVG_SIZE = 32;

export const AccessAndDataSettings = () => {
  const { appUser } = useSession();
  const { canAccessCalendar, canAccessContacts, canAccessDirectory } =
    useGoogleResourcesAccess();
  const { email } = appUser;

  const canConnectToGoogle = useCanConnectToGoogle();

  const isConnected =
    canAccessCalendar && canAccessContacts && canAccessDirectory;

  return (
    <div className="nj-settings-tab-content">
      <div className="nj-settings-tab-content--form-field inline-field">
        <p className="nj-settings-tab-content--access-label">
          <span className="nj-settings-tab-content--access-title email">
            <span>{email}</span>
          </span>
          <span className="nj-settings-tab-content--access-label-subtitle">
            {isConnected ? 'Connected' : 'Not connected'}
          </span>
        </p>

        <Icon type="googleWhite" size={SVG_SIZE} />
      </div>
      <hr className="divider dashed no-margin" />

      <GoogleAccess />
      <hr className="divider" />

      <p className="nj-settings-tab-content--access-title">Data Control</p>
      <ArchiveAll />
      {/*<ClearPersonalization />*/}
      {/*<ExportData />*/}
      {canConnectToGoogle && <DisconnectAccess />}

      <hr className="divider" />
    </div>
  );
};
