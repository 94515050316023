import { configureStore, AnyAction, Reducer } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  persistCombineReducers,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'reduxjs-toolkit-persist';
import createWebStorage from 'reduxjs-toolkit-persist/lib/storage/createWebStorage';
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2';
import logoutReducer, { resetStore } from './slices/logoutSlice';
// attn: will deprecate
import conversationReducer, {
  addConversation,
  updateConversationId,
  addConversationMessage,
  createConversationMessage,
  replaceConversationMessage,
  appendConversationMessage,
  setDisableConversationSubmit,
  setMessageToStream,
  addConversationMessageChunk,
  addConversationMessageFooter,
  updateMessageFeedbackType,
  conversationState,
} from './slices/conversationSlice';
// attn: will deprecate
import userTasksReducer, {
  addNewTask,
  userTasksState,
  updateTaskMessage,
  updateTaskStateByTaskId,
  removeAllMessagesFromTaskByTaskId,
  updateFeedbackType,
  addMessageToTaskByConversationId,
  addMessageToTaskByTaskId,
  createMessageInTaskByTaskId,
  replaceMessageInTaskByTaskId,
  appendMessageInTaskByTaskId,
  markMessagesIsRead,
  updateTaskMessageFeedbackType,
} from './slices/userTasksSlice';
import userChatsReducer, {
  addConversationChat,
  addTaskChat,
  addTaskToConversationChat,
  addMinimumTaskToConversationChat,
  addTaskChatMessageChunk,
  addConversationChatMessageChunk,
  addConversationChatMessageFooter,
  addMessageToConversationChat,
  addMessageToTaskChat,
  appendConversationChatMessage,
  archiveConversationTask,
  updateConversationChatMessage,
  appendTaskChatMessage,
  clearConversationChatMessages,
  createConversationChatMessage,
  createTaskChatMessage,
  replaceTaskChatTemporaryMessageId,
  replaceConversationChatTemporaryMessageId,
  markTaskChatMessagesAsRead,
  removeAllMessagesFromTaskChat,
  replaceConversationChatMessage,
  replaceTaskChatMessage,
  replaceDefaultChat,
  updateTaskChatMessage,
  updateTaskChatState,
  updateConversationsState,
  updateConversationChatMessageFeedbackType,
  updateTaskChatMessageFeedbackType,
  updateTaskChatFeedbackType,
  updateUserChatsState,
  setAvatarSpeech,
  setThreadStatus,
  setShouldAnimate,
  userChatsState,
  userChatsStateThreadsStatusRegistry,
  addConversationChatMessageContentChunk,
  addTaskChatMessageContentChunk,
} from './slices/userChatsSlice';
import type {
  UserChatsState,
  ThreadsStatusRegistry,
} from './slices/userChatsSlice';
import {
  fetchUserById,
  fetchNextPageTasks,
  fetchTaskById,
  fetchNextTopConversations,
  fetchConversationById,
  fetchUpdateTaskById,
  fetchUpdateConversationById,
  fetchArchivedAll,
  fetchFilteredConversationTasks,
} from './thunks';
import sessionReducer, {
  resetSession,
  sessionState,
  setSelectedTaskDetail,
  setNotification,
  clearNotification,
  setAppUser,
  setIsDarkTheme,
  setSocketConnected,
  setOnboardingHintStep,
  setCurrentConversationId,
  setCurrentTaskId,
  showAccessRequestOnSchedulerTask,
  setIsAccessModalShown,
  setFirstUserQuery,
} from './slices/sessionSlice';
import settingsReducer, {
  settingsState,
  resetSettings,
  setActiveSettingsTab,
} from './slices/settingsSlice';
import feedbackReducer, {
  setFeedbackData,
  resetFeedbackData,
  feedbackState,
} from './slices/feedbackSlice';
import avatarReducer, {
  setAvatarQueue,
  clearAvatarQueue,
  setChatMode,
  avatarState,
} from './slices/avatarSlice';
import interfaceControlReducer, {
  setChatModeSidePanel,
  setProfileSettingsBarExpanded,
  setIsSettingsPanelExpanded,
  setIsTasksListExpanded,
  setChatsPageSize,
  interfaceControlState,
} from './slices/interfaceControlSlice';
import {
  tasksApi,
  feedbackApi,
  usersApi,
  guestUsersApi,
  contactsApi,
  conversationApi,
  convEngineApi,
  savingsApi,
  nondebugFeedbackApi,
  tokenApi,
  jsonDataApi,
  offlineRecordingApi,
  metahumanApi,
  sharingApi,
  socketApi,
  zendeskApi,
  matchmakerApi,
  publicAuthApi,
  msftApi,
  walletApi,
  contentApi,
} from './services/index';

const createNoopStorage = () => {
  return {
    getItem(_key: string) {
      return Promise.resolve(null);
    },
    setItem(_key: string, value: string) {
      return Promise.resolve(value);
    },
    removeItem(_key: string) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();
const persistConfig = {
  key: 'ninjaRoot',
  storage,
  timeout: 2000, //Set the timeout function to 2 seconds for the splash screen
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'avatar',
    'interfaceControl',
    `${tasksApi.reducerPath}`,
    `${feedbackApi.reducerPath}`,
    `${usersApi.reducerPath}`,
    `${guestUsersApi.reducerPath}`,
    `${contactsApi.reducerPath}`,
    `${conversationApi.reducerPath}`,
    `${convEngineApi.reducerPath}`,
    `${savingsApi.reducerPath}`,
    `${nondebugFeedbackApi.reducerPath}`,
    `${tokenApi.reducerPath}`,
    `${jsonDataApi.reducerPath}`,
    `${offlineRecordingApi.reducerPath}`,
    `${metahumanApi.reducerPath}`,
    `${sharingApi.reducerPath}`,
    `${socketApi.reducerPath}`,
    `${matchmakerApi.reducerPath}`,
    `${publicAuthApi.reducerPath}`,
    `${msftApi.reducerPath}`,
    `${walletApi.reducerPath}`,
  ],
};

const persistedReducer = persistCombineReducers(persistConfig, {
  logout: logoutReducer,
  session: sessionReducer,
  settings: settingsReducer,
  feedback: feedbackReducer,
  avatar: avatarReducer,
  interfaceControl: interfaceControlReducer,
  conversation: conversationReducer, // attn: will deprecate
  userTasks: userTasksReducer, // attn: will deprecate
  userChats: userChatsReducer, // att: will replace the above
  [tasksApi.reducerPath]: tasksApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [guestUsersApi.reducerPath]: guestUsersApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
  [conversationApi.reducerPath]: conversationApi.reducer,
  [convEngineApi.reducerPath]: convEngineApi.reducer,
  [savingsApi.reducerPath]: savingsApi.reducer,
  [nondebugFeedbackApi.reducerPath]: nondebugFeedbackApi.reducer,
  [tokenApi.reducerPath]: tokenApi.reducer,
  [jsonDataApi.reducerPath]: jsonDataApi.reducer,
  [offlineRecordingApi.reducerPath]: offlineRecordingApi.reducer,
  [metahumanApi.reducerPath]: metahumanApi.reducer,
  [sharingApi.reducerPath]: sharingApi.reducer,
  [socketApi.reducerPath]: socketApi.reducer,
  [zendeskApi.reducerPath]: zendeskApi.reducer,
  [matchmakerApi.reducerPath]: matchmakerApi.reducer,
  [publicAuthApi.reducerPath]: publicAuthApi.reducer,
  [msftApi.reducerPath]: msftApi.reducer,
  [walletApi.reducerPath]: walletApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
});

// action.type === 'messages/clearThreadMessages'
const rootReducer: Reducer = (
  state: ReturnType<typeof persistedReducer>,
  action: AnyAction,
) => {
  if (action.type === 'logout/resetStore') {
    storage.removeItem('persist:ninjaRoot');
    return persistedReducer({} as unknown as RootState, action);
  }
  return persistedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //serializableCheck: {
      //  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //},
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      tasksApi.middleware,
      feedbackApi.middleware,
      usersApi.middleware,
      guestUsersApi.middleware,
      contactsApi.middleware,
      conversationApi.middleware,
      convEngineApi.middleware,
      savingsApi.middleware,
      nondebugFeedbackApi.middleware,
      tokenApi.middleware,
      jsonDataApi.middleware,
      offlineRecordingApi.middleware,
      metahumanApi.middleware,
      sharingApi.middleware,
      socketApi.middleware,
      zendeskApi.middleware,
      matchmakerApi.middleware,
      publicAuthApi.middleware,
      msftApi.middleware,
      walletApi.middleware,
      contentApi.middleware,
    ),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        tasksApi.middleware,
        feedbackApi.middleware,
        usersApi.middleware,
        guestUsersApi.middleware,
        contactsApi.middleware,
        conversationApi.middleware,
        convEngineApi.middleware,
        savingsApi.middleware,
        nondebugFeedbackApi.middleware,
        tokenApi.middleware,
        jsonDataApi.middleware,
        offlineRecordingApi.middleware,
        metahumanApi.middleware,
        sharingApi.middleware,
        socketApi.middleware,
        zendeskApi.middleware,
        matchmakerApi.middleware,
        publicAuthApi.middleware,
        msftApi.middleware,
        walletApi.middleware,
        contentApi.middleware,
      ),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export {
  fetchUserById,
  fetchNextPageTasks,
  fetchTaskById,
  fetchNextTopConversations,
  fetchConversationById,
  fetchUpdateTaskById,
  fetchUpdateConversationById,
  fetchArchivedAll,
  fetchFilteredConversationTasks,
};
export { resetStore };
export {
  resetSession,
  setSelectedTaskDetail,
  setNotification,
  clearNotification,
  setAppUser,
  setIsDarkTheme,
  setSocketConnected,
  setOnboardingHintStep,
  setCurrentConversationId,
  setCurrentTaskId,
  showAccessRequestOnSchedulerTask,
  setIsAccessModalShown,
  setFirstUserQuery,
  sessionState,
};
export { setChatMode, setAvatarQueue, clearAvatarQueue, avatarState };
export { resetSettings, setActiveSettingsTab, settingsState };
export { setFeedbackData, resetFeedbackData, feedbackState };
// attn: will deprecate
export {
  addConversation,
  updateConversationId,
  addConversationMessage,
  createConversationMessage,
  replaceConversationMessage,
  appendConversationMessage,
  setDisableConversationSubmit,
  setMessageToStream,
  addConversationMessageChunk,
  addConversationMessageFooter,
  updateMessageFeedbackType,
  conversationState,
};
// attn: will deprecate
export {
  addNewTask,
  updateTaskMessage,
  updateTaskStateByTaskId,
  removeAllMessagesFromTaskByTaskId,
  updateFeedbackType,
  addMessageToTaskByConversationId,
  addMessageToTaskByTaskId,
  createMessageInTaskByTaskId,
  replaceMessageInTaskByTaskId,
  appendMessageInTaskByTaskId,
  markMessagesIsRead,
  updateTaskMessageFeedbackType,
  userTasksState,
};
export {
  addConversationChat,
  addTaskChat,
  addTaskToConversationChat,
  addMinimumTaskToConversationChat,
  addTaskChatMessageChunk,
  addConversationChatMessageChunk,
  addConversationChatMessageFooter,
  addMessageToConversationChat,
  addMessageToTaskChat,
  appendConversationChatMessage,
  archiveConversationTask,
  updateConversationChatMessage,
  appendTaskChatMessage,
  clearConversationChatMessages,
  createConversationChatMessage,
  createTaskChatMessage,
  replaceTaskChatTemporaryMessageId,
  replaceConversationChatTemporaryMessageId,
  markTaskChatMessagesAsRead,
  removeAllMessagesFromTaskChat,
  replaceConversationChatMessage,
  replaceTaskChatMessage,
  replaceDefaultChat,
  updateTaskChatMessage,
  updateTaskChatState,
  updateConversationsState,
  updateConversationChatMessageFeedbackType,
  updateTaskChatMessageFeedbackType,
  updateTaskChatFeedbackType,
  updateUserChatsState,
  setAvatarSpeech,
  setThreadStatus,
  setShouldAnimate,
  addConversationChatMessageContentChunk,
  addTaskChatMessageContentChunk,
  UserChatsState,
  userChatsState,
  userChatsStateThreadsStatusRegistry,
  ThreadsStatusRegistry,
};
export {
  interfaceControlReducer,
  setChatModeSidePanel,
  setProfileSettingsBarExpanded,
  setIsSettingsPanelExpanded,
  setIsTasksListExpanded,
  setChatsPageSize,
  interfaceControlState,
};
export {
  tasksApi,
  feedbackApi,
  usersApi,
  guestUsersApi,
  contactsApi,
  conversationApi,
  convEngineApi,
  savingsApi,
  nondebugFeedbackApi,
  tokenApi,
  jsonDataApi,
  offlineRecordingApi,
  metahumanApi,
  sharingApi,
  socketApi,
  matchmakerApi,
  msftApi,
  walletApi,
  contentApi,
};

export const persistor = persistStore(store);

export default store;
