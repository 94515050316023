import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

type SignupForFreeButtonProps = {
  isUpsell?: boolean;
  onClick?: () => void;
};

export const SignupForFreeButton = ({
  isUpsell,
  onClick,
}: SignupForFreeButtonProps) => {
  const { onToggleVisibleUpgradeModal } = useContext(SessionContext);
  const navigate = useNavigate();

  const handleClick = () => {
    if (isUpsell) {
      onToggleVisibleUpgradeModal(true);
    } else {
      navigate('/sign-up');
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className="nj-signup-for-free-button"
      onClick={handleClick}
    >
      {isUpsell ? 'Try Ninja Pro for 90% off' : 'Sign up'}
    </button>
  );
};
