import { Link } from 'react-router-dom';
import { useBreakpoint } from 'src/hooks';

export const UpsellChatsMessage = () => {
  const { isMobileOrTablet } = useBreakpoint();
  return (
    <div className="nj-upsell-message-chats">
      To save your chat history <Link to="/sign-up">sign up</Link>{' '}
      {!isMobileOrTablet && (
        <>
          or <Link to="/login">login</Link>
        </>
      )}
    </div>
  );
};
