import { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { X } from '@phosphor-icons/react';
import { useBreakpoint, useSession, useTheme } from 'src/hooks';
import { Button } from 'src/components/Button';
import { ChatModeSidePanel } from 'src/types';
import {
  CameraAngleSelector,
  EnvironmentSelector,
  MovieStudioSection,
  NotificationSection,
  ProfileInfo,
  RenameTaskSection,
  SavedContentSection,
  SearchChatSection,
} from '../SideSettingsSections';
import { SidePanelFooter } from '../SidePanelFooter';
import { NinjaAgentsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/NinjaAgentsSection';
import { ExternalModelsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/ExternalModelsSection';
import { ProfileSettingsSectionGroup } from '../SideSettingsSections/ProfileSettingsSectionGroup';
import { ExecutiveAssistanceSettings } from 'src/pages/ManageTasksChatPage/components/SettingsSection/ExecutiveAssistanceSettings';
import { ImageGeneratorsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/ImageGeneratorsSection';

const SVG_SIZE = 24;

export const SideSettingsPanel = forwardRef<HTMLDivElement>((_, ref) => {
  const { chatModeSidePanel, updateChatModeSidePanel } = useSession();

  const {
    toggleSettingsPanelExpanded,
    toggleProfileSettingsBarExpanded,
    isProfileSettingsBarExpanded,
  } = useTheme();

  const { isMobileOrTablet } = useBreakpoint();

  const handleClose = () => {
    toggleSettingsPanelExpanded(false);
    toggleProfileSettingsBarExpanded(false);
    if (chatModeSidePanel === ChatModeSidePanel.SAVED_CONTENT) {
      updateChatModeSidePanel(ChatModeSidePanel.MOVIE_STUDIO);
    }
  };

  const sectionTitle: string = useMemo(() => {
    switch (chatModeSidePanel) {
      case ChatModeSidePanel.NOTIFICATIONS:
        return 'Notification';
      case ChatModeSidePanel.ENVIRONMENT:
        return 'Choose theme';
      case ChatModeSidePanel.CAMERA_ANGLE:
        return 'Camera Angle';
      case ChatModeSidePanel.MOVIE_STUDIO:
        return 'Movie Studio';
      case ChatModeSidePanel.SAVED_CONTENT:
        return 'Saved Content';
      case ChatModeSidePanel.SEARCH_CHAT:
        return 'Search Chat';
      case ChatModeSidePanel.PROFILE_INFO:
        return '';
      case ChatModeSidePanel.AI_AGENT:
        return 'Ninja persona';
      case ChatModeSidePanel.NINJA_AGENTS:
        return 'Ninja agents';
      case ChatModeSidePanel.EXTERNAL_MODELS:
        return 'External models';
      case ChatModeSidePanel.IMAGE_GENERATORS:
        return 'Image generators';
      case ChatModeSidePanel.VOICE_LANGUAGE:
        return 'Voice & Language';
      default:
        return '';
    }
  }, [chatModeSidePanel]);

  const section = useMemo(() => {
    switch (chatModeSidePanel) {
      case ChatModeSidePanel.NOTIFICATIONS:
        return <NotificationSection />;
      case ChatModeSidePanel.ENVIRONMENT:
        return <EnvironmentSelector />;
      case ChatModeSidePanel.CAMERA_ANGLE:
        return <CameraAngleSelector />;
      case ChatModeSidePanel.RENAME_TASK:
        return <RenameTaskSection />;
      case ChatModeSidePanel.MOVIE_STUDIO:
        return <MovieStudioSection />;
      case ChatModeSidePanel.SAVED_CONTENT:
        return <SavedContentSection />;
      case ChatModeSidePanel.SEARCH_CHAT:
        return <SearchChatSection />;
      case ChatModeSidePanel.PROFILE_INFO:
        return <ProfileInfo />;
      case ChatModeSidePanel.NINJA_AGENTS:
        return <NinjaAgentsSection />;
      case ChatModeSidePanel.EXTERNAL_MODELS:
        return <ExternalModelsSection />;
      case ChatModeSidePanel.IMAGE_GENERATORS:
        return <ImageGeneratorsSection />;
      case ChatModeSidePanel.VOICE_LANGUAGE:
        return (
          <ExecutiveAssistanceSettings className="nj-section--voice-section-from-main-settings" />
        );
      default:
        return <></>;
    }
  }, [chatModeSidePanel]);

  return (
    <div
      className={classNames('nj-section--side-settings-panel', {
        'settings-section': isProfileSettingsBarExpanded,
        'saved-content-section':
          chatModeSidePanel === ChatModeSidePanel.SAVED_CONTENT,
        'nj-section--mobile-side-settings-panel': isMobileOrTablet,
      })}
      ref={ref}
    >
      <div className="nj-section--header">
        <h5 className="nj-section--title">{sectionTitle}</h5>
        <Button onClick={handleClose}>
          <X size={SVG_SIZE} />
        </Button>
      </div>

      {section}

      <ProfileSettingsSectionGroup />

      <SidePanelFooter />
    </div>
  );
});
