import { useContext } from 'react';
import { lazy, Suspense } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { AvatarSelectorContent } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const AvatarSelectorTooltip = () => {
  const { isShowAvatarSelect } = useContext(SessionContext);

  return (
    <Suspense>
      <LazyReactTooltip
        anchorSelect="#avatar-select"
        clickable
        noArrow
        openOnClick
        className="nj-avatar-select-tooltip"
        isOpen={isShowAvatarSelect}
        opacity={1}
        place="bottom-start"
        offset={10}
      >
        <AvatarSelectorContent />
      </LazyReactTooltip>
    </Suspense>
  );
};
