import classNames from 'classnames';
import { PlanSectionType } from 'src/types';
import { PLAN_FREE_FEATURES, PLAN_PRO_FEATURES } from 'src/constants';
import { UpgradeForm } from '../UpgradeForm';
import { PackageDeal } from '../PackageDeal';
import './PlanSection.scss';
import { lazy, Suspense } from 'react';
import { useBreakpoint, useSession } from 'src/hooks';
import { Link } from 'react-router-dom';
import { useCollapse } from 'react-collapsed';
import { CollapsedButton } from 'src/components/FlatAppearance/components/CollapsedButton';

const LazyFeatureItem = lazy(() =>
  import('../FeatureItem').then((module) => ({ default: module.FeatureItem })),
);

interface PlanSectionProps {
  type: PlanSectionType;
  onClose: () => void;
}

export const PlanSection = ({ type, onClose }: PlanSectionProps) => {
  const { isOpenTier } = useSession();
  const { isMobileOrTablet } = useBreakpoint();
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse();
  const isPro = type === 'pro';

  const ListOfFeatures = (type: PlanSectionType) =>
    (type === 'pro' ? PLAN_PRO_FEATURES : PLAN_FREE_FEATURES).map(
      ({ title, withBorder }) => (
        <Suspense key={title}>
          <LazyFeatureItem title={title} withBorder={withBorder} type={type} />
        </Suspense>
      ),
    );

  return (
    <div className={classNames('nj-plan-section', { active: isPro })}>
      <h5 className="nj-plan-section--title">
        <span>Ninja{isPro ? ' PRO' : ''}</span>
        {isPro && (
          <span className="nj-plan-section--remark">Billed monthly</span>
        )}
      </h5>

      {isPro ? (
        <UpgradeForm />
      ) : (
        <div className="nj-plan-section--current-plan">
          <p className="nj-plan-section--current-plan-price">
            <span className="nj-plan-section--current-plan-title">$0</span>
            <span className="nj-plan-section--current-plan-subtitle">
              /month
            </span>
          </p>

          <PackageDeal type="free" />

          {isOpenTier ? (
            <Link
              to="/sign-up"
              className="nj-button nj-plan-section--free-button"
              onClick={onClose}
            >
              Sign up for free
            </Link>
          ) : (
            <button
              className="nj-button nj-plan-section--free-button"
              onClick={onClose}
              type="button"
            >
              Continue as Free
            </button>
          )}
        </div>
      )}

      {/*todo need to refactoring this part*/}
      <ul className="nj-plan-section--feature-list">
        {!isPro &&
          (isMobileOrTablet ? (
            <>
              <div
                className="nj-accordion--header"
                {...getToggleProps({
                  onClick: () => {
                    setExpanded((prevExpanded) => !prevExpanded);
                  },
                })}
              >
                <div className="nj-accordion--label">
                  <div className="nj-accordion--label-content">
                    <p className="nj-accordion--title">
                      View whats included in Ninja
                    </p>
                  </div>
                </div>
                <div className="nj-accordion--label-right-side">
                  <CollapsedButton isExpanded={isExpanded} />
                </div>
              </div>
              <div {...getCollapseProps()}>
                <div className="nj-accordion--content-wrapper">
                  {ListOfFeatures('free')}
                </div>
              </div>
            </>
          ) : (
            <>
              <li className="nj-plan-section--feature-list-title">Includes</li>
              {ListOfFeatures('free')}
            </>
          ))}

        {isPro &&
          (isMobileOrTablet ? (
            <>
              <div
                className="nj-accordion--header"
                {...getToggleProps({
                  onClick: () => {
                    setExpanded((prevExpanded) => !prevExpanded);
                  },
                })}
              >
                <div className="nj-accordion--label">
                  <div className="nj-accordion--label-content">
                    <p className="nj-accordion--title">
                      View whats included in Ninja Pro
                    </p>
                  </div>
                </div>
                <div className="nj-accordion--label-right-side">
                  <CollapsedButton isExpanded={isExpanded} />
                </div>
              </div>
              <div {...getCollapseProps()}>
                <div className="nj-accordion--content-wrapper">
                  {ListOfFeatures('pro')}
                </div>
              </div>
            </>
          ) : (
            <>
              <li className="nj-plan-section--feature-list-title">
                Everything in Free, plus
              </li>
              {ListOfFeatures('pro')}
            </>
          ))}
      </ul>
    </div>
  );
};
