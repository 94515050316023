import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useTheme, useBreakpoint, useOutsideClick } from 'src/hooks';
import { SideSettingsPanel } from '../SidePanel/components/SideSettingsPanel';

export const MobileSidePanel = () => {
  const settingsRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useBreakpoint();

  const {
    isProfileSettingsBarExpanded,
    isSettingsPanelExpanded,
    toggleProfileSettingsBarExpanded,
    toggleSettingsPanelExpanded,
  } = useTheme();

  const handleOverlayClick = () => {
    if (isProfileSettingsBarExpanded) {
      toggleProfileSettingsBarExpanded(false);
    }

    if (isSettingsPanelExpanded) {
      toggleSettingsPanelExpanded(false);
    }
  };

  useOutsideClick(settingsRef, handleOverlayClick);

  return (
    <CSSTransition
      in={isSettingsPanelExpanded}
      timeout={450}
      classNames={
        isMobile ? 'nj-animate-fade' : 'nj-animate-horizontal-slow-appearance'
      }
      unmountOnExit
      nodeRef={settingsRef}
    >
      <SideSettingsPanel ref={settingsRef} />
    </CSSTransition>
  );
};
