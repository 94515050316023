import { Button } from 'src/components/Button';
import { IconWeight, MagnifyingGlass, XCircle } from '@phosphor-icons/react';
import { useState } from 'react';
import log from 'src/utils/logger';

const ICON_PROPS = {
  size: 24,
  color: 'currentColor',
  weight: 'regular' as IconWeight,
};

export const SearchChatSection = () => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleClearSearch = () => {
    setSearchValue('');
  };

  return (
    <div className="nj-section--main-container with-padding">
      <div className="nj-section-search-chat">
        <div className="nj-section-search-chat-input-line">
          <Button className="search-button" onClick={() => log.debug()}>
            <MagnifyingGlass {...ICON_PROPS} />
          </Button>
          <input
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {searchValue.length > 0 && (
            <Button className="clear" onClick={handleClearSearch}>
              <XCircle {...ICON_PROPS} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
