import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/FormProvider';
import { TabPanel } from 'react-headless-tabs';
import { useSettings, useSession } from 'src/hooks';
import { SettingsTabs } from 'src/store/slices/settingsSlice';
import { GeneralSettings } from 'src/pages/ManageTasksChatPage/components/SettingsSection/GeneralSettings';
import { ProfileSettings } from 'src/pages/ManageTasksChatPage/components/SettingsSection/ProfileSettings';
import { ExecutiveAssistanceSettings } from 'src/pages/ManageTasksChatPage/components/SettingsSection/ExecutiveAssistanceSettings';
import { AccessAndDataSettings } from 'src/pages/ManageTasksChatPage/components/AccessAndDataSettings';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import {
  DEFAULT_AVATAR_VOICE_ID,
  DEFAULT_DURATION,
  DEFAULT_DURATION_BETWEEN_REMINDER_EMAILS,
  DEFAULT_HOURS_PER_DAY,
  DEFAULT_LANGUAGE,
  DEFAULT_MINIMUM_AGREEMENT_PERCENTAGE,
  DEFAULT_NEGOTIATION_DURATION,
  DEFAULT_RATE,
  DEFAULT_REQUEST_WINDOW_EXPANSION,
  DEFAULT_SAVINGS_DURATION,
} from 'src/constants';
import { convertSecondsToHours, convertSecondsToWeeks } from 'src/utils';

export interface SettingsFormData {
  timezone?: string;
  allow_notifications_by_email?: boolean;
  allow_notifications_by_slack?: boolean;
  user_language?: string;
  assistant_audio_language?: string;
  close_caption_language?: string;
  assistant_background_color?: string; // outside form, hex
  assistance_voice?: string;
  is_automatically_timezone?: boolean;
  default_duration?: string;
  hourly_rate?: string;
  hours_per_day?: string;
  department_of_team?: string;
  job_title?: string;
  address_street?: string;
  address_apartment?: string;
  address_city?: string;
  address_state?: string;
  savings_duration?: string;
  negotiation_duration?: string | number;
  negotiation_duration_between_reminder_emails?: string | number;
  request_window_expansion?: string | number;
  minimum_agreement_percentage?: string | number;
}

export const SettingsSection = () => {
  const {
    appUser: { user_id },
  } = useSession();

  const [trigger] = useLazyGetUserByIdQuery();

  // TODO refactoring of "SettingsFormData"
  const methods = useForm<SettingsFormData>({
    defaultValues: async () => {
      const result = await trigger(user_id, true);
      const { data } = result;
      return {
        assistance_voice:
          data?.settings?.video_language_setting?.voice_id ||
          DEFAULT_AVATAR_VOICE_ID,
        assistant_audio_language:
          data?.settings?.video_language_setting?.avatar_audio_locale ||
          DEFAULT_LANGUAGE,
        close_caption_language:
          data?.settings?.video_language_setting?.avatar_cc_locale ||
          DEFAULT_LANGUAGE,
        is_automatically_timezone:
          data?.settings?.timezone_setting?.is_automatically_timezone !==
          undefined
            ? data?.settings?.timezone_setting?.is_automatically_timezone
            : true,
        default_duration:
          data?.settings?.scheduler?.default_duration?.toString() ||
          DEFAULT_DURATION,
        timezone:
          data?.settings?.timezone_setting?.timezone ||
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        hours_per_day:
          data?.settings?.scheduler?.hours_per_day?.toString() ||
          DEFAULT_HOURS_PER_DAY,
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        hourly_rate: data?.settings?.cost_saving?.default_per_hour_cost
          ?.amount_100x
          ? data?.settings?.cost_saving?.default_per_hour_cost?.amount_100x.toString()
          : DEFAULT_RATE,
        job_title:
          data?.settings?.profile_setting?.role_information?.job_title || '',
        department_of_team:
          data?.settings?.profile_setting?.role_information
            ?.department_of_team || '',
        address_street:
          data?.settings?.profile_setting?.address?.address_street || '',
        address_apartment:
          data?.settings?.profile_setting?.address?.address_apartment || '',
        address_city:
          data?.settings?.profile_setting?.address?.address_city || '',
        address_state:
          data?.settings?.profile_setting?.address?.address_state || '',
        address_zipcode:
          data?.settings?.profile_setting?.address?.address_zipcode || '',
        savings_duration:
          data?.settings?.cost_saving?.default_duration ||
          DEFAULT_SAVINGS_DURATION,
        negotiation_duration:
          convertSecondsToHours(data?.settings?.scheduler?.request_duration) ||
          DEFAULT_NEGOTIATION_DURATION,
        negotiation_duration_between_reminder_emails:
          convertSecondsToHours(
            data?.settings?.scheduler?.nudge?.reminder?.reminder_freq
              ?.trigger_period,
          ) || DEFAULT_DURATION_BETWEEN_REMINDER_EMAILS,
        request_window_expansion:
          convertSecondsToWeeks(
            data?.settings?.scheduler?.nudge?.extend_search_time?.extend_by,
          ) || DEFAULT_REQUEST_WINDOW_EXPANSION,
        minimum_agreement_percentage:
          +(
            data?.settings?.scheduler?.nudge?.max_agree?.min_agree_rate?.rate ||
            DEFAULT_MINIMUM_AGREEMENT_PERCENTAGE
          ) * 100,
      };
    },
  });

  const { activeSettingsTab } = useSettings();

  const settingsList = [
    { title: SettingsTabs.GENERAL, content: <GeneralSettings /> },
    { title: SettingsTabs.PROFILE, content: <ProfileSettings /> },
    {
      title: SettingsTabs.VOICE_LANGUAGE,
      content: <ExecutiveAssistanceSettings />,
    },
    { title: SettingsTabs.ACCESS_AND_DATA, content: <AccessAndDataSettings /> },
  ];

  return (
    <FormProvider<SettingsFormData> methods={methods}>
      <div data-testid="settings-section" className="nj-settings-section">
        <div className="nj-tabbed-layout--panels nj-section--main-container with-tabs">
          {settingsList.map((setting, index) => (
            <TabPanel
              hidden={activeSettingsTab !== setting.title}
              key={`examplepanel-${setting.title}-${index}`}
            >
              {setting.content}
            </TabPanel>
          ))}
        </div>
      </div>
    </FormProvider>
  );
};
