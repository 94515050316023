import { useMemo, useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  AiGenerationData,
  ModalGalleryType,
  ExternalModelReference as ExternalModelReferenceType,
} from 'src/types';
import { prepareExternalModelsReferences } from 'src/utils';
import { ExternalModelReference } from './components/ExternalModelReference';
import { CollapsibleReferencesList } from 'src/components/CollapsibleReferencesList';

interface ExternalModelReferencesListProps {
  references?: Record<string, AiGenerationData>;
  originalQuery?: string;
}

export const ExternalModelReferencesList = ({
  references = {},
  originalQuery,
}: ExternalModelReferencesListProps) => {
  const { onChangeModalGalleryData } = useContext(SessionContext);

  const sources: ExternalModelReferenceType[] = useMemo(
    () => prepareExternalModelsReferences(references, originalQuery),
    [references, originalQuery],
  );

  const handleClick = (key: string) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.EXTERNAL_MODELS,
      sources,
      Number(key) - 1,
    );
  };

  return (
    <CollapsibleReferencesList
      title="Models"
      references={sources}
      renderItem={(props) => (
        <ExternalModelReference
          {...props}
          key={props.url}
          disabled={!props.content}
          onClick={handleClick}
        />
      )}
    />
  );
};
