import { ArrowsClockwise } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { sanitize } from 'dompurify';
import { CalendarCardEvent } from 'src/types';
import {
  SCHEDULE_ACTION_CREATED,
  SCHEDULE_ACTION_DELETED,
} from 'src/constants';
import { Markdown } from 'src/components/Markdown';

const SVG_SIZE = 16;

interface ScheduleEventProps {
  event: CalendarCardEvent;
}

export const ScheduleEvent = ({ event }: ScheduleEventProps) => {
  const {
    end,
    start,
    title,
    action,
    is_recurring,
    is_all_day,
    html_link = '#',
  } = event;
  const timeRange = is_all_day
    ? 'All day'
    : `${dayjs(start).format('h:mm A')}-${dayjs(end).format('h:mm A')}`;

  return (
    <a
      href={html_link}
      target="_blank"
      rel="noreferrer"
      title={title}
      className={classNames('nj-thread-schedule-card--event', {
        selected: action === SCHEDULE_ACTION_CREATED,
        deleted: action === SCHEDULE_ACTION_DELETED,
      })}
    >
      <p className="nj-thread-schedule-card--event-time-range">
        <span>{timeRange}</span>

        {is_recurring && <ArrowsClockwise size={SVG_SIZE} />}
      </p>

      <div className="nj-thread-schedule-card--event-title">
        <Markdown>{sanitize(title)}</Markdown>
      </div>
    </a>
  );
};
