import styles from './TooltipMobile.module.scss';
import { X } from '@phosphor-icons/react';
import { useVisible} from 'src/hooks';

interface TooltipMobileProps {
  children: React.ReactNode;
}

export const TooltipMobile = ({children}: TooltipMobileProps) => {
  const { isVisible, handleVisibilityRemove } =
    useVisible(true);
  const SVG_SIZE = 24;

  const handleClick = () => {
    handleVisibilityRemove();
  }

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.root}>
      <X size={SVG_SIZE} className={styles.close} onClick={handleClick} />
      <h4 className={styles.title}>Try Ninja Pro for 90% off</h4>
      {children}
    </div>
  );
};
