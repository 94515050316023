import { ReactNode } from 'react';
import { ResearchTaskCreationCard, SchedulerCreationCard } from 'src/types';
import { TaskProgressSection } from './components/TaskProgressSection';

interface ThreadCombinedCardProps {
  // TODO(olha): add a type for ChatCard
  data?: ResearchTaskCreationCard | SchedulerCreationCard;
  children: ReactNode;
}

export const ThreadCombinedCard = ({
  data,
  children,
}: ThreadCombinedCardProps) => {
  return (
    <div data-e2e="combined-card" className="nj-thread-combined-card">
      <div className="nj-thread-combined-card--container">
        {data && <TaskProgressSection data={data} />}

        {children}
      </div>
    </div>
  );
};
