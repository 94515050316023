import { Paperclip } from '@phosphor-icons/react';
import { AttachmentFileInputId } from 'src/constants';
import { ChangeEvent, useContext } from 'react';
import { useSession } from 'src/hooks';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { useLazyGetURLForFileUploadQuery } from 'src/store/services';
import { toast } from 'react-toastify';

export const AddAttachmentButton = () => {
  const {
    setThreadInputBoxFile,
    threadInputBoxFile,
    setAttachmentLoading,
    setFileDataToSend,
  } = useContext<ThreadInputBoxContextType>(ThreadInputBoxContext);
  const { isOpenTier, appUser } = useSession();
  const [getFileUploadUrl] = useLazyGetURLForFileUploadQuery();

  const handleAttachClick = () => {
    document.getElementById(AttachmentFileInputId)?.click();
  };

  const handleFileSelect = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement)?.files;
    const file = files ? files[0] : null;
    if (file) {
      if (file.size > 1024 * 1024) {
        toast.error('File size more then 1MB');
      } else {
        setAttachmentLoading(true);
        setThreadInputBoxFile(file);
        const { data } = await getFileUploadUrl({
          user_id: appUser.user_id,
          file_name: file.name,
        });

        if (data) {
          const formData = new FormData();
          for (const [key, value] of Object.entries(data.form_data.fields)) {
            formData.append(key, value);
          }
          formData.append('file', file);

          const uploadFileUrl = data?.form_data?.url;

          fetch(uploadFileUrl, {
            method: 'POST',
            body: formData,
            mode: 'cors',
          })
            .then((response) => {
              if (response.ok) {
                setAttachmentLoading(false);
                setFileDataToSend(
                  `---[original-filename=${data?.original_filename} converted-filename=${data?.filename}]---`,
                );
              }
            })
            .catch((error) => {
              // todo handle error state
              setAttachmentLoading(false);
            });
        }
      }
    }
  };

  return (
    <div>
      <button
        className="nj-thread-input-box--attach-button"
        onClick={handleAttachClick}
        disabled={!!threadInputBoxFile || isOpenTier}
      >
        <Paperclip size={24} />
      </button>
      <input
        id="attachFile"
        type="file"
        accept=".txt,.docx,.pdf,.rtf,.csv,.tsv,.xlsx"
        onChange={handleFileSelect}
        className="nj-thread-input-box--attach-input"
      />
    </div>
  );
};
