import { Fragment, useContext, useMemo, useCallback } from 'react';
import DebugContext from 'src/contexts/DebugContext';
import { useThreads } from 'src/hooks';
import { CodeCard as CodeCardType } from 'src/types/models/CodeCard';
import {
  MessageType,
  Message,
  ResearchCard,
  CalendarCard,
  CreateMeetingCard as CreateMeetingCardType,
  TextInputCard as TextInputCardType,
  ProposalConfirmationCard as ProposalConfirmationCardType,
} from 'src/types';
import { ContextCard } from './components/ContextCard';
import { ChoicesCard } from './components/ChoicesCard';
import { EmailCard } from './components/EmailCard';
import { ContextCardItem } from './components/ContextCardItem';
import { CodeCard } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/CodeCard';
import { groupMessagesByDate } from 'src/utils';
import { MessageWrapper } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/MessageWrapper';
import { ThreadResearchCard } from 'src/components/FlatAppearance/components/ThreadResearchCard';
import { ThreadScheduleCard } from 'src/components/FlatAppearance/components/ThreadScheduleCard';
import { CreateMeetingCard } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/CreateMeetingCard';
import { TextInputCard } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/TextInputCard';
import { ProposalConfirmationCard } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/ProposalConfirmationCard';
import { ThreadImageGenerationCard } from 'src/components/FlatAppearance/components/ThreadImageGenerationCard';
import { ImageCard } from 'src/types/models/ImageCard';

export const TaskScratchpad = () => {
  const { currentTask } = useThreads();
  const { debugMode } = useContext(DebugContext);

  const { conversation, task_id, messages_context } = currentTask || {};

  const displayMessages = useCallback(
    (messages: Message[]) => {
      return messages.map((message: Message, index: number) => {
        const key = `taskscratchpad-message-${message.message_id}-${index}`;

        switch (message.message_type) {
          case MessageType.PROGRESS_UPDATE:
            return null;

          case MessageType.RESEARCH_CARD:
            return (
              message?.payload && (
                <MessageWrapper message={message} key={key}>
                  <ThreadResearchCard
                    researchCard={message.payload as ResearchCard}
                  />
                </MessageWrapper>
              )
            );

          case MessageType.CHOICES_CARD:
            return (
              <MessageWrapper message={message} key={key}>
                <ChoicesCard message={message} taskId={task_id} />
              </MessageWrapper>
            );

          case MessageType.EMAIL_CARD:
            return (
              <MessageWrapper message={message} key={key} isTimeVisible={false}>
                <EmailCard message={message} />
              </MessageWrapper>
            );

          case MessageType.CALENDAR_CARD:
            return (
              message?.payload && (
                <MessageWrapper message={message} key={key}>
                  <ThreadScheduleCard
                    calendarCard={message.payload as CalendarCard}
                  />
                </MessageWrapper>
              )
            );

          case MessageType.CONVERSATION:
            return (
              <MessageWrapper message={message} key={key}>
                <div className="nj-task-scratchpad--section">
                  <ContextCardItem
                    role={message.role}
                    content={message.content}
                  />
                </div>
              </MessageWrapper>
            );

          case MessageType.CODE_CARD:
            return (
              message?.payload && (
                <MessageWrapper message={message} key={key}>
                  <CodeCard
                    codeCardSnippets={
                      (message.payload as CodeCardType)?.data?.code_snippets
                    }
                  />
                </MessageWrapper>
              )
            );

          case MessageType.CREATE_MEETING_CARD:
            return (
              <MessageWrapper message={message} key={key}>
                <CreateMeetingCard
                  message={message}
                  meetingCard={message.payload as CreateMeetingCardType}
                />
              </MessageWrapper>
            );

          case MessageType.TEXT_INPUT_CARD:
            return (
              <MessageWrapper message={message} key={key}>
                <TextInputCard
                  message={message}
                  textInputCard={message.payload as TextInputCardType}
                />
              </MessageWrapper>
            );

          case MessageType.PROPOSAL_CONFIRMATION_CARD:
            return (
              <MessageWrapper message={message} key={key}>
                <ProposalConfirmationCard
                  message={message}
                  proposalConfirmationCard={
                    message.payload as ProposalConfirmationCardType
                  }
                />
              </MessageWrapper>
            );

          case MessageType.IMAGE_CARD:
            return (
              <MessageWrapper message={message} key={key}>
                <ThreadImageGenerationCard
                  imageCard={message.payload as ImageCard}
                />
              </MessageWrapper>
            );

          default:
            return <Fragment key={message.message_id} />;
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversation?.messages, task_id],
  );

  const displayMessagesWithDate = useMemo(() => {
    const groupedMessagesByDate = groupMessagesByDate(
      conversation?.messages || [],
    );
    return Object.keys(groupedMessagesByDate).map((key) => {
      return (
        <Fragment key={`grouped-messages-${key}`}>
          <div className="nj-task-scratchpad--date-container">
            <div className="nj-task-scratchpad--date-hr" />
            <div className="nj-task-scratchpad--date">{key}</div>
          </div>
          {displayMessages(groupedMessagesByDate[key])}
        </Fragment>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation?.messages, task_id]);

  if (!currentTask) {
    return null;
  }

  return (
    <div className="nj-task-scratchpad">
      <div className="nj-task-scratchpad--container">
        {messages_context && debugMode && (
          <ContextCard messagesContext={messages_context} />
        )}

        {displayMessagesWithDate}
        {/* <div ref={anchorRef} /> */}
      </div>
    </div>
  );
};
