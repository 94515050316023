import { useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CaretLeft } from '@phosphor-icons/react';
import { Button } from 'src/components/Button';
import { useSession, useSettings, useTheme, useBreakpoint } from 'src/hooks';
import { ChatModeSidePanel } from 'src/types';
import { SettingsTabs } from 'src/store/slices/settingsSlice';
import { SettingsSection } from 'src/pages/ManageTasksChatPage/components/SettingsSection';
import styles from './ProfileSettingsSectionGroup.module.scss';

const SVG_SIZE = 24;

export const ProfileSettingsSectionGroup = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileOrTablet } = useBreakpoint();

  const { updateChatModeSidePanel } = useSession();
  const { toggleProfileSettingsBarExpanded, isProfileSettingsBarExpanded } =
    useTheme();
  const { activeSettingsTab } = useSettings();

  const sectionTitle = useMemo(() => {
    switch (activeSettingsTab) {
      case SettingsTabs.GENERAL:
        return 'General';
      case SettingsTabs.PROFILE:
        return 'Profile';
      case SettingsTabs.VOICE_LANGUAGE:
        return 'Voice & Language';
      case SettingsTabs.ACCESS_AND_DATA:
        return 'Access & Data';
      default:
        return 'Settings';
    }
  }, [activeSettingsTab]);

  const handleGoToProfile = () => {
    updateChatModeSidePanel(ChatModeSidePanel.PROFILE_INFO);
    toggleProfileSettingsBarExpanded(false);
  };

  return (
    <CSSTransition
      in={isProfileSettingsBarExpanded}
      timeout={isMobileOrTablet ? 450 : 0}
      classNames={
        isMobileOrTablet ? 'nj-animate-horizontal-slow-appearance' : ''
      }
      unmountOnExit
      nodeRef={containerRef}
    >
      <div className={styles.root} ref={containerRef}>
        <div className="nj-section--header-settings">
          <Button onClick={handleGoToProfile}>
            <CaretLeft size={SVG_SIZE} />
          </Button>
          <h5 className="nj-section--title">{sectionTitle}</h5>
        </div>

        <SettingsSection />
      </div>
    </CSSTransition>
  );
};
