import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

interface GuestAuthLinksProps {
  className?: string;
}

export const GuestAuthLinks = ({ className }: GuestAuthLinksProps) => {
  const { onToggleVisibleUpgradeModal } = useContext(SessionContext);

  return (
    <div
      className={classNames('nj-tier-auth-footer-button-wrapper', className)}
    >
      <Link to="/login" className="nj-tier-auth-footer-button login-button">
        Login
      </Link>
      <button
        type="button"
        className="nj-tier-auth-footer-button signup-button"
        onClick={() => onToggleVisibleUpgradeModal(true)}
      >
        Sign up
      </button>
    </div>
  );
};
