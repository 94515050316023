import { useEffect, useState } from 'react';
import { Select } from 'src/components/Select';
import { STATES } from 'src/constants/states';
import { useFormContext } from 'react-hook-form';
import { useSession, useGetUserPhoto } from 'src/hooks';
import { FormInput } from 'src/components/FormInput';
import { useUserData } from 'src/hooks/useUserData';

export const ProfileSettings = () => {
  const {
    appUser: { email, user_id, ...restUserInfo },
  } = useSession();
  const { setValue } = useFormContext();
  const { photoUrl } = useGetUserPhoto();

  const [img, setImg] = useState('');
  const [errorTooltip, setErrorTooltip] = useState<string | null>(null);

  const { updateUserData } = useUserData();

  useEffect(() => {
    if (photoUrl) {
      setImg(photoUrl);
    }
  }, [photoUrl]);

  // const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const maxAllowedSize = MAX_SIZE * 1024 * 1024;
  //   if (!e?.target?.files || e?.target?.files?.length === 0) {
  //     setImg('');
  //     return
  //   } else if (e.target.files[0].size > maxAllowedSize) {
  //     toast.error(`Max. file size: ${MAX_SIZE} MB`);
  //     e.target.value = '';
  //     setImg('');
  //     return
  //   }
  //   setImg(URL.createObjectURL(e.target.files[0]))
  // }

  const handleChangeUserInfo = (
    name: 'first_name' | 'last_name',
    value: string,
  ) => {
    const initValue = restUserInfo[name] || '';
    if (value !== '') {
      if (value !== initValue) {
        updateUserData({ [name]: value });
      }
    } else {
      setValue(name, initValue);
      setErrorTooltip(name);
    }
  };

  return (
    <div className="nj-settings-tab-content nj-profile-settings">
      <div>
        <h4 className="nj-settings-tab-content-title">Your photo</h4>
        <p className="nj-settings-tab-content--form-sublabel nj-settings-tab-content--form-sublabel-light">
          JPEG, PNG, Max. file size: 5 MB
        </p>
      </div>
      <div className="nj-profile-settings-image-wrapper">
        <img
          referrerPolicy="no-referrer"
          src={img}
          alt="User Avatar"
          className="nj-profile-settings-image"
        />
      </div>
      <hr className="divider" />
      <h4 className="nj-settings-tab-content-title">Contact information</h4>
      <div className="nj-settings-tab-content--form-field">
        <label className="nj-settings-tab-content--form-label">
          First name
        </label>
        <FormInput
          name="first_name"
          type="text"
          className="nj-settings-tab-content--input"
          onFocus={() => setErrorTooltip(null)}
          onBlurHandler={(value) => handleChangeUserInfo('first_name', value)}
          errorMessage={
            errorTooltip === 'first_name' ? 'First name is required' : undefined
          }
        />
      </div>
      <div className="nj-settings-tab-content--form-field">
        <label className="nj-settings-tab-content--form-label">Last name</label>
        <FormInput
          name="last_name"
          type="text"
          className="nj-settings-tab-content--input"
          onFocus={() => setErrorTooltip(null)}
          onBlurHandler={(value) => handleChangeUserInfo('last_name', value)}
          errorMessage={
            errorTooltip === 'last_name' ? 'Last name is required' : undefined
          }
        />
      </div>
      <div className="nj-settings-tab-content--form-field">
        <label className="nj-settings-tab-content--form-label">Email</label>
        <input
          className="nj-settings-tab-content--input"
          disabled
          value={email}
        />
      </div>
      <div className="nj-settings-tab-content--form-field nj-settings-address-fields">
        <label className="nj-settings-tab-content--form-label">
          <span>Address</span>
          <span className="label-optional">Optional</span>
        </label>
        <div className="nj-settings-address-fields-wrapper">
          <FormInput
            name="address_street"
            type="text"
            className="nj-settings-tab-content--input"
            onBlurHandler={(value) =>
              updateUserData({
                settings: {
                  ...restUserInfo.settings,
                  profile_setting: {
                    ...restUserInfo.settings?.profile_setting,
                    address: {
                      ...restUserInfo.settings?.profile_setting?.address,
                      address_street: value,
                    },
                  },
                },
              })
            }
            placeholder="Street name (e,g. 123 Main st)"
          />
          <FormInput
            name="address_apartment"
            type="text"
            className="nj-settings-tab-content--input"
            onBlurHandler={(value) =>
              updateUserData({
                settings: {
                  ...restUserInfo.settings,
                  profile_setting: {
                    ...restUserInfo.settings?.profile_setting,
                    address: {
                      ...restUserInfo.settings?.profile_setting?.address,
                      address_apartment: value,
                    },
                  },
                },
              })
            }
            placeholder="Apt./Suite/Floor"
          />
          <FormInput
            name="address_city"
            type="text"
            className="nj-settings-tab-content--input"
            onBlurHandler={(value) =>
              updateUserData({
                settings: {
                  ...restUserInfo.settings,
                  profile_setting: {
                    ...restUserInfo.settings?.profile_setting,
                    address: {
                      ...restUserInfo.settings?.profile_setting?.address,
                      address_city: value,
                    },
                  },
                },
              })
            }
            placeholder="City"
          />
          <div className="nj-settings-address-state-zipcode-wrapper">
            <Select
              name="address_state"
              isSearchable
              placeholder="State"
              isShort
              options={STATES.map((item) => ({
                label: item.name,
                value: item.abbreviation,
              }))}
              onChangeHandler={(value) =>
                updateUserData({
                  settings: {
                    ...restUserInfo.settings,
                    profile_setting: {
                      ...restUserInfo.settings?.profile_setting,
                      address: {
                        ...restUserInfo.settings?.profile_setting?.address,
                        address_state: value,
                      },
                    },
                  },
                })
              }
            />
            <FormInput
              name="address_zipcode"
              type="text"
              className="nj-settings-tab-content--input"
              onBlurHandler={(value) =>
                updateUserData({
                  settings: {
                    ...restUserInfo.settings,
                    profile_setting: {
                      ...restUserInfo.settings?.profile_setting,
                      address: {
                        ...restUserInfo.settings?.profile_setting?.address,
                        address_zipcode: value,
                      },
                    },
                  },
                })
              }
              placeholder="Zipcode"
            />
          </div>
        </div>
      </div>
      <hr className="divider" />
      <h4 className="nj-settings-tab-content-title">Role information</h4>
      <div className="nj-settings-tab-content--form-field">
        <label className="nj-settings-tab-content--form-label">
          <span>Job title</span>
          <span className="label-optional">Optional</span>
        </label>
        <FormInput
          name="job_title"
          type="text"
          className="nj-settings-tab-content--input"
          onBlurHandler={(value) =>
            updateUserData({
              settings: {
                ...restUserInfo.settings,
                profile_setting: {
                  ...restUserInfo.settings?.profile_setting,
                  role_information: {
                    ...restUserInfo.settings?.profile_setting?.role_information,
                    job_title: value,
                  },
                },
              },
            })
          }
        />
      </div>
      <div className="nj-settings-tab-content--form-field">
        <label className="nj-settings-tab-content--form-label">
          <span>Department of team</span>
          <span className="label-optional">Optional</span>
        </label>
        <FormInput
          name="department_of_team"
          type="text"
          className="nj-settings-tab-content--input"
          onBlurHandler={(value) =>
            updateUserData({
              settings: {
                ...restUserInfo.settings,
                profile_setting: {
                  ...restUserInfo.settings?.profile_setting,
                  role_information: {
                    ...restUserInfo.settings?.profile_setting?.role_information,
                    department_of_team: value,
                  },
                },
              },
            })
          }
        />
      </div>
      <hr className="divider" />
    </div>
  );
};
