import { useSession } from 'src/hooks';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { InsufficientCreditsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/InsufficientCreditsSection';
import { RemainingTasksSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/RemainingTasksSection';
import { CreditsInfoSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/CreditsInfoSection';
import { UserTierStatus } from 'src/types';
import { LowCreditsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/LowCreditsSection';
import { useGetUserBudgetQuotaInfoQuery } from 'src/store/services';
import styles from './WalletSection.module.scss';

export const WalletSection = () => {
  const { isOpenTier, isFreeTier, isProTier, appUser } = useSession();

  const { data: quotaData } = useGetUserBudgetQuotaInfoQuery({
    user_id: appUser.user_id,
  });

  return (
    <div className={styles.root}>
      {(isOpenTier || isFreeTier) && <RemainingTasksSection />}

      {isProTier && <CreditsInfoSection />}

      {(isOpenTier || isFreeTier) && (
        <>
          <hr className="divider" />

          {isFreeTier && (
            <p className={styles.offerLabel}>Limited time Offer</p>
          )}

          <UpsellMessage
            description={
              isOpenTier
                ? 'To preserve your chat history, enjoy higher usage limits, and access meeting negotiator & video chat.'
                : 'Try advanced meeting negotiations, deep research, access to third party LLMs and video chat with Ninja.'
            }
            className={styles.upsellMessage}
          />
        </>
      )}

      {isProTier &&
        appUser?.tier_status === UserTierStatus.OK &&
        quotaData?.is_low_balance && (
          <>
            <hr className="divider" />
            <LowCreditsSection />
          </>
        )}

      {isProTier && appUser?.tier_status === UserTierStatus.QUOTA_EXCEEDED && (
        <>
          <hr className="divider" />
          <InsufficientCreditsSection />
        </>
      )}
    </div>
  );
};
