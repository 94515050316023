import type { MeetingCardFormData } from '../CreateMeetingCard';
import {
  Attendee,
  CreateMeetingCard as CreateMeetingCardType,
  Message,
  Span,
} from 'src/types';
import {
  processSelectionInSelectOptions,
  convertRruleOptionsToRruleString,
} from 'src/utils';

interface PrepareMessageForSubbmitArgs {
  message: Message;
  meetingCard: CreateMeetingCardType;
  data: MeetingCardFormData;
}

export const prepareMessageForSubmit = ({
  data,
  message,
  meetingCard,
}: PrepareMessageForSubbmitArgs) => {
  const {
    event_title,
    location,
    conference,
    minutes,
    // all_day,
    is_recurrent,
    recurrent_frequency,
    recurrent_interval,
    recurrent_total_occurrences,
    meeting_windows,
    attendees,
    confirm_guest_availability,
    organizer_availability,
    event_description,
  } = data;

  const preparedPayload: CreateMeetingCardType = {
    ...meetingCard,
    event_title: {
      ...meetingCard.event_title,
      value: event_title,
    },
    event_description: {
      ...meetingCard.event_description,
      value: event_description,
    },
    location_and_conference: {
      ...meetingCard.location_and_conference,
      location: {
        ...meetingCard.location_and_conference.location,
        value: location,
      },
      conference: {
        ...meetingCard.location_and_conference.conference,
        options: processSelectionInSelectOptions(
          meetingCard.location_and_conference.conference.options,
          conference,
        ),
      },
    },
    duration: {
      ...meetingCard.duration,
      minutes,
      // all_day,
    },
    meeting_windows: {
      ...meetingCard.meeting_windows,
      values: (meeting_windows as Span[]) || [],
    },
    recurrence: {
      ...meetingCard.recurrence,
      value: is_recurrent
        ? [
            convertRruleOptionsToRruleString({
              freq: recurrent_frequency,
              interval: recurrent_interval,
              count: recurrent_total_occurrences,
            }),
          ]
        : undefined,
    },
    attendees: {
      ...meetingCard.attendees,
      values: (attendees as Attendee[]) || [],
    },
    confirm_guest_availability: {
      ...meetingCard.confirm_guest_availability,
      value: confirm_guest_availability,
    },
    organizer_availability: {
      ...meetingCard.organizer_availability,
      options: processSelectionInSelectOptions(
        meetingCard.organizer_availability.options,
        organizer_availability,
      ),
    },
  };

  const preparedMessage: Message = {
    ...message,
    payload: preparedPayload,
  };

  return preparedMessage;
};
