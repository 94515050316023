import dayjs from 'dayjs';
import { Hash } from '@phosphor-icons/react';
import { ApiTaskSelectable } from 'src/types';
import { useSession } from 'src/hooks';
import { TaskStatusIcon } from 'src/components/TaskStatusIcon';

const SVG_SIZE = 20;

interface NotificationItemProps {
  data: Partial<ApiTaskSelectable>;
}

export const NotificationItem = ({ data }: NotificationItemProps) => {
  const { updateSelectedTaskDetail } = useSession();
  const { state, task_hash, task_subject, created_at, task_id } = data;

  const handleItemClick = () => {
    if (!task_id) {
      return;
    }
    updateSelectedTaskDetail(task_id);
  };

  return (
    <div
      className="nj-section--notification-item"
      role="button"
      onClick={handleItemClick}
    >
      <div className="nj-section--notification-item-container">
        <p className="nj-section--main-container-subtitle">
          {dayjs(created_at).format('hh:mm A')}
        </p>

        <h4 className="nj-section--main-container-title">
          <Hash size={SVG_SIZE} />
          <span>{task_hash?.replace('#', '')}</span>
        </h4>

        <p className="nj-section--main-container-description">{task_subject}</p>
      </div>

      <TaskStatusIcon taskState={state} />
    </div>
  );
};
