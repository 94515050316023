import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { Tooltip } from 'src/components/Tooltip';
import { useState } from 'react';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import classNames from 'classnames';
import { InputTypes } from 'src/types';

interface InputProps extends FieldValues {
  name: string;
  onChangeHandler?: (data: string) => void;
  onBlurHandler?: (data: string) => void;
  className?: string;
  errorMessage?: string;
  isInteger?: boolean;
  type?: InputTypes;
  hasErrorBorder?: boolean;
  required?: boolean;
}

const SVG_SIZE = 20;

// TODO(olha): move error condition inside component
export const FormInput = ({
  name,
  onChangeHandler,
  onBlurHandler,
  className,
  errorMessage,
  isInteger,
  type = 'text',
  hasErrorBorder = false,
  required = false,
  ...rest
}: InputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === 'password';

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        validate: (value) => {
          if (typeof value === 'string') {
            return !!value?.trim();
          }
          return true;
        },
      }}
      render={({ field: { onChange, value, onBlur } }) => (
        <div
          lang="en-US"
          className={classNames('nj-input-wrapper', {
            isPassword,
            hasErrorBorder: !!errors[name] || hasErrorBorder,
          })}
        >
          <input
            value={value || ''}
            onBlur={(e) => {
              onBlur();
              if (onBlurHandler) {
                onBlurHandler(e.target.value);
              }
            }}
            onChange={(e) => {
              if (isInteger) {
                if (isNaN(parseInt(e.target.value))) {
                  return;
                }
              }

              const value = Number(e.target.value);
              if (
                !!e.target.value &&
                ((typeof rest.min === 'number' && value < rest.min) ||
                  (typeof rest.max === 'number' && value > rest.max))
              ) {
                return;
              } else {
                onChange(e);
                if (onChangeHandler) {
                  onChangeHandler(e.target.value);
                }
              }
            }}
            className={className}
            lang="en-US"
            type={isPassword ? (showPassword ? 'text' : 'password') : type}
            // attn: a hack to make autocomplete work, cancel out in form tag
            autoComplete="new-password"
            {...rest}
          />
          {isPassword && (
            <button
              className="nj-input-field-password-icon"
              onClick={() => setShowPassword(!showPassword)}
              type="button"
            >
              {showPassword ? (
                <EyeSlash size={SVG_SIZE} />
              ) : (
                <Eye size={SVG_SIZE} />
              )}
            </button>
          )}
          {(errorMessage || !!errors[name]?.message) && (
            <Tooltip
              title={errorMessage || errors[name]?.message?.toString() || ''}
            />
          )}
        </div>
      )}
    />
  );
};
