import { ChatModeSidePanel } from 'src/types';
import { CommonNavigationButton } from '../CommonNavigationButton';
import { Gear } from '@phosphor-icons/react';
import { useSession } from 'src/hooks';

export const SettingsButton = () => {
  const { appUser } = useSession();

  return (
    <CommonNavigationButton
      title="Manage account & settings"
      sidePanelType={ChatModeSidePanel.PROFILE_INFO}
      IconComponent={Gear}
      dataE2E="open-settings-button"
      elementId="onboarding-element-4"
      disabled={!appUser?.settings?.features?.settings_icon?.is_capable}
    />
  );
};
