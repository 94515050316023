import { lazy, Suspense, useRef } from 'react';
import cn from 'classnames';
import { useVisible, useOutsideClick } from 'src/hooks';
import { NinjaAgentsButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons/NinjaAgentsButton';
import { ExternalModelsButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons/ExternalModelsButton';
import { ImageGeneratorsButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons/ImageGeneratorsButton';
import { NinjaAgentsOutlinedIcon } from 'src/images/icons/ninjaAgentsOutlined';
import styles from './ModelsMenu.module.scss';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const ModelsMenu = () => {
  const containerRef = useRef<HTMLButtonElement>(null);

  const { isVisible, handleVisibilityRemove, handleVisibilityToggle } =
    useVisible();

  useOutsideClick(containerRef, handleVisibilityRemove);

  return (
    <div className={styles.root}>
      <button
        ref={containerRef}
        onClick={handleVisibilityToggle}
        id="models-menu-trigger"
        className={cn(styles.triggerButton, { [styles.active]: isVisible })}
      >
        <NinjaAgentsOutlinedIcon />
      </button>

      <Suspense>
        <LazyReactTooltip
          anchorSelect="#models-menu-trigger"
          clickable
          noArrow
          openOnClick
          disableStyleInjection
          className={styles.settingsTooltip}
          isOpen={isVisible}
          opacity={1}
          place="bottom-end"
          offset={10}
        >
          <div className={styles.menu}>
            <NinjaAgentsButton />
            <ExternalModelsButton />
            <ImageGeneratorsButton />
          </div>
        </LazyReactTooltip>
      </Suspense>
    </div>
  );
};
