import { useContext, useEffect, useCallback } from 'react';
import { Info } from '@phosphor-icons/react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { ChatMode } from 'src/types';
import { getDurationInMinutesAndSeconds, interruptMetahuman } from 'src/utils';
import { useSession } from 'src/hooks';
import AvatarModeContext, {
  AvatarModeContextType,
} from 'src/contexts/AvatarModeContext';
import {
  AVATAR_SESSION_NOTICE_SHOW,
  AVATAR_SESSION_NOTICE_WARNING,
} from 'src/constants';

const VIDEO_SESSION_ENDED = 'Video session ended.';

const SVG_SIZE = 24;

export const AvatarSessionNotice = () => {
  const {
    avatarTimeLeft,
    isAvatarSessionRunning,
    stopAvatarSession,
    clearShowAvatarQueue,
  } = useContext<AvatarModeContextType>(AvatarModeContext);
  const { appUser, updateAvatarQueue, updateChatMode } = useSession();

  const handleCloseSession = useCallback(() => {
    stopAvatarSession();
    updateAvatarQueue();
    clearShowAvatarQueue();
    updateChatMode(ChatMode.CHAT);
    interruptMetahuman(appUser.user_id);
    toast.info(VIDEO_SESSION_ENDED, {
      icon: <Info size={SVG_SIZE} weight="fill" />,
    });
  }, [
    stopAvatarSession,
    updateAvatarQueue,
    clearShowAvatarQueue,
    updateChatMode,
    appUser.user_id,
  ]);

  useEffect(() => {
    if (isAvatarSessionRunning && avatarTimeLeft === 0) {
      handleCloseSession();
    }
  }, [isAvatarSessionRunning, avatarTimeLeft, handleCloseSession]);

  const notice = getDurationInMinutesAndSeconds(avatarTimeLeft);

  const warning = avatarTimeLeft <= AVATAR_SESSION_NOTICE_WARNING;

  const visible =
    avatarTimeLeft > 0 && avatarTimeLeft <= AVATAR_SESSION_NOTICE_SHOW;

  return (
    <div
      className={classNames('nj-meta-human--session-notice', {
        visible,
        warning,
      })}
    >
      {notice} left
    </div>
  );
};
