import { sendGTMEvent } from 'src/utils';
import { ChatModeSidePanel, GTMEvent } from 'src/types';
import { useSession } from 'src/hooks';
import { useMemo } from 'react';
import { CommonNavigationButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons';
import { ImagesSquare } from '@phosphor-icons/react';

export const ImageGeneratorsButton = () => {
  const handleGTMEventClick = () => {
    sendGTMEvent(GTMEvent.IMAGE_GENERATORS_SETTINGS_CLICK);
  };

  const { appUser } = useSession();
  const countOfSelectedModels = useMemo(() => {
    const modelsList = appUser?.settings?.image_gen_settings?.models || [];
    const selectedNumber = modelsList.filter((item) => !!item.enabled).length;
    return selectedNumber === 0 ? undefined : selectedNumber;
  }, [appUser.settings?.image_gen_settings?.models]);

  return (
    <CommonNavigationButton
      onClick={handleGTMEventClick}
      title="Image generators"
      sidePanelType={ChatModeSidePanel.IMAGE_GENERATORS}
      IconComponent={ImagesSquare}
      // disabled={!appUser?.settings?.image_gen_settings?.models}
      count={countOfSelectedModels}
    />
  );
};
