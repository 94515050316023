import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppUser,
  BannerType,
  ConversationRole,
  SelectedTaskDetail,
  Notification,
  ApiTaskSelectable,
  TaskSkill,
} from 'src/types';
import { DEFAULT_AGENT } from 'src/constants';
import { fetchUserById } from 'src/store/thunks';
import { usersApi } from '../services';
import { RootState } from '../index';
import { UpdateAllUserStatusesRequest } from 'src/types/models/UpdateAllUserStatusesRequest';
import { UpdateAppVersion } from 'src/types/models/UpdateAppVersion';
import { PURGE } from 'reduxjs-toolkit-persist';
import { UserTier } from 'src/types/models/UserTier';
import { UserTierStatus } from 'src/types/models/UserTierStatus';
import type { StripeSubscription } from 'src/types/models/StripeSubscription';

interface SessionState {
  agent: AppUser;
  appUser: AppUser;
  notification?: Notification;
  isDarkTheme: boolean;
  isShowOnboardingHints: boolean;
  onboardingHintsStep: number;
  isAccessModalShown: boolean;
  isFirstSchedulerTask: boolean;
  bannerType: BannerType | null;
  isFirstUserQuery: boolean;
  // check if we still need keep them in the persist
  isSocketConnected: boolean;
  currentConversationId?: string;
  // should be deprecated
  currentTaskId?: string;
  // deprecating detail
  selectedTaskDetail: SelectedTaskDetail;
}

// To keep all session data here.
const initialState: SessionState = {
  agent: DEFAULT_AGENT,
  appUser: {} as AppUser,
  selectedTaskDetail: {
    selectedTaskId: 'default',
    cameFrom: undefined,
  } as SelectedTaskDetail,
  isSocketConnected: false,
  notification: undefined,
  isDarkTheme: false,
  isShowOnboardingHints: false,
  onboardingHintsStep: 0,
  currentTaskId: undefined,
  currentConversationId: undefined,
  isAccessModalShown: false,
  isFirstSchedulerTask: false,
  bannerType: null,
  isFirstUserQuery: false,
};

/**
 * Keeps on a session data.
 */
export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetSession: (state) => {
      return initialState;
    },
    setBannerType: (state, action: PayloadAction<BannerType | null>) => {
      return { ...state, bannerType: action.payload };
    },
    setSelectedTaskDetail: (
      state,
      action: PayloadAction<SelectedTaskDetail>,
    ) => {
      state.selectedTaskDetail = action.payload;
    },
    setNotification: (state, action: PayloadAction<Notification>) => {
      state.notification = action.payload;
    },
    setAppUser: (state, action: PayloadAction<AppUser>) => {
      state.appUser = action.payload;
    },
    setUserStatus: (
      state,
      action: PayloadAction<UpdateAllUserStatusesRequest>,
    ) => {
      return {
        ...state,
        appUser: { ...state.appUser, status: action.payload.new_status },
      };
    },
    updateUserTier: (
      state,
      action: PayloadAction<{
        new_tier: UserTier;
        new_tier_status: UserTierStatus;
      }>,
    ) => {
      return {
        ...state,
        appUser: {
          ...state.appUser,
          tier_id: action.payload.new_tier,
          tier_status: action.payload.new_tier_status,
        },
      };
    },
    updateRenewEarlyStatus: (
      state,
      action: PayloadAction<{
        renew_early_pending_subscription?: StripeSubscription;
      }>,
    ) => {
      return {
        ...state,
        appUser: {
          ...state.appUser,
          stripe_info: state.appUser.stripe_info
            ? {
                ...state.appUser.stripe_info,
                renew_early_pending_subscription:
                  action.payload.renew_early_pending_subscription,
              }
            : undefined,
        },
      };
    },
    setUserAppVersion: (state, action: PayloadAction<UpdateAppVersion>) => {
      return {
        ...state,
        appUser: {
          ...state.appUser,
          metadata: {
            ...state.appUser.metadata,
            app_version: action.payload.new_version,
          },
        },
      };
    },
    setIsDarkTheme: (state, action: PayloadAction<boolean>) => {
      state.isDarkTheme = action.payload;
    },
    showAccessRequestOnSchedulerTask: (
      state,
      action: PayloadAction<ApiTaskSelectable>,
    ) => {
      if (
        action.payload.skill === TaskSkill.SCHEDULING &&
        !state.isFirstSchedulerTask
      ) {
        state.isFirstSchedulerTask = true;
      }
    },
    setIsAccessModalShown: (state, action: PayloadAction<boolean>) => {
      state.isAccessModalShown = action.payload;
    },
    setSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.isSocketConnected = action.payload;
    },
    clearNotification: (state) => {
      state.notification = undefined;
    },
    setOnboardingHintStep: (state, action: PayloadAction<number>) => {
      state.onboardingHintsStep = action.payload;
    },
    setIsShowOnboardingHints: (state, action: PayloadAction<boolean>) => {
      state.isShowOnboardingHints = action.payload;
    },
    setCurrentTaskId: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        currentTaskId: action.payload,
      };
    },
    setCurrentConversationId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      return {
        ...state,
        currentConversationId: action.payload,
        currentTaskId: undefined,
      };
    },
    setFirstUserQuery: (state) => {
      state.isFirstUserQuery = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // TODO(olha): there are two same actions in different slices!!! needs check and remove from one
      .addCase(fetchUserById.fulfilled, (state, action) => {
        if (!action.payload) return state;
        const avatar = action.payload.first_name || '';
        const appUser = {
          ...action.payload,
          avatar: avatar !== '' ? `avatar${avatar}` : '',
          role: ConversationRole.USER,
        };

        // create an app user entry
        return { ...state, appUser };
      })
      .addCase(PURGE, () => {
        return initialState;
      })
      .addMatcher(
        usersApi.endpoints.getUserById.matchFulfilled,
        (state, action) => {
          return { ...state, appUser: { ...state.appUser, ...action.payload } };
        },
      );
  },
});

export const sessionState = (state: RootState) => state.session as SessionState;

export const {
  resetSession,
  setSocketConnected,
  setSelectedTaskDetail,
  setNotification,
  clearNotification,
  setAppUser,
  setUserStatus,
  setUserAppVersion,
  setIsDarkTheme,
  setOnboardingHintStep,
  setCurrentConversationId,
  setCurrentTaskId,
  showAccessRequestOnSchedulerTask,
  setIsAccessModalShown,
  updateUserTier,
  setBannerType,
  setFirstUserQuery,
  updateRenewEarlyStatus,
} = sessionSlice.actions;

export default sessionSlice.reducer;
