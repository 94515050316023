import { Fragment } from 'react';
import { useTasks } from 'src/hooks';
import { groupNotificationsByDate } from 'src/utils';
import { getTaskDisplayDate } from 'src/utils';
import { EmptyBlock } from './components/EmptyBlock';
import { NotificationItem } from './components/NotificationItem';
import { Button } from 'src/components/Button';

export const NotificationSection = () => {
  const { tasks } = useTasks();
  const groupedData = groupNotificationsByDate(tasks);

  if (!tasks || tasks.length === 0) {
    return <EmptyBlock />;
  }

  return (
    <div className="nj-section--main-container with-padding notification">
      {Object.entries(groupedData).map(([key, value]) => (
        <Fragment key={key}>
          <div className="nj-section--notification-wrapper">
            <p className="nj-section--main-container-subtitle">
              {getTaskDisplayDate(key)}
            </p>

            {value.map((notification) => (
              <NotificationItem
                key={notification.task_id}
                data={notification}
              />
            ))}
          </div>

          <hr className="nj-section--side-settings-panel-divider" />
        </Fragment>
      ))}

      <Button className="nj-section--notification-clear-button">
        Clear all notifications
      </Button>
    </div>
  );
};
