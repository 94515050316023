import { ChatMode } from 'src/types';
import { Hash } from '@phosphor-icons/react';
import { useSession, useBreakpoint, useThreads } from 'src/hooks';
import { MuteButton } from './components/MuteButton';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { MainNavToggleButton } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/MainNavToggleButton';
import { VideoCallButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons/VideoCallButton';
import { ModelsMenu } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/ModelsMenu';
import { UserMenu } from './components/UserMenu';
import styles from './ConversationsHeader.module.scss';
import { useMemo } from 'react';
import { SVG_SIZE_M } from 'src/constants';

export const ConversationsHeader = () => {
  const { isMobileOrTablet } = useBreakpoint();
  const { chatMode } = useSession();
  const { currentThread } = useThreads();

  const headerTitle = useMemo(
    () => currentThread?.conversation_hash?.replace('#', '') || '',
    [currentThread],
  );

  return (
    <header className={styles.root}>
      {isMobileOrTablet ? <MainNavToggleButton /> : <AddConversationButton />}

      {headerTitle && !isMobileOrTablet && (
        <h4 className={styles.title}>
          <Hash size={SVG_SIZE_M} />
          <span>{headerTitle}</span>
        </h4>
      )}

      <div className={styles.actionWrapper}>
        {chatMode === ChatMode.AVATAR && <MuteButton showLabel />}

        <VideoCallButton />
        <ModelsMenu />
        <UserMenu />

        {isMobileOrTablet && (
          <AddConversationButton className="nj-thread-header--mobile-create-new-chat" />
        )}
      </div>
    </header>
  );
};
