/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo, useEffect } from 'react';
import ForwardRefContext, {
  ForwardRefContextType,
} from 'src/contexts/ForwardRefContext';
import {
  useSession,
  useAvatarPreferences,
  useBreakpoint,
  useTheme,
} from 'src/hooks';
import { QueueMessageType, ChatMode } from 'src/types';
import { Modal } from 'react-responsive-modal';
import { X } from '@phosphor-icons/react';
import AvatarModeContext, {
  AvatarModeContextType,
} from 'src/contexts/AvatarModeContext';
import { DefaulQueueView } from './components/DefaulQueueView/DefaultQueueView';
import { QueuePositionView } from './components/QueuePositionView/QueuePositionView';
import { QuotaExceededView } from './components/QuotaExceededView';
import { ErrorView } from './components/ErrorView';
import { AVATAR_SESSION_DURATION_DEFAULT } from 'src/constants';

const SVG_SIZE = 24;

export const AvatarJoiningQueueModal = () => {
  const {
    showAvatarQueueModal,
    showAvatarQueueBanner,
    shouldShowAvatarIframe,
    setShowAvatarQueueModal,
    setShowAvatarQueueBanner,
    setShouldShowAvatarIframe,
    replaceAvatarQueueModalWithBanner,
    clearShowAvatarQueue,
    expandedMeta: isFullscreen,
    startAvatarSession,
  } = useContext<AvatarModeContextType>(AvatarModeContext);
  const { appUser, avatarQueue, updateAvatarQueue, updateChatMode } =
    useSession();
  const { isSettingsPanelExpanded, toggleSettingsPanelExpanded } = useTheme();
  const { rootContainerRef } =
    useContext<ForwardRefContextType>(ForwardRefContext);
  const { avatarImageURL } = useAvatarPreferences();
  const { isDesktopLAndUp } = useBreakpoint();

  // on refresh of the screen the modal might
  // disappear, show the queue banner instead
  useEffect(() => {
    if (
      !showAvatarQueueModal &&
      !showAvatarQueueBanner &&
      !shouldShowAvatarIframe &&
      avatarQueue?.joinedAvatarQueue &&
      !avatarQueue?.available
    ) {
      replaceAvatarQueueModalWithBanner();
    }
  }, [
    showAvatarQueueModal,
    showAvatarQueueBanner,
    shouldShowAvatarIframe,
    avatarQueue?.joinedAvatarQueue,
    avatarQueue?.available,
  ]);

  // todo: check in with Angel whether we need to send
  // cancel request to the signalling server also
  const handleCancel = () => {
    updateAvatarQueue();
    clearShowAvatarQueue();
    updateChatMode(ChatMode.CHAT);
  };

  // switch mode to avatar iframe
  const handleStartVideoChat = () => {
    // remove settings panel if it's supposed to be
    // an inline avatar: not fullscreen & not floating
    if (isSettingsPanelExpanded && isDesktopLAndUp && !isFullscreen) {
      toggleSettingsPanelExpanded(false);
    }

    setShowAvatarQueueBanner(false);
    setShowAvatarQueueModal(false);
    setShouldShowAvatarIframe(true);
    updateChatMode(ChatMode.AVATAR);
    // until it comes from the signalling server
    // make sure we set it ourselves on timer expiry
    updateAvatarQueue({
      ready: true,
    });

    // start avatar session timer worker
    const minutes =
      appUser?.settings?.features?.video_chat?.session_duration_min ?? 0;
    const milliseconds = minutes * 60 * 1000 || AVATAR_SESSION_DURATION_DEFAULT;
    startAvatarSession(milliseconds);
  };

  const handleOnClose = () => {
    // if the user is in the waiting room or available,
    // we should replace the modal with a banner on close
    if (
      avatarQueue?.messageType === QueueMessageType.WAITING_ROOM ||
      avatarQueue?.messageType === QueueMessageType.AVAILABLE
    ) {
      replaceAvatarQueueModalWithBanner();
    } else {
      // otherwise, just cancel the request
      handleCancel();
    }
  };

  const modalView = useMemo(() => {
    if (avatarQueue?.messageType === QueueMessageType.AVAILABLE) {
      return (
        <DefaulQueueView
          onCancel={handleCancel}
          onAutoClose={handleStartVideoChat}
        />
      );
    }

    if (avatarQueue?.messageType === QueueMessageType.WAITING_ROOM) {
      return (
        <QueuePositionView
          position={avatarQueue?.positionInQueue}
          waitingTime={avatarQueue?.expectedWaitingTime}
          onCancel={handleCancel}
        />
      );
    }

    if (avatarQueue?.messageType === QueueMessageType.QUOTA_EXCEEDED) {
      return <QuotaExceededView onCancel={handleCancel} />;
    }

    if (avatarQueue?.messageType === QueueMessageType.INVALID_REQUEST) {
      return (
        <ErrorView message={avatarQueue?.message} onCancel={handleCancel} />
      );
    }

    return <DefaulQueueView onCancel={handleCancel} />;
  }, [
    avatarQueue?.messageType,
    avatarQueue?.positionInQueue,
    avatarQueue?.expectedWaitingTime,
  ]);

  if (!showAvatarQueueModal) {
    return null;
  }

  return (
    <Modal
      open={showAvatarQueueModal}
      onClose={handleOnClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--avatar-joining-queue',
        closeIcon: 'nj-modal--closeButton',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <div className="container">
        <div className="nj-avatar-joining-queue--collection">
          <img
            src={avatarImageURL}
            alt="Ninja"
            className="nj-avatar-joining-queue--image"
          />
          <div className="nj-avatar-joining-queue--spinner"></div>
        </div>
        <div className="nj-avatar-joining-queue--views">
          <h3>Video chat with Ninja</h3>
          {modalView}
        </div>
      </div>
    </Modal>
  );
};
