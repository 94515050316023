import { useMemo } from 'react';
import {
  sessionState,
  setIsDarkTheme,
  setIsTasksListExpanded,
  useAppDispatch,
  useAppSelector,
  interfaceControlState,
  setProfileSettingsBarExpanded,
  setIsSettingsPanelExpanded,
} from 'src/store';
import { useUserData } from 'src/hooks/useUserData';
import { UIColors } from 'src/constants';
import { useSession } from 'src/hooks/useSession';
import { Appearance } from 'src/types/models/Appearance';

export const useTheme = () => {
  const { appUser } = useSession();
  const dispatch = useAppDispatch();
  const { isDarkTheme } = useAppSelector(sessionState);
  const {
    isTasksListExpanded,
    isProfileSettingsBarExpanded,
    isSettingsPanelExpanded,
  } = useAppSelector(interfaceControlState);
  const { updateUserData } = useUserData();

  const updateTasksListExpanded = (value: boolean) => {
    dispatch(setIsTasksListExpanded(value));
  };

  const toggleTasksListExpanded = () => {
    dispatch(setIsTasksListExpanded(!isTasksListExpanded));
  };

  const toggleProfileSettingsBarExpanded = (value?: boolean) => {
    dispatch(
      setProfileSettingsBarExpanded(value ?? !isProfileSettingsBarExpanded),
    );
  };

  const toggleSettingsPanelExpanded = (value?: boolean) => {
    dispatch(setIsSettingsPanelExpanded(value ?? !isSettingsPanelExpanded));
  };

  const updateIsDarkTheme = (isDark: boolean) => {
    updateUserData(
      {
        settings: {
          ...appUser.settings,
          app_appearance: {
            appearance: isDark ? Appearance.DARK : Appearance.LIGHT,
          },
        },
      },
      false,
    );
    dispatch(setIsDarkTheme(isDark));
  };

  const skeletonColors = useMemo(() => {
    const colors = isDarkTheme ? UIColors.darkMode : UIColors.lightMode;
    return {
      baseColor: colors.SKELETON_BACKGROUND,
      highlightColor: colors.SKELETON_HIGHLIGHT,
    };
  }, [isDarkTheme]);

  return {
    isTasksListExpanded,
    isDarkTheme:
      appUser?.settings?.app_appearance?.appearance === Appearance.DARK ??
      isDarkTheme,
    isProfileSettingsBarExpanded,
    isSettingsPanelExpanded,
    setIsDarkTheme: updateIsDarkTheme,
    setIsTasksListExpanded: updateTasksListExpanded,
    toggleTasksListExpanded,
    toggleProfileSettingsBarExpanded,
    toggleSettingsPanelExpanded,
    skeletonColors,
  };
};
