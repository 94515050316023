import { CodeCard } from 'src/types/models/CodeCard';
import { useRef } from 'react';
import { ThreadCodeSources } from 'src/components/FlatAppearance/components/ThreadCodeCard/components/ThreadCodeSources';
import {
  UpsellMessageCardType,
  UpsellMessageResearchCodeCard,
} from 'src/components/FlatAppearance/components/UpsellMessageResearchCodeCard';
import { useSession } from 'src/hooks';

interface ThreadCodeCardProps {
  codeCard?: CodeCard;
  isStreaming?: boolean;
}
export const ThreadCodeCard = ({
  isStreaming,
  codeCard,
}: ThreadCodeCardProps) => {
  const { isOpenTier } = useSession();
  const containerRef = useRef<HTMLDivElement>(null);

  if (!codeCard) {
    return null;
  }

  // TODO(olha): needs refactoring, should be enough or ThreadCodeSources or CodeSource component
  return (
    <div className="nj-thread-code-card" ref={containerRef}>
      <ThreadCodeSources
        codeSnippets={codeCard.data?.code_snippets}
        isStreaming={isStreaming}
      />
      {isOpenTier && (
        <UpsellMessageResearchCodeCard cardType={UpsellMessageCardType.CODER} />
      )}
    </div>
  );
};
