import { useState, useEffect } from 'react';
import { useFormContext, UseFieldArrayAppend } from 'react-hook-form';
import { PlusCircle } from '@phosphor-icons/react';
import { Attendee } from 'src/types';
import { useMeetingCardFieldsWatch } from '../../../../hooks/useMeetingCardFieldsWatch';
import { useSearchContacts } from '../../../../hooks/useSearchContacts';
import type { MeetingCardFormData } from '../../../../CreateMeetingCard';
import { AttendeePicker } from '../AttendeePicker';
import { AttendeeInput } from '../AttendeeInput';

const SVG_SIZE = 24;

interface AddNewAttendeeFormProps {
  append: UseFieldArrayAppend<MeetingCardFormData, 'attendees'>;
}
// TODO(olha): component is related to QuickContactPicker. Should be created a common component for them
export const AddNewAttendeeForm = ({ append }: AddNewAttendeeFormProps) => {
  const {
    setValue,
    clearErrors,
    formState: { errors },
    setFocus,
  } = useFormContext<MeetingCardFormData>();
  const { executed, new_attendee } =
    useMeetingCardFieldsWatch<MeetingCardFormData>();

  const [isOpenPicker, setOpenPicker] = useState<boolean>(false);
  const [shouldShowError, setShowError] = useState<boolean>(false);

  const { contacts, onSearchContacts } = useSearchContacts();

  useEffect(() => {
    if (!errors?.new_attendee) {
      setShowError(false);
    }
  }, [errors.new_attendee]);

  useEffect(() => {
    if (new_attendee?.length === 1 && !isOpenPicker) {
      setOpenPicker(true);
    }
  }, [new_attendee, new_attendee?.length, isOpenPicker]);

  const handleAttendeeAppend = (attendee: Attendee) => {
    append(attendee);
    setValue('new_attendee', '');
    setFocus('new_attendee');
    clearErrors('new_attendee');
  };

  const handleAttendeeSubmit = () => {
    if (!new_attendee) {
      return;
    }

    if (!!errors?.new_attendee) {
      setShowError(true);
      return;
    }

    if (!isOpenPicker || contacts.length === 0) {
      handleAttendeeAppend({ email: new_attendee });
    }
  };

  const handlePickerOpen = () => {
    setOpenPicker(true);
  };

  const handlePickerClose = () => {
    setOpenPicker(false);
  };

  if (executed) {
    return null;
  }

  return (
    <div
      onFocus={handlePickerOpen}
      onBlur={handlePickerClose}
      className="nj-create-meeting-card--attendee-input-wrapper"
    >
      <AttendeePicker
        query={new_attendee}
        isOpen={isOpenPicker && contacts.length > 0}
        contacts={contacts}
        onClose={handlePickerClose}
        onSelect={handleAttendeeAppend}
        shouldDisablePickerSubmit={!isOpenPicker}
      />

      <AttendeeInput
        onSearchContacts={onSearchContacts}
        onAttendeeSubmit={handleAttendeeSubmit}
        isError={shouldShowError}
        shouldDisableInputSubmit={isOpenPicker && contacts.length > 0}
      />

      <button
        type="button"
        disabled={!new_attendee}
        onClick={handleAttendeeSubmit}
        className="nj-button nj-create-meeting-card--attendee-add-button"
      >
        <PlusCircle weight="fill" size={SVG_SIZE} />
      </button>
    </div>
  );
};
