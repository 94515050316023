import dayjs from 'dayjs';
import {
  PaymentPlanOption,
  PaymentPlanOptionWithSale,
  PaymentsActivityColumn,
  TaskSkill,
} from 'src/types';
import { getReadableTaskSkillName } from 'src/utils';

export const FREE_CREDITS = 10;

// TODO(olha): use BE data, remove hardcode
export const PLAN_OPTIONS: PaymentPlanOptionWithSale[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    isDefault: true,
    newAmount: 0.99,
    promotion_code: 'NINJA90',
    salePercentage: 90,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
    newAmount: 10,
    promotion_code: 'MYNINJA10',
    salePercentage: 50,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
    newAmount: 20,
    promotion_code: 'MYNINJA10',
    salePercentage: 33,
  },
];

export const RENEW_EARLY_MONTHLY_OPTIONS: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    tasksCount: 600,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
    tasksCount: 1200,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
    tasksCount: 1800,
  },
];

export const RENEW_EARLY_ANNUALLY_OPTIONS: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_annually_84',
    amount: 8400,
    tasksCount: 24000,
    isAnnual: true,
  },
  {
    lookup_key: 'pro_annually_168',
    amount: 16800,
    tasksCount: 48000,
    isAnnual: true,
  },
  {
    lookup_key: 'pro_annually_252',
    amount: 25200,
    tasksCount: 72000,
    isAnnual: true,
  },
];

export const PLAN_FREE_FEATURES = [
  { title: 'Ninja Advisor', withBorder: false },
  { title: 'Ninja Coder', withBorder: false },
  { title: 'Basic Scheduler', withBorder: false },
  { title: 'Real time Web Search', withBorder: false },
  { title: 'Limited third-party LLM access', withBorder: true },
  { title: '5 minute video chat sessions', withBorder: false },
  { title: 'Limited avatars and tone customizations', withBorder: false },
  { title: 'Email & In-product support', withBorder: false },
];

export const PLAN_PRO_FEATURES = [
  { title: '**Multi-party** Scheduler', withBorder: false },
  { title: '**Ninja Deep Research**', withBorder: false },
  { title: '**Premium** third-party LLM access', withBorder: false },
  { title: 'Fastest response times', withBorder: false },
  { title: 'Priority tasks queue', withBorder: true },
  { title: '30 min video chat sessions', withBorder: false },
  { title: 'Video chat priority queues', withBorder: false },
  { title: '20 avatar selections', withBorder: false },
  { title: 'Priority email & In-product support', withBorder: false },
];

export const ACTIVITY_COLUMNS: PaymentsActivityColumn[] = [
  {
    header: 'Skill',
    accessor: 'skill',
    format: (skill: number | string) =>
      getReadableTaskSkillName(skill as TaskSkill),
  },
  { header: 'Task Name', accessor: 'task_hash' },
  {
    header: 'Date',
    accessor: 'timestamp',
    format: (date: number | string) => dayjs(date).format(PAYMENTS_DATE_FORMAT),
  },
  {
    header: 'Credits',
    accessor: 'cost',
    format: (value: number | string) => `$${Number(value).toFixed(2)}`,
  },
];

export const PAYMENTS_DATE_FORMAT = 'MMMM D, YYYY';
