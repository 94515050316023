import { Modal } from 'react-responsive-modal';
import React, { useContext } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import atlasWithSkills from 'src/images/login/atlas-with-skills.png';
import { useSession } from 'src/hooks';

type UpdatesModalProps = {
  isOpen: boolean;
};

export const UpdatesModal = ({ isOpen }: UpdatesModalProps) => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const {
    appUser: { metadata },
  } = useSession();

  const handleUpdateAndRefreshClick = () => {
    window.location.reload();
  };

  return (
    <Modal
      open={isOpen}
      onClose={
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      }
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access nj-updates-modal',
      }}
      showCloseIcon={false}
      center
    >
      <div className="nj-updates-modal-content">
        <div className="nj-updates-modal-content-background-image-wrapper">
          <img
            className="nj-updates-modal-content-header-background-image"
            src={atlasWithSkills}
            alt="Ninja"
          />
        </div>
        <h2 className="nj-updates-modal-content-title">
          New version {metadata?.app_version} is available
        </h2>
        <footer className="nj-updates-modal-footer">
          <button
            className="nj-updates-modal-button"
            onClick={handleUpdateAndRefreshClick}
          >
            Update and refresh
          </button>
        </footer>
      </div>
    </Modal>
  );
};
