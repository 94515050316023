import { useContext, useState, useRef } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import {
  useDelegateSpeechToAvatar,
  useThreads,
  useBreakpoint,
  useTheme,
} from 'src/hooks';
import { RandomPillars } from 'src/pages/ManageTasksChatPage/components/SkillPillars';
import { ThreadMessageList } from '../ThreadMessageList';
import { ThreadInputBox } from '../ThreadInputBox';
import { GreetingTitle } from 'src/pages/ManageTasksChatPage/components/GreetingTitle';
import { ANIMATION_TIMEOUT } from 'src/constants';

interface ThreadViewProps {
  startSpeechRecognizing: () => void;
  stopSpeechRecognizing: () => void;
  unMuteMicrophone: () => void;
}

export const ThreadView = ({
  startSpeechRecognizing,
  stopSpeechRecognizing,
  unMuteMicrophone,
}: ThreadViewProps) => {
  const { expandedMeta } = useContext(AvatarModeContext);
  const { isSettingsPanelExpanded } = useTheme();
  const { shouldShowPillars } = useThreads();
  const { isTabletAndUp } = useBreakpoint();
  const inputBoxRef = useRef<HTMLDivElement>(null);

  useDelegateSpeechToAvatar();

  const isFullscreenEnabled = Boolean(document.fullscreenElement);

  const [animationInProgress, setAnimationInProgress] = useState(false);

  const handleAnimationStart = () => {
    setAnimationInProgress(true);
  };

  const handleAnimationEnd = () => {
    setAnimationInProgress(false);
  };

  const rootClasses = classNames('nj-thread-view', {
    'is-settings-panel-expanded': isSettingsPanelExpanded,
    'expanded-meta': expandedMeta,
    fullscreen: isFullscreenEnabled,
  });

  return (
    <div className={rootClasses}>
      {!shouldShowPillars && (
        <div className="nj-thread-view--chat">
          <ThreadMessageList />
        </div>
      )}

      <CSSTransition
        in={!shouldShowPillars}
        timeout={ANIMATION_TIMEOUT}
        classNames="nj-animate-input-box-transition"
        onEnter={handleAnimationStart}
        onEntered={handleAnimationEnd}
        nodeRef={inputBoxRef}
      >
        <div
          className={classNames('nj-thread-view--landing-page-wrapper', {
            'align-bottom': !shouldShowPillars && !animationInProgress,
            'align-center': shouldShowPillars,
          })}
          ref={inputBoxRef}
        >
          {shouldShowPillars && <GreetingTitle />}

          <ThreadInputBox
            startSpeechRecognizing={startSpeechRecognizing}
            stopSpeechRecognizing={stopSpeechRecognizing}
            unMuteMicrophone={unMuteMicrophone}
          />

          {shouldShowPillars && <RandomPillars />}
        </div>
      </CSSTransition>

      <p className="nj-thread-view--description">
        {`Ninja is currently in beta and can make mistakes.${
          isTabletAndUp
            ? ' Please do not use it for mission critical tasks.'
            : ''
        }`}
      </p>
    </div>
  );
};
