import { useMemo } from 'react';
import { Checkbox } from 'src/components/Checkbox';
import { Select } from 'src/components/Select';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSession, useUserData } from 'src/hooks';
import dayjs from 'dayjs';

type TimeZoneOption = {
  value: string;
  label: string;
  time: string;
};

// TODO replace metadata in commented part
export const GeneralSettings = () => {
  const { appUser } = useSession();
  const { control, setValue } = useFormContext();

  const fieldValues = useWatch({ control });
  const { updateUserData } = useUserData();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore todo remove with "typescript" update - supportedValuesOf supported by TS version >= 5.1
  const listOfTimezones = Intl.supportedValuesOf('timeZone');

  const sortedListOfTimezones = useMemo(() => {
    return listOfTimezones
      .map((item: string) => ({
        label: `${item} ${new Date().toLocaleTimeString('en-US', {
          timeZone: item,
          hour12: true,
          timeStyle: 'short',
        })}`,
        value: item,
        time: new Date().toLocaleString('en-US', { timeZone: item }),
      }))
      .slice()
      .sort((a: TimeZoneOption, b: TimeZoneOption) =>
        dayjs(a.time).isAfter(dayjs(b.time)) ? 1 : -1,
      );
  }, [listOfTimezones]);

  const handleChangeCheckboxValue = (value: boolean) => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setValue('timezone', currentTimezone);
    updateUserData(
      {
        settings: {
          ...appUser.settings,
          timezone_setting: {
            timezone: currentTimezone,
            is_automatically_timezone: value,
          },
        },
      },
      false,
    );
  };

  return (
    <div className="nj-settings-tab-content visible-dropdown">
      <h4 className="nj-settings-tab-content-title">Time Zone</h4>
      <div className="nj-settings-tab-content--general-select-wrapper">
        <Checkbox
          label={
            <span className="nj-settings-tab-content--label-with-icon">
              <span>Set timezone automatically</span>
            </span>
          }
          name="is_automatically_timezone"
          onChangeHandler={handleChangeCheckboxValue}
        />
      </div>
      <div className="nj-settings-tab-content--form-field">
        <label className="nj-settings-tab-content--form-label">
          Current location Time
        </label>
        <div className="nj-settings-tab-content--general-select-wrapper">
          <Select<string>
            aria-label="location-time"
            name="timezone"
            isSearchable
            isDisabled={fieldValues.is_automatically_timezone}
            options={sortedListOfTimezones}
            onChangeHandler={(value) =>
              updateUserData({
                settings: {
                  ...appUser.settings,
                  timezone_setting: {
                    timezone: value,
                    is_automatically_timezone: false,
                  },
                },
              })
            }
          />
        </div>
      </div>

      <hr className="divider" />
    </div>
  );
};
