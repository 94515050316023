import { useCollapse } from 'react-collapsed';
import styles from './CollapsibleTitle.module.scss';

type CollapsibleTitleProps = {
  query?: string;
  children?: JSX.Element;
};

export const CollapsibleTitle = ({ query = '', children }: CollapsibleTitleProps) => {
  const { getToggleProps, isExpanded } = useCollapse();

  const text = children?.props.content || query;

  const TEXT_MAX_SIZE = 148;
  const longText = text.length > TEXT_MAX_SIZE;
  const slicedText = `${text.slice(0, TEXT_MAX_SIZE).trim()}...`;

  return (
    <>
      {longText && !isExpanded ? slicedText : text}
      {longText && (
        <button type="button" className={styles.button} {...getToggleProps()}>
          {isExpanded ? (
            <span className={styles.text}>{'View less'}</span>
          ) : (
            <span className={styles.text}>{'View more'}</span>
          )}
        </button>
      )}
    </>
  );
};
