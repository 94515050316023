import { useGoogleResourcesAccess } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import { CheckCircle } from '@phosphor-icons/react';
import GoogleLogo from 'src/images/logos/google-logo.svg';
import React from 'react';
import { useLogout } from 'src/hooks/useLogout';
import { useCanConnectToGoogle } from 'src/hooks/useCanConnectToGoogle';

const SVG_SIZE = 24;

export const GoogleAccess = () => {
  const {
    connectAll,
    canAccessCalendar,
    canAccessContacts,
    canAccessDirectory,
  } = useGoogleResourcesAccess();
  const isAllConnected =
    canAccessCalendar && canAccessContacts && canAccessDirectory;

  const { logoutWithRedirectToSignUp } = useLogout();

  const canConnectToGoogle = useCanConnectToGoogle();

  const accessData = [
    {
      title: 'Calendar',
      iconType: 'calendar',
      isConnected: canAccessCalendar,
      iconSize: 24,
    },
    {
      title: 'Contacts',
      iconType: 'contact',
      isConnected: canAccessContacts,
      iconSize: 20,
    },
    {
      title: 'Directory',
      iconType: 'contact',
      isConnected: canAccessDirectory,
      iconSize: 20,
    },
  ];

  return (
    <>
      <div className="nj-settings-tab-content--form-field">
        <p className="nj-settings-tab-content--access-label">
          <span className="nj-settings-tab-content--access-title">
            Calendar, Contacts and Directory Access
          </span>
          <span className="nj-settings-tab-content--access-label-subtitle">
            {canConnectToGoogle
              ? 'Allow Ninja to access your calendar and contacts to schedule event.'
              : 'Currently, Google SSO login is needed to connect your calendar and contacts.'}
          </span>
        </p>

        {!isAllConnected && (
          <div className="nj-settings-tab-content--access-button-wrapper">
            {canConnectToGoogle ? (
              <button
                onClick={connectAll}
                className="nj-settings-tab-content--access-button"
                data-e2e="connect-all-button"
              >
                Connect
              </button>
            ) : (
              <button
                className="nj-auth-sign-in-up-button nj-google-button"
                onClick={logoutWithRedirectToSignUp}
              >
                <div className="nj-google-button-state"></div>
                <div className="nj-google-button-content-wrapper">
                  <div>
                    <img
                      className="nj-auth-sign-in-up-button-logo"
                      src={GoogleLogo}
                      alt="Google"
                    />
                  </div>
                  <span className="nj-google-button-contents">
                    Sign up with Google
                  </span>
                  <span style={{ display: 'none' }}>Sign up with Google</span>
                </div>
              </button>
            )}
          </div>
        )}
      </div>

      <div className="nj-settings-tab-content--access-items-wrapper">
        {accessData.map((item) => (
          <div className="nj-settings-tab-content--form-field" key={item.title}>
            <span className="nj-settings-tab-content--access-label-wrapper">
              <Icon type={item.iconType} size={item.iconSize} />

              <span className="nj-settings-tab-content--access-label">
                <span className="nj-settings-tab-content--access-label-title">
                  {item.title}
                </span>
              </span>
            </span>
            <span className="nj-settings-tab-content--access-state">
              {item.isConnected ? (
                <CheckCircle size={SVG_SIZE} weight="fill" />
              ) : (
                'Not connected'
              )}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default GoogleAccess;
