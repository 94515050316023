import {
  ChitChatCard,
  CodeTaskCreationCard,
  ConversationRole,
  Message,
  MessageType,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
} from 'src/types';
import { ThreadMessageContent } from '../ThreadMessageContent';
import { ThreadCombinedCard } from '../ThreadCombinedCard';
import { ThreadResearchCard } from '../ThreadResearchCard';
import { ThreadScheduleCard } from '../ThreadScheduleCard';
import { DebugMessageWrapper } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/DebugMessageWrapper';
import { ThreadCodeCard } from 'src/components/FlatAppearance/components/ThreadCodeCard';
import { ThreadErrorMessageContent } from 'src/components/FlatAppearance/components/ThreadErrorMessageContent';
import { ErrorPayload } from 'src/types/models/ErrorPayload';
import log from 'src/utils/logger';
import { ThreadChitChatCard } from '../ThreadChitChatCard';
import { ThreadImageGenerationCard } from 'src/components/FlatAppearance/components/ThreadImageGenerationCard';
import { ImageCard } from 'src/types/models/ImageCard';

import { CollapsibleTitle } from 'src/components/CollapsibleTitle';
import {
  FileCsv,
  FileDoc,
  FilePdf,
  FileText,
  FileTxt,
  FileXls,
} from '@phosphor-icons/react';

const fileRegex = /---\[([^=]+)=([^ ]+) ([^=]+)=([^ ]+)\]---/;
const SVG_SIZE = 20;

interface ThreadMessageBodyProps {
  message: Message;
}

export const ThreadMessageBody = ({ message }: ThreadMessageBodyProps) => {
  const { message_type, payload, is_final_answer, role } = message;

  const shouldStreaming = !is_final_answer && role !== ConversationRole.USER;

  const isContentWithFile =
    role === ConversationRole.USER &&
    message.content.length > 0 &&
    !!message.content.match(fileRegex) &&
    (message.content.match(fileRegex) || []).length >= 4;

  const originalFilename =
    message.content.match(fileRegex) !== null
      ? (message.content.match(fileRegex) || [])[2]
      : '';

  // const convertedFilename =
  //   message.content.match(fileRegex) !== null
  //     ? (message.content.match(fileRegex) || [])[4]
  //     : '';

  const newText = message.content.replace(fileRegex, '');

  const handleFileClick = () => {
    // todo
  };

  const renderExceptionIcon = (type: string) => {
    switch (type) {
      case 'pdf':
        return <FilePdf size={SVG_SIZE} />;
      case 'txt':
        return <FileTxt size={SVG_SIZE} />;
      case 'docx':
        return <FileDoc size={SVG_SIZE} />;
      case 'csv':
        return <FileCsv size={SVG_SIZE} />;
      case 'xls':
        return <FileXls size={SVG_SIZE} />;
      default:
        return <FileText size={SVG_SIZE} />;
    }
  };

  const getCardToDisplay = () => {
    switch (message_type) {
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        if (!payload) {
          log.warn('empty payload in the message', message);
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ResearchTaskCreationCard}>
            <ThreadResearchCard
              researchCard={(payload as ResearchTaskCreationCard).research_card}
              // TODO(olha): this is a workaround! needs to change condition and moves to child components to avoid chain of props!!!
              isStreaming={shouldStreaming}
              originalQuery={message.clean_query}
            />
          </ThreadCombinedCard>
        );
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        if (!payload) {
          log.warn('empty payload in the message', message);
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as SchedulerCreationCard}>
            <ThreadScheduleCard
              calendarCard={(payload as SchedulerCreationCard).calendar_card}
              details={(payload as SchedulerCreationCard).details}
              //TODO(olha): add prop from BE
              summary=""
            />
          </ThreadCombinedCard>
        );
      case MessageType.CODE_TASK_CREATION_CARD:
        if (!payload) {
          log.warn('empty payload in the message', message);
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ResearchTaskCreationCard}>
            <ThreadCodeCard
              codeCard={(payload as CodeTaskCreationCard).code_card}
              isStreaming={shouldStreaming}
            />
          </ThreadCombinedCard>
        );
      case MessageType.ERROR_MESSAGE:
        return (
          <ThreadErrorMessageContent
            content={message.content}
            errorCode={(payload as ErrorPayload).error_code}
            unsupportedSkills={(payload as ErrorPayload).unsupported_skills}
          />
        );
      case MessageType.CHAT_CARD:
        if (!payload) {
          return null;
        }
        return (
          <ThreadCombinedCard>
            <ThreadChitChatCard
              chitChatCard={payload as ChitChatCard}
              isStreaming={shouldStreaming}
              originalQuery={message.clean_query}
            />
          </ThreadCombinedCard>
        );
      case MessageType.IMAGE_CARD:
        if (!payload) {
          return null;
        }
        return (
          <ThreadCombinedCard>
            <ThreadImageGenerationCard imageCard={payload as ImageCard} />
          </ThreadCombinedCard>
        );
      default:
        return isContentWithFile ? (
          <div className="nj-thread-message--content-with-file-wrapper">
            <CollapsibleTitle>
              <ThreadMessageContent
                content={newText}
                isStreaming={shouldStreaming}
              />
            </CollapsibleTitle>
            {!!originalFilename && (
              <button
                type="button"
                onClick={handleFileClick}
                className=" nj-thread-message--content-with-file-wrapper--file-label"
              >
                <div className="nj-thread-message--content-with-file-wrapper--file-label-text">
                  {renderExceptionIcon(originalFilename.split('.')[1])}
                  <span className="nj-thread-message--content-with-file-wrapper--file-name ellipsis">
                    {originalFilename}
                  </span>
                </div>
              </button>
            )}
          </div>
        ) : (
          <CollapsibleTitle>
            <ThreadMessageContent
              content={message.content}
              isStreaming={shouldStreaming}
            />
          </CollapsibleTitle>
        );
    }
  };

  return (
    <DebugMessageWrapper message={message}>
      {getCardToDisplay()}
    </DebugMessageWrapper>
  );
};
