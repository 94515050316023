import cn from 'classnames';
import { useGetUserSubscriptionInfoQuery } from 'src/store/services';
import { useSession } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { UserTier } from 'src/types/models/UserTier';
import { UserTierStatus } from 'src/types';

interface RenewEarlyButtonProps {
  className?: string;
}

export const RenewEarlyButton = ({ className }: RenewEarlyButtonProps) => {
  const { appUser } = useSession();
  const { onChangeIsVisibleUpdatePlanModal } = useContext(SessionContext);
  const { data, isLoading: isSubscriptionInfoLoading } =
    useGetUserSubscriptionInfoQuery(
      {
        user_id: appUser.user_id,
      },
      { skip: appUser.tier_id !== UserTier.PAID },
    );

  if (isSubscriptionInfoLoading || data?.is_cancelled) {
    return null;
  }

  return (
    <button
      type="button"
      className={cn('nj-renew-early-button', className)}
      onClick={() => onChangeIsVisibleUpdatePlanModal(true)}
      disabled={
        !!appUser?.stripe_info?.renew_early_pending_subscription ||
        appUser?.tier_status === UserTierStatus.OK
      }
    >
      Add credits
    </button>
  );
};
