import { Modal } from 'src/components/Modal';
import { SignupForFreeButton } from 'src/components/SignupForFreeButton';

type CopyAndShareUpsellModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CopyAndShareUpsellModal = ({
  isOpen,
  onClose,
}: CopyAndShareUpsellModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className="nj-upsell-video-modal nj-upsell-copy-share-modal"
    >
      <div className="nj-upsell-video-modal-content">
        <h3 className="nj-upsell-video-modal-title">
          Sign up to copy & share!
        </h3>

        <p className="nj-upsell-video-modal-description">
          To copy or share your results, please sign up and create an account.
        </p>

        <div className="nj-upsell-video-modal-button-wrapper">
          <SignupForFreeButton />
        </div>
      </div>
    </Modal>
  );
};
