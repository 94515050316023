import cn from 'classnames';
import { Icon } from 'src/components/Icon';
import styles from './ExternalModelThumbnail.module.scss';

const SVG_SIZE = 20;

interface ExternalModelThumbnailProps {
  isActive: boolean;
  title: string;
  icon: string | null;
  disabled?: boolean;
  onClick: () => void;
}

export const ExternalModelThumbnail = ({
  isActive,
  title,
  icon,
  disabled,
  onClick,
}: ExternalModelThumbnailProps) => {
  return (
    <button
      className={cn(styles.root, { [styles.active]: isActive })}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && <Icon size={SVG_SIZE} type={icon} />}
      <span className={cn(styles.title, 'ellipsis')}>{title}</span>
    </button>
  );
};
