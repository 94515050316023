import { useContext } from 'react';
import { ArrowsCounterClockwise } from '@phosphor-icons/react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { Message } from 'src/types';

const SVG_SIZE = 20;

interface RegenerateMessageButtonProps {
  message: Message;
  disabled?: boolean;
}

export const RegenerateMessageButton = ({
  message,
  disabled,
}: RegenerateMessageButtonProps) => {
  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);

  const userInput = message?.original_query || '';

  const handleButtonClick = () => {
    setThreadInputBoxValue(userInput);
  };

  return (
    <button disabled={!userInput || disabled} onClick={handleButtonClick}>
      <ArrowsCounterClockwise size={SVG_SIZE} />
    </button>
  );
};
