import { useMemo, lazy, Suspense } from 'react';
import { Info } from '@phosphor-icons/react';
import { PlanSectionType } from 'src/types';
import { useBreakpoint } from 'src/hooks';
import './PackageDealDetails.scss';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

const SVG_SIZE = 20;

interface PackageDealDetailsProps {
  type: PlanSectionType;
  selectedAmount?: number;
}

export const PackageDealDetails = ({
  type,
  selectedAmount,
}: PackageDealDetailsProps) => {
  const { isTabletAndUp } = useBreakpoint();
  const isPro = type === 'pro';

  const tooltipId = `package-deal-tooltip-${type}`;

  const advisorValue = useMemo(() => {
    switch (selectedAmount) {
      case 1000:
        return '2,000';

      case 2000:
        return '4,400';

      case 3000:
        return '6,900';

      default:
        return '600 (20 per day)';
    }
  }, [selectedAmount]);

  const coderValue = useMemo(() => {
    switch (selectedAmount) {
      case 1000:
        return '2,000';

      case 2000:
        return '4,400';

      case 3000:
        return '6,900';

      default:
        return '600 (20 per day)';
    }
  }, [selectedAmount]);

  const researcherValue = useMemo(() => {
    switch (selectedAmount) {
      case 1000:
        return '600';

      case 2000:
        return '1,320';

      case 3000:
        return '2,070';

      default:
        return '600 (20 per day)';
    }
  }, [selectedAmount]);

  const thirdPartyValue = useMemo(() => {
    switch (selectedAmount) {
      case 1000:
        return '2,000';

      case 2000:
        return '4,400';

      case 3000:
        return '6,900';

      default:
        return '600 (20 per day)';
    }
  }, [selectedAmount]);

  const schedulerValue = useMemo(() => {
    switch (selectedAmount) {
      case 1000:
        return '150';

      case 2000:
        return '330';

      case 3000:
        return '518';

      default:
        return '150 (5 per day)';
    }
  }, [selectedAmount]);

  return (
    <>
      <button data-tooltip-id={tooltipId}>
        <Info size={SVG_SIZE} />
      </button>

      <Suspense>
        <LazyReactTooltip
          id={tooltipId}
          noArrow
          className="nj-package-deal-details--tooltip"
          opacity={1}
          place={isTabletAndUp ? 'bottom-end' : 'top'}
        >
          <div className="nj-package-deal-details--content">
            <p className="nj-package-deal-details--content-text-field nj-package-deal-details--content-title">
              Estimated task amount
            </p>

            <p className="nj-package-deal-details--content-text-field">
              <span>Ninja Agents OR 3P LLMs</span>
              <span>Amount up to</span>
            </p>

            <hr className="divider" />

            <p className="nj-package-deal-details--content-value-field">
              <span>Researcher</span>
              <span>{researcherValue}</span>
            </p>

            <hr className="divider dashed" />

            <p className="nj-package-deal-details--content-value-field">
              <span>Scheduler {isPro ? '(Advanced)' : '(Basic)'} </span>
              <span>{schedulerValue}</span>
            </p>

            <hr className="divider dashed" />

            <p className="nj-package-deal-details--content-value-field">
              <span>Coder</span>
              <span>{coderValue}</span>
            </p>

            <hr className="divider dashed" />

            <p className="nj-package-deal-details--content-value-field">
              <span>Advisor</span>
              <span>{advisorValue}</span>
            </p>

            <hr className="divider dashed" />

            <p className="nj-package-deal-details--content-value-field">
              <span>{isPro ? 'Premium' : 'Limited'} 3P LLMs</span>
              <span>{thirdPartyValue}</span>
            </p>

            <hr className="divider dashed" />

            <p className="nj-package-deal-details--content-text-field">
              {isPro
                ? 'Estimates assume full credit utilization for each specific AI Agent tasks. Actual values vary based on task complexity. Rate limits apply.'
                : 'Rate limits apply.'}
            </p>
          </div>
        </LazyReactTooltip>
      </Suspense>
    </>
  );
};
