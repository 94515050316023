import { useSession, useBreakpoint } from 'src/hooks';
import { TooltipDesktop } from './TooltipDesktop';
import { TooltipMobile } from './TooltipMobile';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';


export const PromoTooltip = () => {
  const { isProTier, isFreeTier, isOpenTier } = useSession();
  const { isMobileOrTablet } = useBreakpoint();

  if (isProTier) {
    return null;
  }

  if (isMobileOrTablet) {
    return (
      <TooltipMobile>
        {isFreeTier && <UpgradeButton title="Upgrade to Ninja Pro" />}
        {isOpenTier && <GuestAuthLinks />}
      </TooltipMobile>
    );
  }

  return (
    <TooltipDesktop>
      {isFreeTier && <UpgradeButton />}
      {isOpenTier && <GuestAuthLinks />}
    </TooltipDesktop>
  );
};
