import {
  NinjaSearchTypes,
  WebSearchTypes,
  WebSearchUserSettings,
} from 'src/types';
import { webSearchTypesList } from 'src/constants/llmResources';
import { Checkbox } from 'src/components/Checkbox';
import { LabelPro } from 'src/components/LabelPro';
import { LabelInsufficientCredits } from 'src/components/LabelInsufficientCredits';
import { LearnMoreUpsellLabel } from 'src/components/LearnMoreUpsellLabel';
import { UpsellVideoModal } from 'src/components/UpsellVideoModal';
import { FormProvider } from 'src/components/FormProvider';
import { useSession, useUserData, useVisible } from 'src/hooks';
import {
  useGetVideoLinksJSONQuery,
  useLazyGetUserByIdQuery,
} from 'src/store/services';
import { useForm } from 'react-hook-form';
import log from 'src/utils/logger';
import { ListMagnifyingGlass } from '@phosphor-icons/react';
import { AtomIcon } from 'src/images/icons/atom';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';

interface FormData {
  [NinjaSearchTypes.DEEP_RESEARCH]: boolean;
  [WebSearchTypes.IMAGE_SEARCH]: boolean;
  [WebSearchTypes.VIDEO_SEARCH]: boolean;
}

export const NinjaAgentsSection = () => {
  const {
    appUser: { settings, user_id },
    isFreeTier,
    isProTier,
    isOpenTier,
  } = useSession();

  const {
    isVisible: isShowUpsellModal,
    handleVisibilitySet: openUpsellModal,
    handleVisibilityRemove: closeUpsellModal,
  } = useVisible();

  const { updateUserSettings } = useUserData();

  const { data: videoLinks } = useGetVideoLinksJSONQuery();

  const [trigger] = useLazyGetUserByIdQuery();

  const methods = useForm<FormData>({
    defaultValues: async () => {
      const result = await trigger(user_id, true);
      const { data } = result;
      return {
        [NinjaSearchTypes.DEEP_RESEARCH]:
          data?.settings?.research?.deep_research?.enabled || false,
        [WebSearchTypes.IMAGE_SEARCH]:
          data?.settings?.research?.web_search?.search_images || false,
        [WebSearchTypes.VIDEO_SEARCH]:
          data?.settings?.research?.web_search?.search_videos || false,
      };
    },
  });

  const { setValue } = methods;

  const handleWebSearchSwitcher = async (
    checked: boolean,
    value: keyof WebSearchUserSettings | keyof WebSearchTypes,
  ) => {
    const research = {
      research: {
        ...(settings?.research || {}),
        web_search: {
          ...(settings?.research?.web_search || {}),
          [value]: checked,
        },
      },
    };

    try {
      await updateUserSettings(research);
    } catch (e) {
      setValue(
        value as WebSearchTypes.IMAGE_SEARCH | WebSearchTypes.VIDEO_SEARCH,
        !checked,
      );
      log.error(e);
    }
  };

  const handleDeepResearchSwitcher = async (checked: boolean) => {
    const research = {
      research: {
        ...(settings?.research || {}),
        deep_research: {
          ...settings?.research?.deep_research,
          enabled: checked,
        },
      },
    };

    try {
      await updateUserSettings(research);
    } catch (e) {
      setValue(NinjaSearchTypes.DEEP_RESEARCH, !checked);
      log.error(e);
    }
  };

  const handleGTMEventClick = () => {
    sendGTMEvent(GTMEvent.NINJA_DEEP_RESEARCH_CLICK);
  }

  return (
    <div className="nj-ninja-agents-section-wrapper">
      <h6 className="nj-section--main-container-subtitle-secondary">
        Ninja's proprietary models are trained using <br /> Llama 3 70B as a
        base
      </h6>
      <div className="nj-section--main-container with-padding">
        {(isFreeTier || isOpenTier) && (
          <UpsellMessage description="Get access to Ninja Deep Research" />
        )}
        <FormProvider<FormData> methods={methods}>
          <div className="nj-researcher-form-wrapper">
            <div className="nj-section--main-container-title-form">
              <h5 className="nj-section--main-container-title-secondary">
                <ListMagnifyingGlass size={24} />
                <span>Researcher</span>
              </h5>
            </div>
            <h6 className="nj-section--main-container-subtitle">
              Related videos & images
            </h6>
            <div className="nj-web-research-content-form-wrapper">
              {webSearchTypesList.map(({ value, label }) => {
                return (
                  <div
                    key={value}
                    className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper"
                  >
                    <Checkbox
                      label={
                        <p className="nj-section--field-title">
                          <span>{label}</span>
                        </p>
                      }
                      name={value}
                      onChangeHandler={(checked: boolean) =>
                        handleWebSearchSwitcher(
                          checked,
                          value as keyof WebSearchUserSettings,
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <hr className="divider no-margin" />
          <div>
            <div className="nj-section--field-wrapper nj-web-research-deep-research-wrapper">
              <div className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper">
                <Checkbox
                  label={
                    <div className="nj-section--field-title">
                      <div className="nj-section--field-title-wrapper">
                        <div className="nj-section--field-title-wrapper-row">
                          <span className="nj-section--field-title-text">
                            Ninja Deep Research
                          </span>
                          <div className="nj-section--field-icon-wrapper">
                            <AtomIcon />
                          </div>
                          {isFreeTier &&
                            !settings?.research?.deep_research?.is_capable && (
                              <LabelPro />
                            )}
                          {isProTier &&
                            !settings?.research?.deep_research?.is_capable && (
                              <LabelInsufficientCredits />
                            )}
                        </div>
                        <span className="nj-section--field-title-description">
                          Multi-hop advanced research
                        </span>
                      </div>
                    </div>
                  }
                  disabled={!settings?.research?.deep_research?.is_capable}
                  name={NinjaSearchTypes.DEEP_RESEARCH}
                  onChangeHandler={(checked: boolean) => {

                      handleDeepResearchSwitcher(checked)
                      handleGTMEventClick()
                    }
                  }
                />
              </div>
            </div>
            {isFreeTier && !settings?.research?.deep_research?.is_capable && (
              <div className="nj-deep-research-upsell-message-wrapper">
                <LearnMoreUpsellLabel onClick={openUpsellModal} />
                <UpsellVideoModal
                  videoUrl={videoLinks?.deepResearch || ''}
                  title="Ninja's Deep Researcher Agent"
                  description="Upgrade to PRO to get access to Ninja’s advanced multi-hop researcher."
                  isOpen={isShowUpsellModal}
                  onClose={closeUpsellModal}
                />
              </div>
            )}
          </div>
          <hr className="divider no-margin" />
        </FormProvider>
      </div>
    </div>
  );
};
