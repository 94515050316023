import {
  LanguageButton,
  CameraAngleButton,
  EnvironmentSelectorButton,
  SettingsButton,
  SignOutButton,
  SupportButton,
} from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons';
import styles from './UserMenuTooltip.module.scss';
import { useSession } from 'src/hooks';
import { ChatMode } from 'src/types';
import { UserMenuMainInfo } from '../UserMenuMainInfo';
import { WalletSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { ThemeSelector } from '../ThemeSelector';
import { AccountActivityLink } from '../AccountActivityLink';

export const UserMenuTooltip = () => {
  const { chatMode, isOpenTier } = useSession();

  return (
    <div className={styles.root}>
      <UserMenuMainInfo />

      <div className={styles.walletWrapper}>
        <WalletSection />
      </div>

      {chatMode === ChatMode.AVATAR && (
        <>
          <LanguageButton />
          <EnvironmentSelectorButton />
          <CameraAngleButton />

          <hr className="divider" />
        </>
      )}

      <SettingsButton />

      <ThemeSelector />

      <SupportButton />

      <AccountActivityLink />

      {isOpenTier ? (
        <GuestAuthLinks className={styles.authLinks} />
      ) : (
        <SignOutButton />
      )}
    </div>
  );
};
