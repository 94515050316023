import { useSession } from 'src/hooks';
import { TiersAuthFooter } from 'src/pages/AuthPages/components/TiersAuthFooter';

export const Footer = () => {
  const { removeCurrentTaskId } = useSession();

  return (
    <div className="nj-account-bar" onClick={removeCurrentTaskId}>
      <TiersAuthFooter />
    </div>
  );
};
