import { FormProvider } from 'src/components/FormProvider';
import { useForm } from 'react-hook-form';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import { useSession, useUserData } from 'src/hooks';
import './ImageGeneratorsSection.scss';
import { convertToValueWithoutDots } from 'src/utils';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { Icon } from 'src/components/Icon';
import { Checkbox } from 'src/components/Checkbox';
import { LabelInsufficientCredits } from 'src/components/LabelInsufficientCredits';
import { LabelPro } from 'src/components/LabelPro';
import {
  ImageGeneratorsModelProvider,
  imageGeneratorsModelsSet,
  newImageGeneratorModels,
} from 'src/constants';
import { LabelNew } from 'src/components/LabelNew';
import type { shared__models__user_settings__ImageGenSettingsOption } from 'src/types/models/shared__models__user_settings__ImageGenSettingsOption';
import log from 'src/utils/logger';

const SVG_SIZE = 20;

export const ImageGeneratorsSection = () => {
  const { appUser, isFreeTier, isProTier, isOpenTier } = useSession();
  const [trigger] = useLazyGetUserByIdQuery();
  const { updateUserSettings } = useUserData();

  const methods = useForm({
    defaultValues: async () => {
      const result = await trigger(appUser.user_id, true);
      const { data } = result;

      return (data?.settings?.image_gen_settings?.models || []).reduce(
        (acc, current) => {
          acc[convertToValueWithoutDots(current.model)] = current.enabled;
          return acc;
        },
        {} as Record<string, boolean | undefined>,
      );
    },
  });

  const bedrockModels = (
    appUser?.settings?.image_gen_settings?.models || []
  ).filter(
    (item) => item.model.split('/')[0] === ImageGeneratorsModelProvider.BEDROCK,
  );

  const openaiModels = (
    appUser?.settings?.image_gen_settings?.models || []
  ).filter(
    (item) => item.model.split('/')[0] === ImageGeneratorsModelProvider.OPENAI,
  );

  const handleValueChange = async (checked: boolean, modelName: string) => {
    const updatedModelList = (
      appUser?.settings?.image_gen_settings?.models || []
    ).map((item) => {
      if (item?.model === modelName) {
        return {
          ...item,
          enabled: checked,
        };
      }
      return item;
    });

    const image_gen_settings = {
      image_gen_settings: {
        ...appUser?.settings?.image_gen_settings,
        models: updatedModelList,
      },
    };

    try {
      await updateUserSettings(image_gen_settings, 'Preferred model saved');
    } catch (e) {
      log.error(e);
    }
  };

  const renderModelsCheckboxes = (
    modelsList: Array<shared__models__user_settings__ImageGenSettingsOption>,
  ) =>
    modelsList.map(({ model, is_capable }, index) => {
      const currentModelObj = imageGeneratorsModelsSet[model];
      return (
        <div
          key={`${model}_${index}`}
          className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper"
        >
          <Checkbox
            label={
              <div className="nj-section--field-title-wrapper">
                <p className="nj-section--field-title">
                  <span>{currentModelObj.display_name || model}</span>
                  {currentModelObj.icon_name &&
                    !currentModelObj.hide_icon_in_settings && (
                      <Icon type={currentModelObj.icon_name} size={SVG_SIZE} />
                    )}
                </p>
                {isProTier && !is_capable && <LabelInsufficientCredits />}
                {isFreeTier && !is_capable && <LabelPro />}
                {!isOpenTier &&
                  is_capable &&
                  newImageGeneratorModels.includes(model) && <LabelNew />}
              </div>
            }
            name={convertToValueWithoutDots(model)}
            onChangeHandler={(checked: boolean) => {
              handleValueChange(checked, model);
            }}
            disabled={!is_capable}
          />
        </div>
      );
    });

  return (
    <div className="nj-section--main-container with-padding nj-generated-images">
      {!isProTier && (
        <UpsellMessage description="Get access to Stable Diffusion, Amazon Titan & DALL-E 3 models" />
      )}
      <FormProvider methods={methods}>
        <div className="nj-external-models-form-wrapper">
          <h5 className="nj-section--main-container-title">
            Model(s) for generating image
          </h5>
          <h6 className="nj-section--main-container-subtitle">
            Select at least 1 model. If no model is selected, the default will
            be Amazon Titan.
          </h6>
        </div>
        <div className="nj-generated-images--label-content-wrapper">
          <Icon size={SVG_SIZE} type="awsLogo" />
          <span className="nj-generated-images--label-content-text">
            Amazon Bedrock
          </span>
        </div>
        {renderModelsCheckboxes(bedrockModels)}
        <hr className="divider no-margin" />
        <div className="nj-generated-images--label-content-wrapper">
          <Icon size={SVG_SIZE} type="chatGPTLogo" />
          <span className="nj-generated-images--label-content-text">
            OpenAI
          </span>
        </div>
        {renderModelsCheckboxes(openaiModels)}
      </FormProvider>
    </div>
  );
};
