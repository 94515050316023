import { useMemo } from 'react';
import { Frequency } from 'rrule';
import dayjs from 'dayjs';
import { useMeetingCardFieldsWatch } from '../../hooks/useMeetingCardFieldsWatch';
import {
  convertRruleOptionsToRruleString,
  convertRruleStringToReadableText,
} from 'src/utils';
import { useTimeZone } from 'src/hooks';
import { DATE_RANGE_STRING_FORMAT } from '../../constants/defaultValues';
import type { MeetingCardFormData } from '../../CreateMeetingCard';

export const ScheduleBetweenDefaultVaue = () => {
  const {
    minutes,
    meeting_windows,
    is_recurrent,
    recurrent_frequency,
    recurrent_interval,
    recurrent_total_occurrences,
  } = useMeetingCardFieldsWatch<MeetingCardFormData>();

  const { userTimeZoneShortName, userTimeZone } = useTimeZone();

  const recurrentTitle = useMemo(() => {
    const rrulestr = convertRruleOptionsToRruleString({
      freq: recurrent_frequency || Frequency.WEEKLY,
      interval: recurrent_interval || 1,
      count: recurrent_total_occurrences || 1,
    });

    return convertRruleStringToReadableText(rrulestr);
  }, [recurrent_frequency, recurrent_interval, recurrent_total_occurrences]);

  return (
    <>
      <span>{minutes || 0} minutes</span>

      {meeting_windows?.map(({ start, end }, index) => {
        const startTitle = dayjs(start)
          .tz(userTimeZone)
          .format(DATE_RANGE_STRING_FORMAT);

        const endTitle = dayjs(end)
          .tz(userTimeZone)
          .format(DATE_RANGE_STRING_FORMAT);

        return (
          <span
            key={index}
          >{`${startTitle} ${userTimeZoneShortName} to ${endTitle} ${userTimeZoneShortName}`}</span>
        );
      })}

      {is_recurrent && (
        <>
          <span>{recurrentTitle}</span>

          <span className="nj-create-meeting-card--schedule-between-description">
            Ninja will negotiate the time for the first meeting and will make it
            recurring based on the rules defined here.
          </span>
        </>
      )}
    </>
  );
};
