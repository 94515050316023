import { useSession, useVisible } from 'src/hooks';
import { Button } from 'src/components/Button';
import { Archive, X } from '@phosphor-icons/react';
import { Modal } from 'react-responsive-modal';
import { useContext, useState } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useThreads } from 'src/hooks';
import log from 'src/utils/logger';
import { fetchArchivedAll, useAppDispatch } from 'src/store';

const SVG_SIZE = 24;

export const ArchiveAll = () => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { isVisible, handleVisibilitySet, handleVisibilityRemove } =
    useVisible();
  const { appUser } = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const { getConversationsList } = useThreads();

  const dispatch = useAppDispatch();

  const handleArchivingAll = async () => {
    setIsLoading(true);

    try {
      if (appUser.user_id) {
        await dispatch(fetchArchivedAll(appUser.user_id));
        // get original list without archives
        await getConversationsList({ reload: true });
      } else {
        throw new Error(`Cannot archive, user is not authenticated.`);
      }
      handleVisibilityRemove();
      setIsLoading(false);
    } catch (error: unknown) {
      log.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="nj-settings-tab-content--form-field inline-field">
      <p className="nj-settings-tab-content--access-subtitle">
        Archive all available chats & tasks
      </p>
      <button
        onClick={handleVisibilitySet}
        className="nj-settings-tab-content--archive-button"
      >
        <Archive size={SVG_SIZE} />
      </button>

      <Modal
        open={isVisible}
        onClose={handleVisibilityRemove}
        container={rootContainerRef?.current || document.body}
        classNames={{
          root: 'nj-modal--root',
          overlay: 'nj-modal--overlay',
          modal: 'nj-modal--access',
        }}
        showCloseIcon={!isLoading}
        closeIcon={<X size={SVG_SIZE} />}
        center
        closeOnOverlayClick={false}
      >
        <div className="nj-modal--access--wrapper">
          <p className="nj-modal--access--title">Archive Tasks</p>

          {isLoading ? (
            <p className="nj-archiving--loading">
              <span className="nj-archiving--progress-icon" />
              <span className="nj-archiving--loading-title">
                Archive all available chats &amp; tasks
              </span>
            </p>
          ) : (
            <>
              <p className="nj-settings-tab-content--access-description">
                All chats and tasks that are available up to today will be
                archived.
              </p>

              <p className="nj-settings-tab-content--access-description">
                Any in-progress tasks will not be archived.
              </p>

              <p className="nj-settings-tab-content--access-description">
                To proceed, click "Confirm archive" below.
              </p>

              <div className="nj-modal--access--action-wrapper">
                <Button
                  type="reset"
                  onClick={handleVisibilityRemove}
                  className="nj-settings-tab-content--access-button outlined active"
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  className="nj-settings-tab-content--access-button fill"
                  onClick={handleArchivingAll}
                >
                  Confirm archive
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
