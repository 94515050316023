import { useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import AudioContext from 'src/contexts/AudioContext';
import { ApiTaskSelectable, CameFrom } from 'src/types';
import { useBreakpoint, useThreads, useReadMessage } from 'src/hooks';
import { useAppDispatch, setShouldAnimate } from 'src/store';
import { TaskStatusIcon } from 'src/components/TaskStatusIcon';
import { SkillLabel } from 'src/components/SkillLabel';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { pause, handleScrollToMessageByTask } from 'src/utils';

type ConversationTaskProps = {
  task: Partial<ApiTaskSelectable>;
  shouldAnimate?: boolean;
  selected: boolean;
  scrollTo: (val: number) => void;
  shouldOpenRenameModal?: (val: boolean) => void;
  onClick: (taskId: string) => Promise<void>;
};

const STREAMING_DELAY = 125;

/**
 * Task component displays a row with task content which
 * holds task status: worked on, blocked or completed,
 * and short info on the task.
 */
export const ConversationTask = ({
  task,
  selected,
  shouldAnimate = false,
  scrollTo,
  shouldOpenRenameModal,
  onClick,
}: ConversationTaskProps) => {
  const { microphoneTriggerRef } = useContext(AudioContext);

  const { task_hash, state: taskState, conversation } = task;
  const { cameFrom } = useThreads();
  const { isMobile } = useBreakpoint();
  const { readMessage } = useReadMessage();

  const dispatch = useAppDispatch();

  const threadTaskRef = useRef<HTMLDivElement>(null);
  const isAnimatingRef = useRef<boolean>(false);

  useEffect(() => {
    if (selected && threadTaskRef.current && cameFrom === CameFrom.CHAT) {
      scrollTo(threadTaskRef.current.offsetTop);
    }
  }, [selected, scrollTo, cameFrom]);

  useGSAP(
    () => {
      if (isAnimatingRef.current) {
        const taskElement = threadTaskRef.current;
        if (!taskElement) {
          return;
        }

        taskElement.classList.add('task-created');

        const timeline = gsap.timeline({
          paused: true,
          onComplete: () => {
            taskElement.classList.add('animated');
          },
        });

        timeline
          .addLabel('start')
          .set('.subtitle', {
            opacity: 0,
            maxWidth: 0,
          })
          .set('.actions', {
            opacity: 0,
          })
          .addLabel('task item slide down')
          .fromTo(
            taskElement,
            {
              maxHeight: 0,
              opacity: 0,
            },
            {
              maxHeight: 40,
              opacity: 1,
              duration: 0.3,
            },
          )
          .addLabel('task hash title streaming')
          .to('.subtitle', {
            opacity: 1,
            maxWidth: '70%',
            duration: 1,
            delay: 0.35,
          })
          .addLabel('show actions panel')
          .to('.actions', {
            opacity: 1,
            duration: 0.25,
            delay: 0,
          });

        pause(STREAMING_DELAY).then(() => {
          timeline.play();
        });
      }
    },
    { dependencies: [isAnimatingRef.current], scope: threadTaskRef },
  );

  useEffect(() => {
    if (shouldAnimate) {
      dispatch(setShouldAnimate());
      isAnimatingRef.current = true;
    }
  }, [dispatch, shouldAnimate]);

  const handleClick = async () => {
    const { task_id, parent_conversation_id, requires_attention } = task;

    if (!task_id || !parent_conversation_id) {
      return;
    }

    microphoneTriggerRef?.current?.stopRecording(true);

    if (requires_attention) {
      readMessage(task_id);
    }

    await onClick(task_id);

    handleScrollToMessageByTask(task_id);
  };

  return (
    <div
      className={classNames('nj-thread-task', {
        'task-created': shouldAnimate,
        selected,
      })}
      data-conversation-id={conversation?.conversation_id}
      onClick={handleClick}
      ref={threadTaskRef}
      data-testid="task"
    >
      <div className="container">
        <span className="nj-task-card--icon">
          <TaskStatusIcon taskState={taskState} />
        </span>

        {selected && !isMobile && (
          <>
            <h3 className="subtitle ellipsis">
              <span>{task_hash?.replace('#', '')}</span>
            </h3>

            <div className="nj-task-card--action-wrapper actions">
              {task.skill && <SkillLabel skill={task.skill} />}
            </div>
          </>
        )}

        {(!selected || isMobile) && (
          <>
            <h3 className="subtitle ellipsis">{task_hash?.replace('#', '')}</h3>

            <div className="nj-task-card--action-wrapper actions">
              {task.skill && <SkillLabel skill={task.skill} />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
