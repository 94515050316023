import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { sanitize } from 'dompurify';
import { Circle, CheckSquare, CheckCircle } from '@phosphor-icons/react';
import { useCollapse } from 'react-collapsed';
import { Markdown } from 'src/components/Markdown';

const SVG_SIZE = 20;

type ChoiceClickProps = {
  select_key: string;
  select_value: string;
  selected: boolean;
  multi_select: boolean;
};

type ChoiceCardEntryProps = {
  select_key: string;
  selected?: boolean;
  submitted?: boolean;
  multi_select: boolean;
  select_value: string;
  title?: string;
  description?: string;
  children: React.ReactNode;
  rest?: { [key: string]: React.ReactNode };
  onChoiceClick: (values: ChoiceClickProps) => void;
};

export const ChoiceCardEntry = ({
  select_key,
  selected = false,
  submitted = false,
  multi_select,
  select_value,
  title,
  description = '',
  children,
  onChoiceClick,
  ...rest
}: ChoiceCardEntryProps) => {
  const [isExpanded, setExpanded] = useState<boolean>(selected);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    hasDisabledAnimation: submitted,
  });

  useEffect(() => {
    setExpanded(selected);
  }, [selected]);

  return (
    <div className={classNames('nj-options-card--entry')} {...rest}>
      <div
        className={classNames('nj-options-card--row', 'option', {
          selected,
          isExpanded,
        })}
        data-e2e="options-card-entry"
        {...(!submitted &&
          getToggleProps({
            onClick: () => {
              setExpanded((prevExpanded) => !prevExpanded);
              onChoiceClick({
                select_key,
                select_value,
                selected: !selected,
                multi_select,
              });
            },
          }))}
      >
        <div className="left">
          <h4>{title}</h4>
          <Markdown>{sanitize(description)}</Markdown>
        </div>

        <div className="nj-options-card--entry-icon nj-options-card--icon">
          {multi_select ? (
            <CheckSquare
              size={SVG_SIZE}
              weight={selected ? 'fill' : 'regular'}
              color="currentColor"
            />
          ) : selected ? (
            <CheckCircle size={SVG_SIZE} weight="fill" color="currentColor" />
          ) : (
            <Circle size={SVG_SIZE} weight="regular" color="currentColor" />
          )}
        </div>
      </div>

      <div {...getCollapseProps()}>{children}</div>
    </div>
  );
};
