import { useRef } from 'react';
import classNames from 'classnames';
import { useTheme } from 'src/hooks';
import { SideSettingsPanel } from './components/SideSettingsPanel';
import { Panel } from 'src/components/Panel';

export const SidePanel = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isSettingsPanelExpanded } = useTheme();

  return (
    <Panel
      ref={containerRef}
      className={classNames('nj-fluid--panel--settings', {
        expanded: isSettingsPanelExpanded,
      })}
    >
      <div className={classNames('nj-section', 'nj-section--side')}>
        <div className="container">
          <SideSettingsPanel />
        </div>
      </div>
    </Panel>
  );
};
