/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Tooltip } from 'src/components/Tooltip';
import { useSession, useRenameTask, useTasks, useTheme } from 'src/hooks';
import { Button } from 'src/components/Button';

interface FormData {
  title: string;
}

export const RenameTaskSection = () => {
  const [isFocusInput, setIsFocusInput] = useState<boolean>(false);

  const { isTaskItemById, selectedTaskDetail } = useSession();
  const { selectTaskById } = useTasks();
  const { isSettingsPanelExpanded } = useTheme();

  const currentTask = useMemo(() => {
    return selectTaskById(selectedTaskDetail.selectedTaskId);
  }, [selectedTaskDetail.selectedTaskId, selectTaskById]);

  const { onRenameTask } = useRenameTask(selectedTaskDetail.selectedTaskId);

  const handleRenameTask = async (value: string) => {
    await onRenameTask(value);
  };

  const {
    register,
    handleSubmit,
    reset: resetForm,
    setValue,
  } = useForm<FormData>({});

  const handleCancelEdit = () => {
    resetForm();
    setValue('title', currentTask?.task_hash?.replace('#', '') || '');
  };

  useEffect(() => {
    if (!isSettingsPanelExpanded) {
      handleCancelEdit();
    }
  }, [isSettingsPanelExpanded]);

  useEffect(() => {
    if (!currentTask || !isTaskItemById) {
      return;
    }
    setValue('title', currentTask?.task_hash?.replace('#', '') || '');
  }, [currentTask, isTaskItemById]);

  const handleFormSubmit = async ({ title }: FormData) => {
    if (!title) {
      handleCancelEdit();
      return;
    }
    await handleRenameTask(title);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;

    const cleanedValue = value.replace(/\s+/g, '-').replace(/[^\w\s-]/g, '');
    e.target.value = cleanedValue;
  };

  if (!currentTask) {
    return null;
  }

  return (
    <div className="nj-section--main-container with-padding">
      <form
        className="nj-section--rename-task"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className="nj-section--rename-task-input-wrapper">
          <input
            className="nj-section--rename-task-input"
            {...register('title')}
            onInput={handleInputChange}
            onFocus={() => setIsFocusInput(true)}
            onBlur={() => setIsFocusInput(false)}
          />

          {isFocusInput && (
            <Tooltip title="Names can’t contain special characters" />
          )}
        </div>

        <Button className="nj-section--rename-task-submit-button" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};
