import { useMemo } from 'react';
import { ChatsFilter } from 'src/types';
import { useSession, useThreads } from 'src/hooks';
import { InProgressIcon } from 'src/components/InProgressIcon';
import {
  Check,
  XCircle,
  PauseCircle,
  Warning,
  Prohibit,
} from '@phosphor-icons/react';

const ICON_PROPS = {
  size: 16,
  color: 'currentColor',
};

export const FilteringChip = () => {
  const { chatsFilter, updateChatsFilter, removeCurrentTaskId } = useSession();
  const { getConversationsList } = useThreads();

  const handleResetConversationsList = () => {
    updateChatsFilter(ChatsFilter.ALL);
    getConversationsList({ reload: true, filter: ChatsFilter.ALL });
  };

  // all + all & archived will not have icons
  const chipIcon = useMemo(() => {
    switch (chatsFilter) {
      case ChatsFilter.STOPPED:
        return <Prohibit {...ICON_PROPS} />;
      case ChatsFilter.FAILED:
        return <Warning {...ICON_PROPS} />;
      case ChatsFilter.IN_PROGRESS:
        return <InProgressIcon {...ICON_PROPS} />;
      case ChatsFilter.DONE:
        return <Check {...ICON_PROPS} />;
      case ChatsFilter.BLOCKED:
        return <PauseCircle {...ICON_PROPS} />;
      default:
        return null;
    }
  }, [chatsFilter]);

  return chatsFilter !== ChatsFilter.ALL ? (
    <div className="nj-filtering-chips" onClick={removeCurrentTaskId}>
      <span className="nj-filtering-chip">
        <span>{chipIcon}</span>
        <span>{chatsFilter}</span>
        <span onClick={handleResetConversationsList}>
          <XCircle {...ICON_PROPS} />
        </span>
      </span>
    </div>
  ) : null;
};
