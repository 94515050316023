import { useContext, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { useBreakpoint, useTheme } from 'src/hooks';
import { Panel } from 'src/components/Panel';
import { ConversationListMainContent } from './components/ConversationListMainContent';
import { OverlayBackground } from 'src/components/OverlayBackground';
import { ConversationControl } from './components/ConversationControl';
import { ANIMATION_TIMEOUT } from 'src/constants';

/**
 * New Paradigm Multi-Threaded Conversation & Task Chat.
 */
export const MultiConversationsListPanel = () => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileOrTablet } = useBreakpoint();

  const { expandedMeta: isInFullscreen } = useContext(AvatarModeContext);

  const { isTasksListExpanded, setIsTasksListExpanded } = useTheme();
  const { toggleProfileSettingsBarExpanded, toggleSettingsPanelExpanded } =
    useTheme();

  useEffect(() => {
    if (isMobileOrTablet) {
      toggleProfileSettingsBarExpanded(false);
      toggleSettingsPanelExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileOrTablet]);

  if (isInFullscreen) {
    return null;
  }

  if (isMobileOrTablet) {
    return (
      <>
        <OverlayBackground
          showOverlay={isTasksListExpanded}
          onClick={() => setIsTasksListExpanded(false)}
          zIndex={1000}
          className="transparent-tablet"
          ref={overlayRef}
        />

        <CSSTransition
          in={isTasksListExpanded}
          timeout={ANIMATION_TIMEOUT}
          classNames="nj-animate-horizontal-appearance-ltr"
          unmountOnExit
          nodeRef={containerRef}
        >
          <div ref={containerRef} className="nj-mobile-conversation-list">
            <ConversationListMainContent />
          </div>
        </CSSTransition>

        {isMobileOrTablet && <ConversationControl type="panel" />}
      </>
    );
  }

  return (
    <Panel className="nj-fluid--panel--nav nj-fluid--panel--chats-list">
      <ConversationListMainContent />
    </Panel>
  );
};
