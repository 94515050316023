import {
  Fragment,
  useRef,
  useEffect,
  useMemo,
  useContext,
  useCallback,
  MouseEvent,
  useState,
} from 'react';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { Hash, CaretUp, CaretDown } from '@phosphor-icons/react';
import { useCollapse } from 'react-collapsed';
import classNames from 'classnames';
import SessionContext, {
  SessionContextType,
} from 'src/contexts/SessionContext';
import AudioContext, { AudioContextType } from 'src/contexts/AudioContext';
import AvatarModeContext, {
  AvatarModeContextType,
} from 'src/contexts/AvatarModeContext';
import { useBreakpoint, useSession, useTheme } from 'src/hooks';
import {
  ApiMinimumTask,
  Conversation,
  ChatsFilter,
  TaskState,
} from 'src/types';
import {
  useAppDispatch,
  fetchConversationById,
  setShouldAnimate,
} from 'src/store';
import { ConversationTask } from '../ConversationTask';
import { ConversationThreadMenu } from '../ConversationThreadMenu';
import { Counter } from 'src/components/Counter';
import { DEFAULT_CHAT_NAME } from 'src/constants';
import { interruptMetahuman } from 'src/utils';

type ConversationThreadProps = {
  conversation: Conversation;
  selected?: boolean;
  activeThreadAnchor: string | null;
  setActiveThreadAnchor: (anchorId: string | null) => void;
  shouldAnimate?: boolean;
  shouldAnimateListItem?: [string, string];
  shouldOpenRenameModal?: (val: boolean) => void;
  scrollTo: (val: number) => void;
};

const SVG_SIZE = 20;

const ICON_PROPS = {
  size: SVG_SIZE,
  color: 'currentColor',
};

/**
 * ConversationThread displays a clickable conversation with
 * tasks belonging to it as a micro-list: parent > children.
 */
export const ConversationThread = ({
  conversation,
  selected = false,
  shouldAnimate = false,
  shouldAnimateListItem,
  activeThreadAnchor,
  setActiveThreadAnchor,
  scrollTo,
  shouldOpenRenameModal,
}: ConversationThreadProps) => {
  const {
    conversation_id,
    tasks,
    messages,
    conversation_hash,
    number_tasks_require_attention,
  } = conversation;
  const {
    updateCurrentConversationId,
    appUser,
    chatsFilter,
    currentTaskId,
    currentConversationId,
  } = useSession();
  const { isMobile } = useBreakpoint();
  const { onToggleScrollToBottom } =
    useContext<SessionContextType>(SessionContext);
  const { showTranscript, toggleTranscript } =
    useContext<AvatarModeContextType>(AvatarModeContext);
  const { metaHumanTalking } = useContext<AudioContextType>(AudioContext);

  const { setIsTasksListExpanded } = useTheme();

  const [isExpanded, setExpanded] = useState<boolean>(true);
  const { getCollapseProps, getToggleProps } = useCollapse({
    defaultExpanded: true,
    isExpanded,
  });

  const threadTasks = useMemo(() => {
    if (!tasks) return [];
    const sorted = tasks
      .slice()
      .sort(
        (a, b) =>
          new Date(b.created_at || new Date()).getTime() -
          new Date(a.created_at || new Date()).getTime(),
      );

    const filtered =
      chatsFilter !== ChatsFilter.ALL_AND_ARCHIVED
        ? sorted.filter((a: ApiMinimumTask) => a.state !== TaskState.ARCHIVED)
        : sorted;

    return filtered;
  }, [tasks, chatsFilter]);

  const dispatch = useAppDispatch();

  const willAnimateRef = useRef<boolean>(shouldAnimate);
  const isAnimatingRef = useRef<boolean>(false);
  const threadRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<string>();
  const headingTitleRef = useRef<HTMLHeadingElement>(null);

  const unhashedTitle = useMemo(() => {
    return conversation_hash?.replace('#', '') || '';
  }, [conversation_hash]);

  // set the value invisibly and hide the title
  const prepareAnimation = useCallback((headingValue?: string) => {
    if (threadRef.current) {
      threadRef.current.classList.add('hidden');
    }
    willAnimateRef.current = false;
    headingRef.current = headingValue;
    isAnimatingRef.current = true;
  }, []);

  useEffect(() => {
    if (
      willAnimateRef.current === true &&
      headingRef.current === DEFAULT_CHAT_NAME &&
      unhashedTitle !== DEFAULT_CHAT_NAME
    ) {
      dispatch(setShouldAnimate());
      prepareAnimation(unhashedTitle);
    } else {
      headingRef.current = unhashedTitle;
      if (headingTitleRef.current) {
        headingTitleRef.current.innerText = unhashedTitle;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unhashedTitle]);

  useGSAP(
    () => {
      if (isAnimatingRef.current) {
        gsap
          .timeline({
            onComplete: () => {
              isAnimatingRef.current = false;
              threadRef.current?.classList.add('animated');
            },
          })
          .addLabel('maximizing the title')
          .fromTo(
            '.subtitle',
            { maxWidth: '0', opacity: 0 },
            { maxWidth: '100%', opacity: 1, duration: 1 },
          )
          .addLabel('showing menu')
          .fromTo('.submenu', { opacity: 0 }, { opacity: 1, duration: 0.3 });
      }
    },
    { dependencies: [isAnimatingRef.current], scope: threadRef },
  );

  const threadId = conversation_id || DEFAULT_CHAT_NAME;

  const handleCollapseClick = (e: MouseEvent) => {
    e.stopPropagation();
    setExpanded((prevExpanded) => !prevExpanded);
  };

  // on click lazy load conversation by id
  const triggerConversationUpdate = async () => {
    updateCurrentConversationId(threadId);

    if (threadId !== DEFAULT_CHAT_NAME) {
      onToggleScrollToBottom(true);

      if (metaHumanTalking) {
        await interruptMetahuman(appUser.user_id);
      }

      await dispatch(
        fetchConversationById({
          userId: appUser.user_id,
          conversationId: threadId,
        }),
      );
    }
  };

  const handleConversationClick = async (e: MouseEvent) => {
    setActiveThreadAnchor(null);

    if (threadId === currentConversationId) {
      handleCollapseClick(e);
      return;
    }

    if (isMobile) {
      setIsTasksListExpanded(false);
    }

    await triggerConversationUpdate();

    if (!showTranscript) {
      toggleTranscript(true);
    }
  };

  const handleTaskClick = async (taskId: string) => {
    setActiveThreadAnchor(taskId);

    if (isMobile) {
      setIsTasksListExpanded(false);
    }

    if (conversation_id !== currentConversationId) {
      await triggerConversationUpdate();
    }

    if (!showTranscript) {
      toggleTranscript(true);
    }
  };

  const handleMenuClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
  };

  return (
    <Fragment>
      <div
        className={classNames('nj-thread-conversation', {
          default: conversation_id === DEFAULT_CHAT_NAME,
          'has-messages': messages && messages.length > 0,
          'has-tasks': threadTasks.length > 0,
          'has-notification': !!number_tasks_require_attention,
          selected: selected,
          'with-indicator': selected && !activeThreadAnchor,
        })}
        onClick={handleConversationClick}
        ref={threadRef}
        data-conversation-id={conversation_id}
        data-conversation-hash={conversation_hash}
        data-number-of-tasks={tasks?.length}
      >
        <div className="container">
          <span className="nj-task-card--icon">
            <Hash
              {...ICON_PROPS}
              weight={number_tasks_require_attention ? 'bold' : 'regular'}
            />
          </span>

          <h3 ref={headingTitleRef} className="subtitle ellipsis">
            {headingRef.current}
          </h3>

          {number_tasks_require_attention ? (
            <Counter number={number_tasks_require_attention} />
          ) : null}

          <span
            onClick={handleMenuClick}
            className="nj-thread-conversation--menu submenu"
          >
            {selected && !currentTaskId && (
              <ConversationThreadMenu
                conversation={conversation}
                shouldOpenRenameModal={shouldOpenRenameModal}
              />
            )}
          </span>

          {threadTasks.length > 0 && (
            <button
              className={classNames('nj-thread-conversation--collapse-icon', {
                visible: selected,
              })}
              {...getToggleProps({
                onClick: handleCollapseClick,
              })}
            >
              {isExpanded ? (
                <CaretUp size={SVG_SIZE} />
              ) : (
                <CaretDown size={SVG_SIZE} />
              )}
            </button>
          )}
        </div>
      </div>

      <div {...getCollapseProps()}>
        {threadTasks.map((minimalTask: ApiMinimumTask, index: number) => (
          <ConversationTask
            key={`task-${minimalTask.task_id}-${index}`}
            task={minimalTask}
            selected={activeThreadAnchor === minimalTask.task_id && selected}
            shouldAnimate={
              shouldAnimateListItem &&
              Array.isArray(shouldAnimateListItem) &&
              shouldAnimateListItem[0] === 'task' &&
              shouldAnimateListItem[1] === minimalTask.task_id
            }
            scrollTo={scrollTo}
            shouldOpenRenameModal={shouldOpenRenameModal}
            onClick={handleTaskClick}
          />
        ))}
      </div>
    </Fragment>
  );
};
