import { useContext, useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { List } from '@phosphor-icons/react';
import SessionContext from 'src/contexts/SessionContext';
import { TaskState, GTMEvent } from 'src/types';
import { useSession } from 'src/hooks';
import { sendGTMEvent } from 'src/utils';
import { ANIMATION_TIMEOUT } from 'src/constants';
import { Icon } from 'src/components/Icon';
import './ViewTaskButton.scss';

const SVG_SIZE = 16;

interface GoToTaskButtonProps {
  taskId: string;
  taskState: TaskState;
}

export const ViewTaskButton = ({ taskId, taskState }: GoToTaskButtonProps) => {
  const { onToggleVisibleTaskViewModal } = useContext(SessionContext);

  const { updateCurrentTaskId } = useSession();

  const gradientRef = useRef<HTMLDivElement>(null);

  const isShadowAnimated = useMemo(
    () => taskState === TaskState.IN_PROGRESS,
    [taskState],
  );

  const handleClick = () => {
    updateCurrentTaskId(taskId);
    onToggleVisibleTaskViewModal(true);
    sendGTMEvent(GTMEvent.TASK_VIEW_OPENING, {
      task_id: taskId,
      task_state: taskState,
    });
  };

  return (
    <div
      className={classNames('nj-thread-message--elastic-gradient-button', {
        'is-done': !isShadowAnimated,
        'is-warning': taskState === TaskState.BLOCKED,
      })}
      role="button"
      tabIndex={0}
    >
      <CSSTransition
        in={isShadowAnimated}
        timeout={ANIMATION_TIMEOUT}
        classNames="nj-animate-opacity-pulsation"
        unmountOnExit
        nodeRef={gradientRef}
      >
        <div
          ref={gradientRef}
          className="nj-thread-message--elastic-gradient-shadow"
        />
      </CSSTransition>

      <div className="nj-thread-message--elastic-gradient-border" />

      <button
        data-e2e="open-task-from-bubble-button"
        onClick={handleClick}
        className="nj-thread-message--action-round-button"
        tabIndex={-1}
      >
        {isShadowAnimated ? (
          <Icon type="listGradient" />
        ) : (
          <List
            className="nj-thread-message--action-round-button-icon"
            size={SVG_SIZE}
          />
        )}
      </button>
    </div>
  );
};
