import { Play } from '@phosphor-icons/react';
import classNames from 'classnames';
import { UpsellVideoModal } from 'src/components/UpsellVideoModal';
import { useVisible } from 'src/hooks';
import { Button } from 'src/components/Button';
import { useGetVideoLinksJSONQuery } from 'src/store/services';

const SVG_SIZE = 20;

export const LearnMoreButton = () => {
  const {
    isVisible: isShowUpsellModal,
    handleVisibilitySet: openUpsellModal,
    handleVisibilityRemove: closeUpsellModal,
  } = useVisible();
  const { data: videoLinks } = useGetVideoLinksJSONQuery();

  return (
    <>
      <Button
        className={classNames(
          'nj-common-button',
          'nj-common-button--secondary',
          'nj-learn-more-button',
        )}
        onClick={openUpsellModal}
      >
        <Play size={SVG_SIZE} />
        <span>Learn more</span>
      </Button>
      <UpsellVideoModal
        videoUrl={videoLinks?.scheduler || ''} // todo - for now only scheduler could be here
        title="Scheduler AI Agent"
        description="Sign up to have Ninja coordinate your meeting times seamlessly."
        isOpen={isShowUpsellModal}
        onClose={closeUpsellModal}
      />
    </>
  );
};
