import { useThreads } from 'src/hooks';
import { TagsSection } from '../TaskViewHeader/components';
import { TaskQueryStatusSection } from 'src/components/FlatAppearance/components/TaskView/components/TaskViewProgress/components';

export const TaskViewProgress = () => {
  const { currentTask } = useThreads();

  if (!currentTask || !currentTask?.task_id) {
    return null;
  }

  return (
    <div className="nj-task-view-header--wrapper">
      <div className="nj-task-view-header--content">
        <TagsSection currentTask={currentTask} />

        <TaskQueryStatusSection />
      </div>
    </div>
  );
};
