import { useMemo, useRef, useState, lazy, Suspense } from 'react';
import { getSkillFromMessage, uppercaseFirstLetter } from 'src/utils';
import {
  useOutsideClick,
  useSession,
  useSubmitUserInput,
  useThreads,
} from 'src/hooks';
import { Message, MessageSkill } from 'src/types';
import { RedoSkillButtons } from 'src/constants';
import { UserList } from '@phosphor-icons/react';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

interface RedoSkillActionsProps {
  message: Message;
}

const ICON_SIZE = 20;

export const RedoSkillActions = ({ message }: RedoSkillActionsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isShowRedoTooltip, setIsShowRedoTooltip] = useState(false);
  const { onSubmitUserInput } = useSubmitUserInput();
  const { isSubmitHappened } = useThreads();
  const { appUser } = useSession();

  const userInput = message?.refined_query || message?.clean_query || '';

  const messageIdValidPart = message.message_id?.slice(-6);

  const skillsForRedo = useMemo(() => {
    const currentSkill = message.message_type
      ? getSkillFromMessage(message.message_type)
      : '';

    return RedoSkillButtons.filter(({ skill }) => skill !== currentSkill);
  }, [message.message_type]);

  const handleOpenTooltip = () => {
    setIsShowRedoTooltip(!isShowRedoTooltip);
  };

  const handleCloseTooltip = () => {
    setIsShowRedoTooltip(false);
  };

  const handleClick = (skill: MessageSkill) => {
    const preparedUserInput = `/${skill} ${userInput}`;
    handleCloseTooltip();
    onSubmitUserInput(preparedUserInput);
  };

  useOutsideClick(containerRef, handleCloseTooltip);

  return (
    <div className="nj-message-footer--redo-skill-actions" ref={containerRef}>
      <button
        onClick={handleOpenTooltip}
        id={`redo-with-skills_${messageIdValidPart}`}
        disabled={isSubmitHappened || !userInput}
      >
        <UserList size={ICON_SIZE} />
      </button>

      {isShowRedoTooltip && (
        <Suspense>
          <LazyReactTooltip
            anchorSelect={`#redo-with-skills_${messageIdValidPart}`}
            clickable
            noArrow
            openOnClick
            className="nj-redo-tooltip"
            isOpen={isShowRedoTooltip}
            opacity={1}
            place="top-start"
            offset={2}
          >
            <div className="nj-redo-tooltip-with-skills">
              <header className="nj-redo-tooltip-header">
                Regenerate with different Agent
              </header>
              {skillsForRedo.map(({ skill, icon: SkillIcon }) => (
                <button
                  className="nj-redo-skill-row"
                  onClick={() => handleClick(skill)}
                  key={skill}
                  disabled={
                    skill === MessageSkill.SCHEDULER &&
                    !appUser?.settings?.features?.redo_as_scheduler?.is_capable
                  }
                >
                  <SkillIcon size={ICON_SIZE} />
                  <span>{uppercaseFirstLetter(skill)}</span>
                </button>
              ))}
            </div>
          </LazyReactTooltip>
        </Suspense>
      )}
    </div>
  );
};
