/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, Fragment } from 'react';
import classNames from 'classnames';
import { VideoCameraSlash } from '@phosphor-icons/react';
import { ChatMode } from 'src/types';
import { useSession } from 'src/hooks';
import AvatarModeContext, {
  AvatarModeContextType,
} from 'src/contexts/AvatarModeContext';
import AudioContext from 'src/contexts/AudioContext';
import { AvatarSessionNotice } from '../AvatarSessionNotice';
import { AvatarMultiButtonControl } from '../AvatarMultiButtonsControl';
// import { MuteButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsHeader/components/MuteButton';
import { Button } from 'src/components/Button';

type AvatarFrameButtonsLayoutProps = {
  isVideoFloating?: boolean;
  isSplitView?: boolean;
  onFullscreen: (val: boolean) => void;
};

const SVG_SIZE = 24;

export const AvatarFrameButtonsLayout = ({
  isVideoFloating = false,
  isSplitView = false,
  onFullscreen,
}: AvatarFrameButtonsLayoutProps) => {
  const {
    showTranscript,
    expandedMeta: isInFullscreen,
    clearShowAvatarQueue,
  } = useContext<AvatarModeContextType>(AvatarModeContext);
  const { metaHumanTalking } = useContext(AudioContext);
  const { updateAvatarQueue, updateChatMode } = useSession();

  const handleCloseAvatar = () => {
    updateAvatarQueue();
    clearShowAvatarQueue();
    updateChatMode(ChatMode.CHAT);
  };

  return (
    <Fragment>
      <div
        className={classNames('nj-meta-human--header', {
          'side-position': !showTranscript || !metaHumanTalking,
          floating: isVideoFloating,
        })}
      >
        {/* TODO(olha): WIP */}
        {/* {(showTranscript || (isInFullscreen && isVideoFloating)) && (
          <MuteButton showLabel />
        )} */}
        <AvatarMultiButtonControl
          isVideoFloating={isVideoFloating}
          onFullscreen={onFullscreen}
        />
      </div>
      {isVideoFloating && (
        <Button
          className="nj-meta-human--button medium red end-video-call"
          onClick={handleCloseAvatar}
        >
          <VideoCameraSlash size={SVG_SIZE} />
        </Button>
      )}
      <AvatarSessionNotice />
    </Fragment>
  );
};
