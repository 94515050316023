import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useQueryParams, useSubmitUserInput } from 'src/hooks';
import { toast } from 'react-toastify';
import { DEFAULT_CHAT_ID } from 'src/constants';

/**
 * useChatPageQueryParams handles all query parameters on the Main chat page.
 */
export const useChatPageQueryParams = () => {
  const navigate = useNavigate();
  const { session_id, query } = useQueryParams();
  const { onSubmitUserInput } = useSubmitUserInput();

  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);

  useEffect(() => {
    if (session_id) {
      //TODO(olha): WIP call checking endpoint
      toast('Welcome to Ninja PRO');
      navigate('/', { replace: true });
      //TODO(olha): WIP error handling toast('Session ID is not valid.');
    }
  }, [session_id, navigate]);

  useEffect(() => {
    if (query) {
      const decodedQuery = decodeURIComponent(query);
      setThreadInputBoxValue(decodedQuery);
      onSubmitUserInput(decodedQuery, { conversation_id: DEFAULT_CHAT_ID });
      navigate('/', { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setThreadInputBoxValue, navigate]);
};
