import { useMemo } from 'react';
import { ConversationRole, Message, MessageType, TaskState } from 'src/types';
import { useSession, useThreads, useVisible } from 'src/hooks';
import { getTaskIdFromMessage } from 'src/utils';
import { RegenerateMessageButton } from '../RegenerateMessageButton';
import { RedoSkillActions } from '../RedoSkillActions';
// TODO(olha): move component to the common folder
import { MessageThumbsButtons } from 'src/pages/ManageTasksChatPage/components/ConversationFeedback/components/NonDebugModeThumbsFeedback/components/MessageThumbsButtons';
import { CopyMessageButton } from 'src/pages/ManageTasksChatPage/components/ChatMessage/components/CopyMessageButton';
import { ShareButton } from 'src/components/ShareButton';
import { RedoWithPersona } from 'src/components/FlatAppearance/components/RedoWithPersona';
import { NegativeFeedbackAdditionalForm } from 'src/components/FlatAppearance/components/NegativeFeedbackAdditionalForm';
import { XCircle } from '@phosphor-icons/react';

const SVG_SIZE = 20;

interface MessageFooterPops {
  message: Message;
}

export const MessageFooter = ({ message }: MessageFooterPops) => {
  const { appUser } = useSession();
  const {
    role,
    conversation_id,
    message_id,
    message_type,
    is_final_answer,
    feedback,
  } = message;

  const {
    isVisible: isShowNegativeFeedbackForm,
    handleVisibilityRemove: onCloseNegativeFeedbackForm,
    handleVisibilityToggle: onToggleNegativeFeedbackForm,
  } = useVisible(false);

  const { selectConversationTaskById } = useThreads();

  const taskId = getTaskIdFromMessage(message);
  const taskState = taskId ? selectConversationTaskById(taskId)?.state : null;

  const negativeFeedbackObject = {
    feedback_id: feedback?.feedback_id || '',
    user_id: appUser.user_id,
    conversation_id: conversation_id || '',
    message_id: message_id || '',
    feedback_type: feedback?.feedback_type,
    task_id: taskId,
  };

  const shouldShowSharingButtons = useMemo(() => {
    if (message_type === MessageType.CONVERSATION && is_final_answer) {
      return true;
    }

    if (taskState === TaskState.DONE && is_final_answer) {
      return true;
    }

    if (!taskId && is_final_answer) {
      return true;
    }

    return false;
  }, [message_type, is_final_answer, taskState, taskId]);

  if (role === ConversationRole.USER || !message_id) {
    return null;
  }

  return (
    <div>
      <div className="nj-message-footer">
        <div className="nj-message-footer--wrapper">
          <RegenerateMessageButton message={message} />
          {message.message_type !== MessageType.IMAGE_CARD && (
            <>
              <RedoSkillActions message={message} />
              <RedoWithPersona message={message} />
            </>
          )}
        </div>

        <div className="nj-message-footer--wrapper">
          <MessageThumbsButtons
            taskId={taskId}
            messageId={message_id}
            conversationId={conversation_id}
            messageType={message_type}
            feedback={feedback}
            onToggleNegativeFeedbackForm={onToggleNegativeFeedbackForm}
            isShowNegativeFeedbackForm={isShowNegativeFeedbackForm}
          />

          <CopyMessageButton
            item={message}
            disabled={
              !shouldShowSharingButtons ||
              message_type === MessageType.IMAGE_CARD
            }
          />

          {message.message_type !==
            MessageType.SCHEDULER_TASK_CREATION_CARD && (
            <ShareButton
              messageId={message.message_id || ''}
              buttonIconSize={SVG_SIZE}
              disabled={!shouldShowSharingButtons}
            />
          )}
        </div>
      </div>
      {isShowNegativeFeedbackForm && (
        <div className="nj-message-footer">
          <div className="nj-feedback-negative-form-wrapper">
            <header className="nj-feedback-negative-form-header">
              <h5 className="nj-feedback-negative-form-header-title">
                Tell us more about this rating
              </h5>
              <button onClick={onCloseNegativeFeedbackForm}>
                <XCircle size={SVG_SIZE} />
              </button>
            </header>
            <NegativeFeedbackAdditionalForm
              onClose={onCloseNegativeFeedbackForm}
              messageType={message_type || MessageType.CONVERSATION}
              feedback={negativeFeedbackObject}
            />
          </div>
        </div>
      )}
    </div>
  );
};
