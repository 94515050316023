import { RenewEarlyButton } from 'src/components/RenewEarlyButton';
import { RemainingTasksSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/RemainingTasksSection';
import styles from './InsufficientCreditsSection.module.scss';

export const InsufficientCreditsSection = () => {
  return (
    <div className={styles.root}>
      <h4 className={styles.title}>Insufficient credits</h4>
      <p className={styles.description}>
        Renew your subscription early to continue access to PRO features.
      </p>
      <RemainingTasksSection />
      <RenewEarlyButton className={styles.button} />
      <p className={styles.description}>
        Due to insufficient PRO credits, your account will be adjusted to the
        FREE account limitations.
      </p>
    </div>
  );
};
