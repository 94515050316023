import { useContext } from 'react';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { toast } from 'react-toastify';
import {
  DotsThreeOutlineVertical,
  PencilSimple,
  Archive,
  Bug,
} from '@phosphor-icons/react';
import { useSession } from 'src/hooks';
import { useAppDispatch, fetchUpdateConversationById } from 'src/store';
import {
  Conversation,
  ConversationState,
  UpdateConversationHashStateRequest,
} from 'src/types';
import { useCheckIsNinjaEmployee } from 'src/hooks';
import { useHotkeys } from 'react-hotkeys-hook';
import DebugContext from 'src/contexts/DebugContext';

type ConversationThreadMenuProps = {
  conversation: Conversation;
  shouldOpenRenameModal?: (val: boolean) => void;
};

const ICON_PROPS = {
  size: 20,
  color: 'currentColor',
};

export const ConversationThreadMenu = ({
  conversation,
  shouldOpenRenameModal,
}: ConversationThreadMenuProps) => {
  const { state: conversationState, conversation_id } = conversation;
  const { toggleDebugMode } = useContext(DebugContext);
  const isUserNinjaEmployee = useCheckIsNinjaEmployee();
  const { appUser } = useSession();

  const dispatch = useAppDispatch();

  useHotkeys('ctrl+d', () => {
    toggleDebugMode();
  });

  const handleOpenRenameModal = () => {
    if (shouldOpenRenameModal) {
      shouldOpenRenameModal(true);
    }
  };

  const handleArchiveThread = (conversationId?: string) => {
    if (!conversation_id) {
      return;
    }

    dispatch(
      fetchUpdateConversationById({
        user_id: appUser.user_id,
        conversation_id: conversationId,
        state: ConversationState.ARCHIVED,
      } as UpdateConversationHashStateRequest),
    );

    toast('Chat archived');
  };

  return (
    <Menu
      gap={8}
      menuClassName="nj-multi-menu"
      menuButton={
        <MenuButton data-testid="snowman-menu">
          <DotsThreeOutlineVertical {...ICON_PROPS} alt="Conversation menu" />
        </MenuButton>
      }
      transition
    >
      <MenuItem data-testid="rename-hash-btn" onClick={handleOpenRenameModal}>
        <PencilSimple {...ICON_PROPS} /> Rename
      </MenuItem>

      {conversationState === ConversationState.ARCHIVABLE && (
        <>
          <MenuItem
            data-testid="archive-btn"
            onClick={() => handleArchiveThread(conversation_id)}
          >
            <Archive {...ICON_PROPS} /> Archive
          </MenuItem>
        </>
      )}

      {isUserNinjaEmployee && (
        <MenuItem data-testid="rename-hash-btn" onClick={toggleDebugMode}>
          <Bug {...ICON_PROPS} /> Debug
        </MenuItem>
      )}
    </Menu>
  );
};
