import { useCallback } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Plus } from '@phosphor-icons/react';
import type { MovieStudioFormData } from 'src/types';
import { Button } from 'src/components/Button';
import { VideoScriptDragItem } from '../VideoScriptDragItem';
import { List } from 'react-movable';

const SVG_SIZE = 20;

export const VideoScript = () => {
  const { control } = useFormContext<MovieStudioFormData>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'script',
  });

  const handleMoveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      move(dragIndex, hoverIndex);
    },
    [move],
  );

  return (
    <div className="nj-avatar-recording--video-script">
      <List
        values={fields}
        onChange={({ oldIndex, newIndex }) =>
          handleMoveCard(oldIndex, newIndex)
        }
        renderList={({ children, props, isDragged }) => (
          <ul
            {...props}
            style={{ padding: 0, cursor: isDragged ? 'grabbing' : undefined }}
          >
            {children}
          </ul>
        )}
        renderItem={({ value, props, isDragged, isSelected, index = 0 }) => (
          <VideoScriptDragItem
            key={value.id}
            id={value.id}
            index={index}
            remove={remove}
            isDisabledRemove={fields.length === 1}
            itemProps={props}
          />
        )}
      />
      <Button
        onClick={() =>
          append({
            text: '',
            imageURL: '',
          })
        }
        type="button"
        className="nj-avatar-recording--video-script-add-button"
      >
        <Plus size={SVG_SIZE} />
        <span>Add</span>
      </Button>
    </div>
  );
};
