import { useContext, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { useIdleTimer } from 'react-idle-timer';
import AudioContext, { AudioContextType } from 'src/contexts/AudioContext';
import { useSession, useBreakpoint } from 'src/hooks';
import AvatarModeContext, {
  AvatarModeContextType,
} from 'src/contexts/AvatarModeContext';
import { Timer, X, Info } from '@phosphor-icons/react';
import { Button } from 'src/components/Button';
import { ChatMode } from 'src/types';
import { toast } from 'react-toastify';
import { useBanner } from 'src/hooks/useBanner';

const SVG_SIZE = 24;

const VIDEO_CHAT_ENDED_MESSAGE = 'Video chat ended.';

export const AvatarIdleBanner = () => {
  const { isTabletAndUp } = useBreakpoint();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { metaHumanTalking, recordInProgress } =
    useContext<AudioContextType>(AudioContext);
  const [remaining, setRemaining] = useState<number>(0);

  const { bannerType } = useBanner();

  const { updateAvatarQueue, updateChatMode } = useSession();
  const { clearShowAvatarQueue, avatarIsBeingDragged } =
    useContext<AvatarModeContextType>(AvatarModeContext);

  const handleDismissOnIdleAvatar = () => {
    updateAvatarQueue();
    clearShowAvatarQueue();
    updateChatMode(ChatMode.CHAT);
    toast.info(VIDEO_CHAT_ENDED_MESSAGE, {
      icon: <Info size={SVG_SIZE} weight="fill" />,
    });
  };

  // when idle state has been reached,
  // check whether banner was shown,
  // if not show the banner, and
  // kickstart the timer
  const onIdle = () => {
    if (!isExpanded) {
      activate();
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
      handleDismissOnIdleAvatar();
    }
  };

  // when the user is prompted to interact
  // with the avatar expand this banner.
  const onPrompt = () => {
    setIsExpanded(true);
  };

  // when the user interacts with the avatar
  // close the banner.
  const onActive = () => {
    setIsExpanded(false);
  };

  const onAction = () => {
    setIsExpanded(false);
  };

  const { getRemainingTime, activate, pause, resume } = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    onAction,
    startOnMount: true,
    timeout: 90000,
    promptBeforeIdle: 60000,
    throttle: 50,
  });

  // show timer to the user to notify about idle state of avatar
  const remainingTime = useMemo(() => {
    if (remaining === 0) {
      return '2:00';
    }
    const minutes = Math.floor(remaining / 60);
    const seconds = remaining % 60;

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, [remaining]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (metaHumanTalking || recordInProgress || avatarIsBeingDragged) {
      pause();
    } else {
      resume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaHumanTalking, recordInProgress, avatarIsBeingDragged]);

  const handleClose = () => {
    setIsExpanded(false);
    activate();
  };

  return (
    <div
      className={classNames('nj-banner', 'nj-banner--idle', {
        expanded: isExpanded,
        absolute: bannerType !== null, // todo it is temporary solution, the banner for avatar should be refactored
      })}
    >
      <div className="container">
        <div className="nj-banner--icon">
          <Timer size={SVG_SIZE} weight="fill" />
        </div>
        <div className="nj-banner--notification">
          <h5>{remainingTime}</h5>
          <p>
            {isTabletAndUp
              ? "Video chat will end if there's no activities."
              : "Chat'll end if there's no activities."}
          </p>
        </div>
        <Button className="nj-banner--close" onClick={handleClose}>
          <X size={SVG_SIZE} />
        </Button>
      </div>
    </div>
  );
};
