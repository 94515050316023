import styles from './TooltipDesktop.module.scss';
import { X } from '@phosphor-icons/react';
import { useTheme } from 'src/hooks';
import { useVisible } from 'src/hooks';

interface TooltipDesktopProps {
  children: React.ReactNode;
}

export const TooltipDesktop = ({children}: TooltipDesktopProps) => {
  const SVG_SIZE = 24;
  const { isVisible, handleVisibilityRemove } =
    useVisible(true);

  const { isTasksListExpanded } = useTheme();

  const handleClick = () => {
    handleVisibilityRemove();
  }

  if (!isVisible || isTasksListExpanded) {
    return null;
  }

  return (
    <div className={styles.root}>
      <X size={SVG_SIZE} className={styles.close} onClick={handleClick} />
      <div className={styles.titleContainer}>
        <h4 className={styles.title}>Try Ninja Pro for 90% off</h4>
        <span className={styles.tag}>Limited time</span>
      </div>
      <p className={styles.description}>Deep research, multi-party meeting negotiations, access to 20+ external LLMs and video chat with Ninja</p>

      {children}
    </div>
  );
};
